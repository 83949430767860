import React from 'react';
// import cn from "classnames";
// import date from "php-date";
// import * as strtotime from "strtotime";
// import {toastr} from "react-redux-toastr";
import {FormItem} from "components/_common";
// import {in_array, array_unset} from "utils/func";
// import {rolesAdmin, rolesModerator, SITE_URL, MESSAGES} from "utils/config";


const Personal = props => {
	const {user,
		settings, savedSettings, setSavedSettings,
	} = props;
	return (
		<>
			<FormItem
				fieldType={"text"}
				name={""}
				label={"Ширина контентной части сайта при ширине экрана больше 1024px, (укажите вместе с px или %)"}
				defVal={settings['user_' + user.id + '_style_content_max_width'] && settings['user_' + user.id + '_style_content_max_width']}
				changeAction={e => {
					setSavedSettings({
						...savedSettings,
						['user_' + user.id + '_style_content_max_width']: e.target.value.trim()
					});
				}}
				descr={"если пусто, то 100%"}
			/>
		</>
	)

};

export default Personal;
