import * as strtotime from "strtotime";
// import date from "php-date";
import {diffDays} from "utils/func";
import React from "react";

export const TablesBgr = {
    white: 'ffffff',
    yellow: 'ffff00',
    orange: 'ffa500',
    red: 'ff0000',
    lightblue: '42aaff',
    salat: '99ff99',
};


export const billsShowCallText = (data) => {
    data = {...data};
    // console.log(data);
    // console.log(data[3]);
    if (data[6] != undefined && data[6].value !== ''
        &&
        (data[3] == undefined || data[3].value === '')
    ) {
        let rowDate = new Date(strtotime(data[6].value + ' 23:59:59') * 1000);
        // console.log(data[6].value, rowDate, diffDays(new Date(), rowDate));

        if (diffDays(new Date(), rowDate))
            return <div title={"от " + data[6].value}>Обзвонить</div>;
    }

    return null;
}

export const backgroundColorSelect = (onClick) => {
    return (
        <div className={"table-color-select"}>
            {Object.values(TablesBgr).map(clr => {
                return (<div
                    // className={clr ==TablesBgr.white ? "rmk" : ""}
                    style={{backgroundColor: `#${clr}`}}
                    key={`sc-${clr}`}
                    onClick={() => onClick(clr)}
                ></div>);
            })
            }
        </div>
    );
}

export const PanelColorSelect = ({onClick, right=false}) => {
   return (
       <div
           className={"tables-colors" + (right ? ' -right' : '')}
           // className={cn("tables-colors", {fixed: colorsPanelFixed})}
       >
           {/*<p>Выделить цветом фон</p>*/}
           {
               Object.values(TablesBgr).map(clr => {
                   return (<div
                       style={{backgroundColor: `#${clr}`}}
                       key={`sc-${clr}`}
                       onClick={() => onClick(clr)}
                   ></div>);
               })
           }
       </div>
   );
}