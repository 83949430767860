import React from 'react';
import OrdersListItem from "components/orders/OrdersListItem";
import {number_format} from "utils/func";
import {driverSelectComponent} from "../tasks/utils";

const OrdersHeaderStyle = {
    'tob.ru': {background: '#1BA39C'},
    'westcom.ru': {background: '#FF7B00'},
    'torgstore.ru': {background: '#70ac43'},
};


const OrdersList = props => {
    const {
        user,
        ordersInfo,
        // division,
        isEditable,
        managerSelect, statusSelect,
        columnsTitle,
        setField,//задает значение поля, накладная и примечание
        status,
        selectedManagerId
    } = props;

    const {items: orders, totalSum} = ordersInfo;


    const ordersBySite = {};

    for (let order of orders) {

        if (!ordersBySite.hasOwnProperty(order.site))
            ordersBySite[order.site] = {
                header: order.site,
                style: OrdersHeaderStyle[order.site] ?? null,
                orders: []
            };
        ordersBySite[order.site].orders.push(order);
    }

    // console.dir(ordersBySite);
    // console.dir(Object.values(ordersBySite));

    let content = [];
    Object.values(ordersBySite).map(({header, style, orders}, i) => {

        content.push(
            <div className={"orders-list-site"} style={style} key={`orders-hdr-${i}`}>
                {header}
            </div>
        );

        orders.map(order => {
            content.push( <OrdersListItem
                user={user}
                key={"orders-list-item-" + order.id}
                order={order}
                // getPopupEditor={getPopupEditor}
                isEditable={isEditable}
                managerSelect={managerSelect}
                statusSelect={statusSelect}
                status={status}
                selectedManagerId={selectedManagerId}
                columnsTitle={columnsTitle}
                setField={setField}
            />);
        });
    })

    return (
        <>

            {content}


        </>
    )

};

export default OrdersList;
