import React from 'react';
import {FormItem, List} from "components/_common";

const Tasks = props => {
	const {
		settings,
		// savedSettings,
		setSavedSettings,
		// userRoles
	} = props;




	return (
		<>
			<List
				label={"Примечание, предустановленные значения"}
				dataDefault={(settings && settings.cmntsPredefined) ? settings.cmntsPredefined : [] }
			/>
		</>
	)

};

export default Tasks;
