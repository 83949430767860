import React from 'react';
import {Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import OrdersGet from "containers/orders/OrdersGet";
import OrdersContainer from "containers/orders/OrdersContainer";
import OrdersShowContainer from "containers/orders/OrdersShowContainer";

const OrdersRouter = props => {

	return(
		<Switch>
			<Route path="/orders/get"
				   key={"orders-get"}>
				<OrdersGet {...props} />
			</Route>
			<Route path={["/orders/show/:id"]} exact={true} key={"orders-show"}>
				<OrdersShowContainer {...props} />
			</Route>
			<Route
				path="/orders/:status?/:site?"
				// path={['/orders/:status?', ]}
				// exact={true}
				key={"orders"}
			>
				<OrdersContainer {...props} />
			</Route>



			<Route component={Page404} />
		</Switch>
	)
	// return <FormsContainer {...props} />

};

export default OrdersRouter;
