import React, {createRef, useState} from 'react';
import {FormItem} from "components/_common";
import {Button} from "components/_common";
import {in_array, array_unset} from "utils/func";
import cn from "classnames";
import {toastr} from "react-redux-toastr";


const ListItem = ({name, setData}) => {
	return (
		<div className="form-list-item">
			<div className="-n">
				{name}
			</div>
			<i className={"fa fa-close"}
			   onClick={e => {
				   setData(prev => {
						let n = array_unset([...prev], name);

						// setSavedSettings(prev => ({
						// 	...prev,
						// 	cities: n
						// }));
						return n;
					});

			   }}
			></i>
		</div>
	);
}
const List = props => {

	const {
		setSavedSettings,
		dataDefault = [],
		label,
	} = props;

	const inputRef = createRef();


	const [data, setData] = useState(dataDefault);
	const [field, setField] = useState('');


	return (
		<div className={cn("form-item")}>
			{
				label ? (
					<label>
						{label}
					</label>
				) : null
			}

			<div className="row mt20">
				<div className="col-2-3">
					<FormItem
						name={"listItem"}
						placeholder={'Новы элемент'}
						defVal={field}
						reff={inputRef}
						changeAction={(e, v) => setField(v)}
					/>
				</div>
				<div className="col-3">
					<Button
						title={"Добавить"}
						onClick={e => {
							let value = inputRef.current.value.trim();

							if (value !== '' && !in_array(value, data)) {
								let _newArr = [...data];
								_newArr.push(inputRef.current.value.trim());
								_newArr = _newArr.sort();

								setData(_newArr);
								setField('');

								// setSavedSettings(prev => ({
								// 	...prev,
								// 	cities: _cities
								// }));
							}
							else
								toastr.warning('Внимание', 'Значение уже есть либо пустое');

						}}
					/>
				</div>
			</div>
			{
				data.map((name, i) =>
					<ListItem
						key={'li-' + i}
						name={name}
						setData={setData}
						// setSavedSettings={setSavedSettings}
					/>
				)
			}
		</div>


	)

};

export default List;
