import React, {createRef, useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {Button, ContentHeader, Win, FormItem} from "components/_common";
import {MENU_TITLE, MESSAGES, rolesModerator} from "utils/config";
import {useParams} from "react-router-dom";
import DocsUploadBox from "components/docs/DocsUploadBox";
import {BUTTON_TYPE} from "components/_common/Button";
import {toastr} from "react-redux-toastr";
import {array_unset, downloadFile, getFileIcon, in_array} from "utils/func";

const API_URL = 'taskmanager.php';
const TodoEdit = props => {

	const {
		user, actions, history, GUI,
		edit,
	} = props;

	let {id = 0} = useParams();
	id = parseInt(id);

	const formRef = createRef();

	const isAdmin = in_array(user.role, rolesModerator);
	// const isManager = user.role == 'manager';

	const [data, setData] = useState(null);
	const [files4upload, setFiles4upload] = useState([]);


	let apiActionProcess = false;
	const getData = async (isSubscribe = true) => {

		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			apiAction(await Api.post(API_URL, {
				action: 'getTodo',
				userId: user.id,
				id: id
			}), (response) => {
				setData(response);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});


		}
	};
	const uploadFiles = async (id, cb) => {

		const formData = new FormData();

		formData.append('id', id);
		// formData.append('user', JSON.stringify(user));
		formData.append('userId', user.id);
		formData.append('action', 'uploadFiles');

		files4upload.map((file, i) => {
			formData.append('file_' + i, file);
		})

		if (!apiActionProcess) {
			actions.showLoading();
			apiAction(await Api.post(API_URL, formData), (response) => {
				// console.log(response);

				// if (response.files.length) {
				// 	let _files = data.files ? data.files : [];
				// 	_files.concat(response.files);
				// 	setData(prev => ({
				// 		...prev,
				// 		files: _files
				// 	}))
				// }

				//редирект
				if (typeof cb == 'function')
					cb();
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	}
	const onSave = async (_id) => {
		const form = formRef.current;
		console.clear();
		console.log(form.execId.value);
		if (!apiActionProcess) {
			actions.showLoading();
			apiAction(await Api.post(API_URL, {
				action: 'saveTodo',
				userId: user.id,
				id: id,
				txt: form.txt.value.trim(),
				execId: form.execId.value
			}), (resp) => {
				// console.log(resp);

				//далее загружаем файлы по ИД
				if (files4upload.length !== 0 && resp.id) {
					uploadFiles(resp.id, () => {
						history.push(MENU_TITLE.taskManager.url)
					});
				} else
					history.push(MENU_TITLE.taskManager.url);


			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}

	}

	const deleteTodo = _id => {
		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: async () => {
					if (!apiActionProcess) {
						actions.showLoading();
						apiAction(await Api.post(API_URL, {
							action: 'deleteTodo',
							userId: user.id,
							id: _id
						}), (resp) => {
							// console.log(resp);
							if (resp.deleted)
								history.push(MENU_TITLE.taskManager.url);

						}, () => {
							apiActionProcess = false;
							actions.hideLoading();
						});
					}
				}
			}
		);
	}

	const onDeleteFile = (todoId, fileId) => {

		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: async () => {
					console.log('delete file', todoId, fileId);
					if (!apiActionProcess) {
						actions.showLoading();
						apiAction(await Api.post(API_URL, {
							action: 'deleteTodoFiles',
							userId: user.id,
							id: todoId,
							fileId: fileId
						}), () => {

							let _files = (data && data.todo && data.todo.files)
								? data.todo.files
								: [];
							_files.map((fl, i) => {
								if (fl.id == fileId)
									array_unset(_files, i);
							});
							setData(prev => ({
								...prev,
								files: _files
							}));

						}, () => {
							apiActionProcess = false;
							actions.hideLoading();
						});
					}
				}
			}
		);
	};
	const changeStatus = (_id, status) => {
		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: async () => {
					if (!apiActionProcess) {
						actions.showLoading();
						apiAction(await Api.post(API_URL, {
							action: 'changeStatus',
							userId: user.id,
							id: _id,
							status: status
						}), (response) => {
							if (response.changed)
								history.push(MENU_TITLE.taskManager.url);

						}, () => {
							apiActionProcess = false;
							actions.hideLoading();
						});
					}
				}
			}
		);
	}

	useEffect(() => {
			console.clear();
			let isSubscribe = true;
			getData(isSubscribe).then(null);

			return () => {
				isSubscribe = false;
			}
		},
		[]
	);

	let chParent = [{
		url: MENU_TITLE.taskManager.url,
		name: MENU_TITLE.taskManager.many
	}];


	if (data == null)
		return null;

	let managersOptionsMap = new Map();
	let defaultManagerId = 0;
	if (data && data.managers && data.managers.length) {
		data.managers.map((man, s) => {
			if (defaultManagerId === 0)
				defaultManagerId = parseInt(man.id);
			managersOptionsMap.set(man.id, {
				value: man.name
			});
		});
	}


	const winFooter = (
		<div className={"win-ftr-btns flx -sb"}>
			<div>
				{
					edit
						? <Button
							type={BUTTON_TYPE.applyIco}
							onClick={e => onSave(id)}
						/>
						: null
				}

				<Button
					type={BUTTON_TYPE.closeIco}
					onClick={e => history.push(MENU_TITLE.taskManager.url)}
				/>
			</div>
			<div>
				{
					(isAdmin || parseInt(data.todo.ownerId) === parseInt(user.id))
						? <Button
							type={BUTTON_TYPE.delete}
							onClick={e => deleteTodo(id)}
						/>
						: null
				}

			</div>
		</div>
	);

	let fileBox = null;
	if (data.todo && data.todo.files && data.todo.files.length) {
		fileBox = (
			<div className="form-item tm-todo-files">
				<label>
					{/*<i className={"fa fa-files-o"}></i> */}
					Файлы
				</label>
				<ul className="form-files-list">
					{
						data.todo.files.map(file => {
							// console.dir(file);
							return (
								<li key={"files-list-" + file.id}>
									<a
										href={"#"}
										onClick={e => downloadFile(e, user, file.id)}
									>
										{getFileIcon(file.ext)}
										{file.fname}
									</a>
									{
										edit ? <i
											className="fa fa-close"
											title={"Удалить файл"}
											onClick={e => onDeleteFile(data.todo.id, file.id)}
										></i> : null
									}
								</li>
							);
						})
					}
				</ul>
			</div>
		);
	}

	const todoStatus = parseInt(data.todo.status);


	return (
		<>
			<ContentHeader
				title={id ? 'Новое задание' : 'Редактирование задания'}
				showDates={false}
				parent={chParent}
			/>
			<Win
				header={"Задание для менеджера"}
				onClose={() => history.push(MENU_TITLE.taskManager.url)}
				winClass={"tm-todo-edit"}
				footer={winFooter}
			>

				<form ref={formRef}>

					<FormItem
						key={"txt"}
						edit={edit}
						name={"txt"}
						fieldType={"txt"}
						label={"Описание"}
						defVal={data ? data.todo.txt : ""}
						cls={"textarea-big"}
					/>
					<FormItem
						key={"execId"}
						edit={edit}
						name={"execId"}
						fieldType={"select"}
						label={"Менеджеру"}
						optionsMap={managersOptionsMap}
						defVal={data ? data.todo.execId : defaultManagerId}
					/>

					{fileBox}

					{
						edit ?
							<DocsUploadBox
								showOnlyUploadBox={true}
								settings={user.settings}
								filesList={files4upload}
								setFilesList={(_files) => setFiles4upload(_files)}
								GUI={GUI}
								formRef={null}
							/>
							: null
					}

					<div className="flx -sa mt20">
						{
							(todoStatus === 0
								&& (
									isAdmin
									|| parseInt(user.id) === parseInt(data.todo.execId)
								)
							)
								? <Button
									type={BUTTON_TYPE.save}
									ico={"check"}
									title={"Отметить как выполненное"}
									onClick={e => changeStatus(id, 1)}
								/>
								: null
						}
						{
							(todoStatus == 1
								&& (
									isAdmin
									||
									parseInt(user.id) === parseInt(data.todo.ownerId)
								)
							)
								? (
									<>
										<Button
											type={BUTTON_TYPE.apply}
											ico={"check"}
											title={"Отметить как принятое"}
											onClick={e => changeStatus(id, 2)}
										/>

										<Button
											type={BUTTON_TYPE.closeIco}
											ico={"check"}
											title={"Отметить как не выполненное"}
											onClick={e => changeStatus(id, 0)}
										/>
									</>
								)
								: null
						}
					</div>


				</form>
			</Win>
		</>
	)

};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading,
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TodoEdit);

//
// const TodoForm = (edit, data) => {
// 	console.dir('data TodoForm', edit, data);
// 	return (
// 		<>
// 			<FormItem
// 				key={"txt"}
// 				edit={edit}
// 				name={"txt"}
// 				fieldType={"txt"}
// 				label={"Описание"}
// 				// defVal={data ? data.txt : ""}
// 				defVal={data.txt}
// 				cls={"textarea-big"}
// 			/>
// 		</>
// 	)
// }
