import React, {useState} from 'react';
import cn from "classnames";

const Tabs = props => {

    let {
        data = [], activeTabInit = 0,
        tabPrefix = 'tab',
        url = '',
        useUrl = true,
        onChange = null
    } = props;


    if (useUrl && window.location.hash != '') {
        let hashTabIndex = window.location.hash.split('-').pop();
        if (hashTabIndex != undefined)
            activeTabInit = parseInt(hashTabIndex);
    }


    const [activeTab, setActiveTab] = useState(activeTabInit);

    const tabLen = data.length;

    const changeTab = (tabIndex) => {

        if (useUrl)
            window.history.pushState(
                {
                    page: 'settings',
                    tab: tabIndex
                },
                '',
                `${url}#${tabPrefix}-${tabIndex}`
            );
        setActiveTab(tabIndex);
        if (typeof onChange == 'function')
            onChange(tabIndex);
    }


    return (
        <div className="tabs">
            <ul className="tabs-list flx _z">
                {
                    data.map((tab, i) => {
                        // console.log(tab.title, (i - tabLen) * -1);
                        let title = tab.title ? tab.title : 'undefined';
                        return (
                            <li
                                key={"tabs-list-item-" + title + i}
                                className={cn({active: activeTab == i})}
                                onClick={() => changeTab(i)}
                                style={{
                                    zIndex: ((i - tabLen) * -1) + (activeTab == i ? 1 : 0)
                                }}
                            >
                                {title}
                            </li>
                        )
                    })
                }
            </ul>
            <div className="tabs-panels">
                {
                    data.map((tab, i) => {
                        return <div
                            key={"tabs-panel-" + tab.title + i}
                            className={cn({active: activeTab == i})}>
                            {
                                tab.content
                                    ? tab.content
                                    : null
                            }
                        </div>
                    })
                }
            </div>
        </div>
    )

};

export default Tabs;
