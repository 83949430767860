import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Api, {apiAction} from "utils/Api";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {ContentHeader, Win, Button} from "components/_common";
import {in_array} from "utils/func";
import {MENU_TITLE, rolesAdmin, MESSAGES} from "utils/config";
import date from "php-date";
import {TasksTabs} from "components/tasks";

const fieldTitle = {
	tasks: {
		ddate: 'Дата исполнения',
		phoneMobile: 'Мобильный телефон',
		phones: 'Доп. телефоны'
	}
}


const API_URL = 'users.php';
const ChangelogContainer = (props) => {

	let {id = ''} = useParams();
	const {user, actions, part, fields = null} = props;
	const isAdmin = in_array(user.role, rolesAdmin);
	// console.log(id, part);
	const [changelog, setChangelog] = useState(null);
	const [data, setData] = useState(null);

	let apiActionProcess = false;
	const getData = async (isSubscribe = true) => {
		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'getChangelog',
				user: user,
				id: id,
				part: part
			});

			apiAction(res, (resp) => {

				setChangelog(resp.changelog);
				setData(resp.data);

				// console.dir(data);

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
			apiActionProcess = false;
		}
		actions.hideLoading();
	};

	useEffect(() => {
			let isSubscribe = true;
			getData(isSubscribe).then(null);


			return () => {
				isSubscribe = false;
			}
		},
		[]
	);

	const tableRow = (item) => {

		// console.dir(item);
		let field;
		if (fieldTitle[item.part] && fieldTitle[item.part][item.field])
			field = fieldTitle[item.part][item.field];
		else
			field = item.field;
		return (
			<div className="tr" key={`${item.id}-${item.cdate}`}>
				<div className="td" style={{width: '15%'}}>
					{field}
				</div>
				<div className="td" style={{width: '15%'}}>
					{item.userName}
				</div>
				<div className="td" style={{width: '10%'}}>
					{item.dateValue}
				</div>
				<div className="td" style={{width: '20%'}}>
					{item.valueWas}
				</div>
				<div className="td" style={{width: '20%'}}>
					{item.valueNew}
				</div>
				<div className="td" style={{width: '20%'}}>
					{item.txt}
				</div>
			</div>);
	};

	const table = [];
	if (changelog) {
		table.push(
			<div className="thead" key={"chl-head"}>
				{tableRow({
					id: 0,
					field: 'Поле',
					userName: 'Пользователь',
					cdate: 0,
					dateValue: 'Дата изм.',
					valueWas: 'Было',
					valueNew: 'Стало',
					txt: 'Комментарий'
				})}
			</div>
		);
		let tbody = [];
		changelog.map(item => {

			if (fields && fields[item.field])
				item.field = fields[item.field];

			item.dateValue = date('d.m.Y H:i', new Date(item.cdate * 1000) );

			tbody.push(tableRow(item));
			return null;
		});

		table.push(
			<div className="tbody" key={"chl-body"}>
				{tbody}
			</div>
		);
	}

	let chParent = [];
	let mainTitle = "История изменений";
	let winHeader = '';
	let backUrl = '';
	switch (part) {
		case "tasks":
			backUrl = '/tasks/edit/' + id;
			chParent.push({
				url: backUrl,
				name: MENU_TITLE.tasks.one + ' №' + id
			});
			// winHeader = MENU_TITLE.tasks.one + ' №' + id + ' :: ' + mainTitle;
			winHeader = <TasksTabs
				user={user}
				id={id}
				tab={"changelog"}
				task={data}
			/>
			break;
	}

	if (!isAdmin)
		return MESSAGES.accessDenied;

	return (
		<>
			<ContentHeader
				title={"История изменений"}
				showDates={false}
				// datesAction={getDa}
				parent={chParent}
				// titleEnds={chTitleEnds}
			/>
			<Win
				onClose={() => props.history.push(backUrl)}
				header={winHeader}
				// footer={}
			>

				{table.length ?
				<div className="tbl dark -bordered -zebra">
					{table}
				</div> : null
				}
			</Win>

		</>
	)

};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangelogContainer);
// export default ChangelogContainer;
