import React from 'react';
import FormsListItem from "components/forms/FormsListItem";
import {in_array, number_format} from "utils/func";
import {rolesModerator} from "utils/config";
import OrdersListItem from "components/orders/OrdersListItem";


const FormsList = props => {

	const {
		user,
		formsInfo,
		// division,
		isEditable,
		managerSelect, statusSelect,
		columnsTitle,
		setField,//задает значение поля, накладная и примечание
		// status,
		// selectedManagerId,
		showForms
	} = props;

	const {items: forms} = formsInfo;
	// console.log(showForms);
	// console.log(forms);

	if (!showForms)
		return <div>Ошибка</div>

	return (
		<>
			{/*выведно наверх 12/01/2023*/}
			{/*<div className="sub-hdr flx -alc">*/}
			{/*	<div className="-name">*/}
			{/*		{division.name}*/}
			{/*	</div>*/}
			{/*	<div className="-count">*/}
			{/*		Кол-во: {forms.length} шт*/}
			{/*	</div>*/}
			{/*</div>*/}

			{forms.map(form => {

				let formState = null;
				showForms.map(sform => {
					if (sform.id == form.id)
						formState = sform;
				});

				return <FormsListItem
					user={user}
					key={"forms-list-item-" + form.id}
					form={form}
					isEditable={isEditable}
					managerSelect={managerSelect}
					statusSelect={statusSelect}
					columnsTitle={columnsTitle}
					setField={setField}
					formState={formState}
				/>
			})}
		</>
	)

};

export default FormsList;
