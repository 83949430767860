import {Storage} from "utils/Storage";
import Cookies from 'universal-cookie';
import {COOKIE_LOGIN, COOKIE_PASS} from "utils/config";

export const SESSION_USER = 'sklad_user';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SET = 'USER_SET';
export const USER_SET_SETTINGS = 'USER_SET_SETTINGS';

export const userAuth = (type, userInfo) => {


	if (typeof type !== 'string')
		throw "UserAction.userAuth(type, userInfo)? parameter type must be a string";
	// console.log('userAuth', 'type'+type, userInfo);
	// console.log(type);
	if (type === USER_LOGOUT) {
		// Storage.session_set(SESSION_USER, null);
		Storage.set(SESSION_USER, null);

		// const cookies = new Cookies();
		// cookies.remove(COOKIE_LOGIN);
		// cookies.remove(COOKIE_PASS);
		// cookies.remove(SESSION_USER);
	}

	return {
		type: type,
		payload: userInfo
	}
};
export const setUser = user => {
	return {
		type: USER_SET,
		payload: user
	}
};
export const userSetSettings = (key, val) => {
	return {
		type: USER_SET_SETTINGS,
		payload: {
			key: key,
			value: val
		}
	}
};