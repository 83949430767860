import React from 'react';
import {getFileSize, getFileIcon, downloadFile, getFileExtension, in_array, trim} from "utils/func";
import {SITE_URL, IMG_EXTENSIONS, DEBUG} from "utils/config";
import ReactFancyBox from "react-fancybox";

// export const IMG_EXTENSIONS = [
//     'jpg', 'jpeg',
//     'bmp', 'png', 'gif',
//     'webp'
// ];


const FilesList = ({user = null, files = [], onDelete, isFromServer = true}) => {

    let siteUrl = SITE_URL;
    if (DEBUG)
        siteUrl = 'http://localhost/sklad';

    if (user === null)
        onDelete = null;

    return (
        <ul className="form-files-list">
            {
                files.map((file, i) => {
                    let ico;
                    let isImage = false;

                    if (isFromServer) {
                        if (in_array(file.ext.toLowerCase(), IMG_EXTENSIONS)) {
                            isImage = true;
                            let src = siteUrl + '/'+trim(file.path, '/');

                            // console.log(src);

                            ico = <ReactFancyBox
                                thumbnail={src}
                                image={src} />;
                        }
                        else
                            ico = getFileIcon(
                                file.ext,
                                file
                            );
                    } else {
                        ico = getFileIcon(
                            getFileExtension(file.name),
                            file
                        )
                    }

                    // ico = getFileIcon(
                    //     getFileExtension(file.name),
                    //     file
                    // )
                    let fileSize = null;
                    if (file.size)
                        fileSize = '('+getFileSize(file.size) + ')';


                    return (
                        <li key={"file-uploader-files-" + i}>
                            <div className="ffl-btns">
                                {
                                    isFromServer
                                        ? <i className="fa fa-floppy-o"
                                             onClick={(e) => downloadFile(e, user, file.id)}
                                             title={"Скачать"}
                                        ></i>
                                        : null
                                }
                                {
                                    onDelete ?
                                        <i className="fa fa-close"
                                           onClick={() => onDelete( isFromServer ? file.id : file)}
                                           title={"Удалить"}
                                        ></i>
                                        : null
                                }
                            </div>


                            <figure>
                                <a
                                    href="#"
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        if (!isImage && isFromServer)
                                            await downloadFile(e, user, file.id);
                                    }}
                                >
                                    {ico}

                                    <figcaption>
                                        {file.name} {fileSize}
                                    </figcaption>
                                </a>
                            </figure>


                        </li>
                    )
                })
            }
        </ul>
    )

};

export default FilesList;