import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {bool_val} from "utils/func";
import cn from "classnames";
import {Storage} from "utils/Storage";

const Win = (props) => {

	const {children = null, header = null, footer = null, wrap = true,
		winClass = '',
		onClose = null,
		noOverflowContent = true,
		minimized = false, //может быть минимизировано- добавляет кнопку
		isMinimized = null,//по умолчанию
		id = null,//нужен для запоминания состояния окна
	} = props;

	const [isContentHide, setContentHide] = useState(
		isMinimized !== null ? isMinimized :
			(id ? bool_val(Storage.get('win_hidden_' + id, false)) : false)
	);
	// console.log(header + typeof header );
	let headerCont = null;
	 if (header || onClose || minimized)
		headerCont = (
			<div
				className={"h" + ((onClose || minimized) ? ' -wc' : '')}
			>
				{header ? header : <div>&nbsp;</div>}

				{
					minimized ? <i
						className={cn("b fa", {
							"fa-window-minimize" : !isContentHide,
							"fa-window-maximize": isContentHide
						})}
						title={isContentHide ? 'Развернуть': 'Свернуть'}
						onClick={() => {
							if (minimized) {
								if (id)
									Storage.set('win_hidden_' + id, !isContentHide);

								setContentHide(!isContentHide);
							}
						}}
					></i> : null
				}
				{onClose && <i className="b fa fa-close" onClick={onClose}></i>}
			</div>
		);


	let cont = "";
	if (wrap) {
		cont = (
			<div className="-wrap">
				{children}
			</div>
		);
	}
	else
		cont = children;


	return(
		<div className={cn("win", winClass, {minimized: isContentHide})}>
			{headerCont}
			<div className={
				cn("c", {
					"-no-overflow": noOverflowContent,
					// "hide": isContentHide
				})
			}>
				{cont}
			</div>
			{footer && <div className="f">{footer}</div>}
		</div>
	)
};


Win.propTypes = {
	header: PropTypes.oneOfType([PropTypes.element,PropTypes.string]),
	children: PropTypes.oneOfType([PropTypes.element,PropTypes.string, PropTypes.array]),//PropTypes.node.isRequired,
	footer: PropTypes.oneOfType([PropTypes.element,PropTypes.string]),
	winClass: PropTypes.string,
	onClose: PropTypes.func,
};

export default Win;