import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Api, {apiAction} from "utils/Api";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {MENU_TITLE} from "utils/config";
import {ContentHeader} from "components/_common";
import {ReportsIndex, Installers, ProductsSale, Installs} from "components/reports";
import {toastr} from "react-redux-toastr";
import {saveSync} from "save-file";

const ReportsContainer = props => {

	const {user, GUI, actions} = props;
	const {reportType = '', domain = ''} = useParams();

	// console.log(reportType, domain);

	const [reportData, setReportData] = useState(null);


	const getDataOnLoad = ['productsSaleWestcom', 'productsSale'].includes(reportType);


	let apiActionProcess = false;
	const getData = async (isSubscribe = true, rtype = null, calDates = null) => {

		if (!isSubscribe)
			return;
		// console.clear();
		// console.log('ReportsContainer getData');
		// console.log(calDates);
		// console.dir(GUI.calendarDates);
		// return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;
			setReportData(null);

			try {
				const res = await Api.post('reports.php', {
					action: 'getReport',
					user: user,
					reportType: rtype,
					dates: calDates ? calDates : GUI.calendarDates,
					domain: domain
				}, {
					timeout: 60000
				});

				apiAction(res, (data) => {
					// console.log(data);
					setReportData(data);
				}, () => {
					apiActionProcess = false;
					actions.hideLoading();
				});
			} catch (err) {
				toastr.error(
					'Ошибка',
					err.toString()
				);
			}
		}
	};

	const downloadFile = (user = null, fileId = 0) => {
		console.log(user, fileId);
		if (reportData.fileData == undefined || reportData.fileData == null) {
			toastr.error(
				'Ошибка',
				'Данные файла не найдены'
			);
			return;
		}
		if (reportData.fileName == undefined || reportData.fileName == null) {
			toastr.error(
				'Ошибка',
				'Неизвестно наименование файла'
			);
			return;
		}
		saveSync(
			reportData.fileData,
			reportData.fileName
		);

	};

	useEffect(() => {
			let isSubscribe = true;
			// await getData(isSubscribe, reportType);
			if (getDataOnLoad)
				getData(isSubscribe, reportType).then(null);

			return () => {
				isSubscribe = false;
				setReportData(null);
			}
		},
		[
			reportType
		]
	);


	let content;
	let contentHeaderTitle;
	switch (reportType) {
		case "productsSale":
			contentHeaderTitle = MENU_TITLE.reportProductsSale.many + domain;
			content = <ProductsSale data={reportData} downloadFile={downloadFile} domain={domain}/>;
			break;
		// case "productsSaleWestcom":
		// 	content = <ProductsSaleWestcom data={reportData} downloadFile={downloadFile}/>;
		// 	contentHeaderTitle = MENU_TITLE.reportProductsSaleWestcom.one;
		// 	break;
		case "installers":
			content = <Installers data={reportData} downloadFile={downloadFile}/>;
			contentHeaderTitle = MENU_TITLE.reportInstallers.one;
			break;
		case "installs":
			content = <Installs user={user} data={reportData} />;
			contentHeaderTitle = MENU_TITLE.reportInstalls.one;
			break;
		default:
			content = <ReportsIndex />;
			contentHeaderTitle = 'Выберите тип отчета';
			// contentHeaderTitle = 'not defined';
			break;
	}
	let contentHeaderParent = [{url: '/reports', name: MENU_TITLE.reports.many}];


	return (
		<>
			<ContentHeader
				title={contentHeaderTitle}
				parent={contentHeaderParent}
				showDates={true}
				datesAction={() => getData(true, reportType)}
			/>
			{content}
		</>
	)

};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);
// export default ReportsContainer;
