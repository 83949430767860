import React from 'react';
import SheetsContainer from "containers/sheets/SheetsContainer";
import {Route, Switch} from "react-router-dom";
import SettingsContainer from "../containers/settings/SettingsContainer";
import Page404 from "../components/Page404";

const SheetsRouter = props => {

    // return <SheetsContainer {...props} />

    return (
        <Switch>
            <Route
                path="/sheets/:sheetName"
            >
                <SheetsContainer {...props} />
            </Route>
            <Route component={Page404} />
        </Switch>
    );
};

export default SheetsRouter;