import React from 'react';
import date from "php-date";
import {NavLink} from "react-router-dom";
import DocsListFiles from "components/docs/DocsListFiles";
import {downloadFile} from "utils/func";

const Installs = ({user, data}) => {

	// console.dir(data);
	if (!data)
		return null;
	if (data.length == 0)
		return 'Нет сданых монтажей за выбранный период';

	return (
		<div className="installs-report-tbl tbl hdr-cntr dark">
			<div className="thead">
				<div className="tr">
					<div className="td -num">
						№ Заявки
					</div>
					<div className="td -date">
						Дата монтажа
					</div>
					<div className="td -photo">
						Фото
					</div>
				</div>
			</div>
			<div className="tbody">
				{
					data.map(item =>
						<InstallsItem
							key={`irt-${item.tid}`}
							user={user}
							// onDownload={downloadFile}
							{...item}
						/>
					)
				}
			</div>
		</div>
	)

};

export default Installs;


const InstallsItem = props => {
	if (props.tid == 35359)
		console.dir(props);
	let photos = [];


	if (props.claimFiles && props.claimFiles.length) {
		console.log('props.claimFiles -HAS');
		photos.push(
			<div className="installs-report-files" key={`claim-files-${props.tid}`}>
				<h3>Рекламации</h3>
				<DocsListFiles

					files={props.claimFiles}

					onDelete={null}
					onDownload={downloadFile}
					user={props.user}
					onlyThumb={false}
				/>
			</div>
		);
	}

	if (props.photosFiles && props.photosFiles.length) {
		photos.push(
			<div className="installs-report-files" key={`photos-files-${props.tid}`}>
				<h3>Фото объекта</h3>
				<DocsListFiles

					files={props.photosFiles}

					onDelete={null}
					onDownload = {downloadFile}
					user={props.user}
					onlyThumb={false}
				/>
			</div>
		);
	}
	if (props.detailsFiles && props.detailsFiles.length) {
		photos.push(
			<div className="installs-report-files" key={`details-files-${props.tid}`}>
				<h3>Детали для замены</h3>
				<DocsListFiles

					files={props.detailsFiles}

					onDelete={null}
					onDownload={downloadFile}
					user={props.user}
					onlyThumb={false}
				/>
			</div>
		);
	}


	return (
		<div className="tr">
			<div className="td -num">
				<NavLink to={`/tasks/edit/${props.tid}`}>
					{props.tid}
				</NavLink>
			</div>
			<div className="td -date">
				{date("d.m.Y H:i", new Date(props.completeDate * 1000))}
			</div>
			<div className="td -photo">
				{photos}
			</div>
		</div>
	);
}
