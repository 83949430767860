import React, {createRef, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {setCalendarSelectDateAction, setSearchAction} from "actions/GUIActions";
import {rolesAdmin} from "utils/config";
import {in_array} from "utils/func";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Win} from "components/_common";
import {InstallEdit, TasksTabs, InstallView} from "components/tasks";


/**
 * Монтаж, анкета
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * https://github.com/agilgur5/react-signature-canvas
 */
const API_URL = 'tasks.php';
const InstallContainer = props => {

	const {
		// GUI,
		user,
		actions
	} = props;

	let {id = 0} = useParams();

	const isAdmin = in_array(user.role, rolesAdmin);
	const isInstaller = in_array(user.role, ['installer']);

	const [data, setData] = useState(null);



	let apiActionProcess = false;
	const getData = async (isSubscribe = true) => {
		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'getInstall',
				user: user,
				id: id
			});

			apiAction(res, (resp) => {
				// console.dir(resp);
				setData(resp);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
			apiActionProcess = false;
		}
		actions.hideLoading();
	};

	useEffect(() => {
			let isSubscribe = true;

			getData(isSubscribe).then(null);

			return () => {
				isSubscribe = false;
			}
		},
		[]
	);


	const backUrl = '/tasks/edit/' + id;

	const winHeader = (
		<div className="flx -sb -alc w100">
			{
				id ? <TasksTabs
					user={user}
					id={id}
					tab={"install"}
					task={(data && data.task) ? data.task : null}
				/> : null
			}
		</div>
	);
	let content = null;
	if (data !== null) {
		const installView = <InstallView
			{...props}
			task={data.task}
			install={data.install}
		/>;
		// console.log(data.install);
		//если монтажник
		if (isInstaller) {
			//если не сдан
			if (data.install == null || (data.install.completeDate != undefined && parseInt(data.install.completeDate) === 0))
				content = <InstallEdit
					{...props}
					task={data.task}
					install={data.install}
					// actions={actions}
				/>
			else
				content = installView
		}
		else
			content = installView
	}

	return (
		<>
			<ContentHeader
				title={"Монтаж"}
				parent={[{url: '/tasks', name: 'Заявки'}, {url: backUrl, name: `Заявка №${id}`}]}
				showDates={false}
			/>
			<Win
				onClose={() => props.history.push('/tasks')}
				header={winHeader}
			>
				{content}
			</Win>
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setCalendarSelectDateAction, setSearchAction,
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(InstallContainer);
