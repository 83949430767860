import React from 'react';
import Page404 from "components/Page404";
import {Route, Switch} from "react-router-dom";
import ReportsContainer from "containers/reports/ReportsContainer";

const ReportsRouter = props => {

	return(
		<Switch>
			<Route
				path="/reports"
				exact={true}
				key={"reports-index"}
			>
				<ReportsContainer {...props} />
			</Route>
			<Route
				path="/reports/:reportType/:domain?"
				key={"reports-typed"}
				exact={true}
			>
				<ReportsContainer {...props} />
			</Route>
			<Route>
				<Page404 text={"Страница отчетов не найдена"} />
			</Route>
		</Switch>
	)

};

export default ReportsRouter;
