import React, {createRef, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Win, Button} from "components/_common";
import {toastr} from "react-redux-toastr";
import {setCalendarSelectDateAction, setSearchAction} from "actions/GUIActions";
import {rolesModerator, MENU_TITLE, MESSAGES} from "utils/config";
import {in_array, is_empty, array_unset, downloadFile} from "utils/func";
import DocsUploadBox from "components/docs/DocsUploadBox";
import DocsList from "components/docs/DocsList";



const DocsContainer = props => {

	const {user, GUI, actions} = props;
	const {userId = 0} = useParams();



	//файлы в БД разбитые по юзерам
	const [docsByUser, setDocsByUser] = useState({});
	const [filesUser, setFilesUser] = useState(null);

	//файлы для закачки
	const [filesList, setFilesList] = useState([]);
	const formRef = createRef();

	let apiActionProcess = false;
	const getDocs = async (isSubscribe = true, calDates = null, search = '') => {
		// console.clear();
		// console.log(alreadyGetTasksDate);
		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('docs.php', {
				action: 'getDocs',
				user: user,
				dates: calDates ? calDates : GUI.calendarDates,
				userId: userId,
				search: search
			});

			apiAction(res, (data) => {
				setDocsByUser(data.files);
				setFilesUser(data.filesUser);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});


		}
	};

	const onCalendarSelectDate = (dates) => {
		getDocs(true, dates).then(null);
	};
	const doSearch = str => {
		getDocs(true, [], str).then(null);
	};

	useEffect(() => {
			let isSubscribe = true;
			getDocs(isSubscribe).then(r => {});
			actions.setCalendarSelectDateAction(onCalendarSelectDate);
			actions.setSearchAction(doSearch);
			return () => {
				isSubscribe = false;
				//отключаем функцию обработчик на календаре
				actions.setCalendarSelectDateAction(null);
				actions.setSearchAction(null);
			}
		},
		[]
	);

	const doUpload = async () => {
		if (!filesList.length)
			return;

		console.clear();

		const form = formRef.current;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const formData = new FormData();
			formData.append('action', 'upload');
			formData.append('user', JSON.stringify(user));

			// formData.append('files', JSON.stringify(filesList));

			// console.log('userId: ' + userId);
			formData.append('data', JSON.stringify({
				ftype: userId ? 'user': 'nakl',
				name: form.name.value,
				naklNum: form.naklNum ? form.naklNum.value : '',
				fDate: form.fDate ? form.fDate.value : '',
				dates: GUI.calendarDates,
				forUserId: userId
			}));
			filesList.map((file, key) => {
				formData.append('file' + key, file);
			});




			const res = await Api.post('docs.php', formData);

			apiAction(res, (data) => {
				console.log(data.files);

				setFilesList([]);
				setDocsByUser(data.files);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	};
	const RND = Math.random();

	const doDelete = async (fileId) => {
		console.log('doDelete ' + fileId);
		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('docs.php?' + RND, {
				action: 'deleteFile',
				user: user,
				id: fileId
			});

			apiAction(res, (data) => {
				let tmp = {...docsByUser};
				for (let userName in tmp) {
					if (tmp[ userName ].length) {
						tmp[ userName ].map((f, i) => {
							if (f.id === fileId)
								tmp[ userName ] = array_unset(tmp[ userName ], i);
						});
					}
				};
				setDocsByUser(tmp);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	};
	const onDelete = async (fileId) => {

		if (window.confirm(MESSAGES.confirmAction) === true)
			doDelete(fileId);

	};
	const doDownloadFile = async (e, user, id) => {
		e.preventDefault();

		await downloadFile(e, user, id, (data) => {
			const _docsByUser = {...docsByUser}
			for (const userName in _docsByUser) {
				_docsByUser[userName].map(f => {
					if (f.id === id) {
						if (!f.opened)
							f.opened = {};

						f.opened[user.id] = Math.round(new Date().getTime() / 1000);
					}
				});
			}

			setDocsByUser(_docsByUser);
		});
	};

	const doPrint = async (e, user, file) => {
		// e.preventDefault();

		await downloadFile(e, user, file.id, (data) => {

			const _docsByUser = {...docsByUser}
			for (const userName in _docsByUser) {
				_docsByUser[userName].map(f => {
					if (f.id === file.id) {
						if (!f.printed)
							f.printed = {};

						f.printed[user.id] = Math.round(new Date().getTime() / 1000);
					}
				});
			}

			setDocsByUser(_docsByUser);

		}, {
			noSave: true,
			touchTarget: 'printed'
		});

	};


	let uploadBox = null;
	if (in_array(user.role, [...rolesModerator, 'manager']))
		uploadBox = <DocsUploadBox
			GUI={GUI}
			formRef={formRef}
			settings={user.settings}
			filesList={filesList}
			setFilesList={setFilesList}
			uploadAction={doUpload}
			forUser={Boolean(userId)}
			// showOnlyUploadBox={true}
		/>;

	// console.log(docsByUser);

	let contentHeaderTitle = MENU_TITLE.docs.many;
	let contentHeaderParent = [];
	if (userId && filesUser) {
		contentHeaderTitle = 'Файлы пользователя ' + filesUser.name;
		contentHeaderParent = [{
			name: 'Пользователи',
			url: '/user/role/' + filesUser.role
		}];
	}

	return (
		<>
			<ContentHeader
				title={contentHeaderTitle}
				showDates={!userId}
				datesAction={getDocs}
				parent={contentHeaderParent}
			/>
			{uploadBox}
			{/*{!is_empty(docsByUser)*/}
			{/*	? <DocsList*/}
			{/*		docsByUser={docsByUser}*/}
			{/*		onDelete={onDelete}*/}
			{/*		{...props}*/}
			{/*	/>*/}
			{/*	: null}*/}
			<DocsList
				docsByUser={docsByUser}
				onDelete={onDelete}
				onDownload={doDownloadFile}
				onPrint={doPrint}

				{...props}
			/>
		</>
	)

};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setCalendarSelectDateAction, setSearchAction
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(DocsContainer);
