import React from 'react';
import {Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import SettingsContainer from "containers/settings/SettingsContainer";

const SettingsRouter = props => {

	return(
		<Switch>

			<Route
				path="/settings"
				exact={true}
				key={"settings-personal"}
			>
				<SettingsContainer {...props} />
			</Route>
			<Route path="/settings/:settingsType"
				   exact={true}
				   key={"settings-bytype"}>
				<SettingsContainer {...props} />
			</Route>
			<Route path="/ustav"
					exact={true}
				   key={"settings-ustav"}>
				<SettingsContainer {...props} />
			</Route>
			<Route
				path="/vocations"
				exact={true}
				key={"settings-vocations"}
			>
				<SettingsContainer {...props} />
			</Route>
			<Route component={Page404} />
		</Switch>
	)

};

export default SettingsRouter;
