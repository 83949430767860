import React from 'react';
import {Button} from "components/_common";

const ProductsSale = ({data, downloadFile, domain}) => {

	if (data === null)
		return null;

	const sales = data.data ? data.data : [];

	if (sales.length == 0)
		return <div>
			Нет продаж
		</div>


	return (
		<>
			<div className="panel-top flx -e mt20m orders no-print">
				<div className="mt20 row">
					<div className="">
						{
							data.fileUrl ?
								<Button
									title={"Скачать Excel-файл"}
									ico={"file-excel-o"}
									onClick={() => {
										console.log('clicked');
									}}
									type={"save"}
									href={{
										pathname: data.fileUrl
									}}
									hrefBlank={true}
								/>
								: null
						}
					</div>
				</div>
			</div>
			<div className="prod-sale-report tbl hdr-cntr -bordered ">
				<div className="thead">
					<ul className="tr">
						<li className="td col-art">
							Артикул
						</li>
						<li className="td col-name">
							Наименование
						</li>
						{/*<li className="td col-clr">*/}
						{/*	Цвет*/}
						{/*</li>*/}
						<li className="td col-img">
							Фото
						</li>
						<li className="td col-price">
							Цена
						</li>
						<li className="td col-qty">
							Кол-во
						</li>
						<li className="td col-sum">
							Сумма
						</li>
					</ul>
				</div>
				<div className="tbody">
					{
						sales.map(itm =>
							<ProductsSaleLine
								data={itm}
								domain={domain}
								key={itm.name + itm.color + itm.article}
							/>
							)
					}
				</div>
			</div>
		</>
	)

};

export default ProductsSale;

const ProductsSaleLine = ({data, domain}) => {

	let priceMin = parseFloat(data.priceMin);
	let priceMax = parseFloat(data.priceMax);


	return (
		<ul className="tr">
			<li className="td col-art">
				{data.article}
			</li>
			<li className="td col-name">
				{data.name}


			</li>
			{/*<li className="td col-clr">*/}
			{/*	/!*{data.color}*!/*/}

			{/*	{data.orders}*/}
			{/*</li>*/}
			<li className="td col-img">
				{
					data.img != ''
						? <img src={`//${domain}${data.img}`}/>
						: null
				}
			</li>
			<li className="td col-price">
				{data.price}
				{/*{data.price && data.price.toFixed(2)}*/}
				{
					(priceMin != priceMax)
						? <div>
							<p>мин: {priceMin}</p>
							<p>макс: {priceMax}</p>
						</div>
						: null

				}

			</li>
			<li className="td col-qty">
				{data.amount}
			</li>
			<li className="td col-sum">
				{data.summ}
			</li>
		</ul>
	)
}
