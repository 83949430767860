import React, {createRef, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import date from "php-date";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Win, Button, BUTTON_TYPE} from "components/_common";
import {rolesModerator, MENU_TITLE, MESSAGES,
	ORDERS_STATUS_CANCELLED, ORDERS_STATUS
} from "utils/config";
import {in_array, number_format, bool2YesNo} from "utils/func";
import {FormItem} from "components/_common";
import {saveSync} from 'save-file';
import {canSeeOrder} from "components/orders/OrdersListItem";
import ReactFancyBox from 'react-fancybox';
import cn from "classnames";

const API_URL = 'orders.php';

const OrdersShowContainer = props => {

	const {user, actions, GUI} = props;

	let {id = 0} = useParams();
	const [order, setOrder] = useState([]);
	const [todoList, setTodoList] = useState({});
	const [companyList, setCompanyList] = useState([]);
	//страница выглядит как коммерческое предложение, для печати
	const [kpCompany, setKpCompany] = useState(null);


	const [statusCommentVisible, setStatusCommentVisible] = useState(false);
	const statusRef = createRef();
	const statusCommentRef = createRef();

	let apiActionProcess = false;
	const getData = async (isSubscribe = true, calDates = null) => {
		console.log('get order');
		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'getOrder',
				userId: user.id,
				id: id
			});

			apiAction(res, (data) => {
				// console.dir(data.order.products);
				setOrder(data.order);
				setTodoList(data.todoList);
				setCompanyList(data.companyList);

				if (GUI.socket && !parseInt(data.order.uid))
					GUI.socket.emit(
						'setOpenByUser',
						{
							userId: user.id,
							what: 'order'
						}
					);

				if (parseInt(data.order.status) === ORDERS_STATUS_CANCELLED)
					setStatusCommentVisible(true);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	};

	useEffect(() => {
			let isSubscribe = true;
			getData(isSubscribe).then(null);

			return () => {
				isSubscribe = false;
			}
		},
		[id]
	);

	const saveOrder = async () => {


		if (statusCommentVisible && statusCommentRef.current && statusCommentRef.current.value === '') {

			toastr.error('Ошибка', 'Не заполнено поле "Комментариии к статусу"');
			return;
		}

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'saveOrder',
				userId: user.id,
				id: order.id,
				status: statusRef.current.value,
				statusComments: statusCommentVisible ? statusCommentRef.current.value : order.statusComments
			});

			apiAction(res, (data) => {

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}

	};


	const deleteOrder = async () => {
		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'deleteOrder',
				userId: user.id,
				id: order.id
			});

			apiAction(res, (data) => {
				if (data.deleted)
					props.history.push('/orders');
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	};
	const onDeleteOrder = () => {
		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: () => deleteOrder(),

			}
		);
	};
	const printAction = () => {
		window.print();
	};
	const generateInvoice = async (companyNum) => {
		if (companyNum === '')
			return;


		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'generateInvoice',
				userId: user.id,
				id: order.id,
				companyNum: companyNum
			});

			apiAction(res, (data) => {
				saveSync(
					data.fileData,
					data.fileName
				);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	};

	const saveKpAsFile = async () => {
		console.clear();
		// console.log(`saveKpAsFile`);
		// console.dir(kpCompany);
		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'saveKpAsFile',
				userId: user.id,
				id: order.id,
				kpData: kpCompany
			});

			apiAction(res, (data) => {
				if (data.fileData)
					saveSync(
						data.fileData,
						data.fileName
					);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	}


	if (!order.id)
		return null;

	let content = [];
	let winHeader = null;
	if (order.userName)
		winHeader = 'Менеджер: ' + order.userName;


	// console.log(order.divId + '/'+user.divId);
	if (!canSeeOrder(user, order))
		content.push(<div key={"role-err"}>
			Нет прав для просмотра заказа
		</div>);
	else {

		let rowNum = 0;

		const {products = [], info} = order;

		let {
			discount = 0, discountCode = '', discountName = '',
			deliveryPrice = 0,
			fromYandexMarket = '',
			razgruzkaPrice = 0,
			company = '', companyName = '',
		} = info;

		discount = parseFloat(discount);
		deliveryPrice = parseFloat(deliveryPrice);
		razgruzkaPrice = parseFloat(razgruzkaPrice);

		if (company != '')
			companyName = company;

		/**
		 * итоговая стоимось заказа
		 * @type {number}
		 */
		let priceSumm = 0;
		//отнимаем скидку
		// priceSumm -= discount;
		// //добавляем доставку
		// priceSumm += deliveryPrice;
		// //добавляем разгрузку
		// priceSumm += razgruzkaPrice;

		if (fromYandexMarket === '1')
			content.push(<div
				key={"fromYandexMarket"}
				className={"red-box"}
				style={{marginBottom: '40px'}}
			>
				ВНИМАНИЕ! Заказ с Яндекс.Маркета
			</div>);

		if (products && products.length) {

			let qtySumm = 0;

			// console.dir(products);

			content.push(
				<table className={"tbl dark -blue -bordered -zebra"} key={"prods-tbl"}>
					<thead>
					<tr>
						<td width="5%" align="center">№</td>
						<td width="10%" align="center">Артикул</td>
						<td width="55%" align="center">Наименование</td>
						<td width="10%" align="center">Цена, руб.</td>
						<td width="10%" align="center">Кол-во</td>
						<td width="10%" align="center">Сумма, руб.</td>
					</tr>
					</thead>
					<tbody>
					{
						products.map(product => {
							product.price = parseFloat(product.price);
							product.qty = parseInt(product.qty);
							const summ = product.price * product.qty;
							priceSumm += summ;
							qtySumm += product.qty;
							rowNum++;

							// console.log(product.price, summ, priceSumm);

							// console.log(rowNum, product.price, product.qty,  summ);

							return (
								<tr key={"prod-" + rowNum}>
									<td className={"_tac"}>
										{rowNum}
									</td>
									<td className={"_tac"}>
										{product.article ? product.article : " "}
									</td>
									<td>
										{
											productNameContent(order.site, product)
										}
									</td>
									<td className={"_tar"}>
										{number_format(product.price)}
									</td>
									<td className={"_tac"}>
										{product.qty}
									</td>
									<td className={"_tar"}>
										{number_format(summ)}
									</td>
								</tr>
							);
						})
					}

					{
						deliveryPrice > 0
							? (
								<tr key={"prod-delivery"}>
									<td className={"_tac"}>
										{++rowNum}
									</td>
									<td colSpan={4}>
										Доставка
									</td>
									<td className={"_tar"}>
										{number_format(deliveryPrice)}
									</td>
								</tr>
							) : null
					}
					{
						razgruzkaPrice > 0
							? (
								<tr key={"prod-razgruzka"}>
									<td className={"_tac"}>
										{++rowNum}
									</td>
									<td colSpan={4}>
										Разгрузка
									</td>

									<td className={"_tar"}>
										{number_format(info.razgruzkaPrice)}
									</td>
								</tr>
							)
							: null
					}

					{
						discount > 0
							? (
								<tr key={"prod-discount"}>
									<td className={"_tac"}>
										{++rowNum}
									</td>
									<td colSpan={4}>
										Скидка {
										discountCode != ''
											? <>по коду сертификата: {discountCode}</>
											: null
										} {discountName}
									</td>

									<td className={"_tar"}>
										{number_format(discount)}
									</td>
								</tr>
							)
							: null
					}

					<tr key={"prod-itogo"}>
						<td colSpan={4} className={"_tar"}>
							<strong>Итого:</strong>
						</td>
						<td className={"_tac"}>
							{qtySumm}
						</td>
						<td className={"_tar"}>
							{number_format(order.summ)}
						</td>
					</tr>
					</tbody>

				</table>
			);
		}

		content.push(<div className="mt20" key={++rowNum}></div>);


		let clientInfo = [];

		if (order.cName && order.cName !== '')
			clientInfo.push(<div className="col-2" key={++rowNum}>
				<FormItem
					key={++rowNum}
					edit={false}
					label={"Контактное лицо"}
					name={""}
					defVal={order.cName}
					ico={"user-o"}
				/>
			</div>);

		clientInfo.push(<div className="col-2" key={++rowNum}>
			{
				(companyName && companyName !== '') && <FormItem
					key={++rowNum}
					edit={false}
					label={"Компания"}
					name={""}
					defVal={companyName}
					ico={"building-o"}
				/>
			}
		</div>);

		clientInfo.push(<div className="col-2" key={++rowNum}>
			{
				(order.cPhone && order.cPhone !== '') && <FormItem
					key={++rowNum}
					edit={false}
					label={"Телефон"}
					name={""}
					defVal={order.cPhone}
					ico={"mobile"}
				/>
			}
		</div>);

		clientInfo.push(<div className="col-2" key={++rowNum}>
			{
				(order.cEmail && order.cEmail !== '') && <FormItem
					key={++rowNum}
					edit={false}
					label={"Email"}
					name={""}
					defVal={order.cEmail}
					ico={"envelope"}
				/>
			}
		</div>);


		if (clientInfo.length)
			content.push(<div className="row" key={++rowNum}>
				{clientInfo}
			</div>);


		let deliveryInfo = [];

		// console.dir(todoList);
		if (order.dType) {

			//для поддержки старого типа отсылки  типа доставки, в новом будет строка
			let deliveryName = todoList[order.dType] ? todoList[order.dType].name : order.dType;

			if (info.tkName && info.tkName != '')
				deliveryName += `: ${info.tkName}`;

			deliveryInfo.push(<div className="col-2" key={++rowNum}>
				<FormItem
					key={++rowNum}
					edit={false}
					label={"Тип доставки"}
					name={""}
					defVal={deliveryName}
					ico={"truck"}
				/>
				{
					deliveryPrice
						? <FormItem
							key={++rowNum}
							edit={false}
							label={"Стоимость доставки"}
							name={""}
							defVal={deliveryPrice}
							ico={"money"}
						/>
						: null
				}
			</div>);
		}
		if (order.dAddr)
			deliveryInfo.push(<div className="col-2" key={++rowNum}>
				<FormItem

					edit={false}
					label={"Адрес доставки"}
					name={""}
					defVal={order.dAddr}
					ico={"map-marker"}
					fieldType={"txt"}
				/>
			</div>);




		// content.push(<hr key={++rowNum} />);

		if (deliveryInfo.length)
			content.push(<div className="row" key={++rowNum}>
				{deliveryInfo}
			</div>);

		if (order.pay !== '')
			content.push(<FormItem key={++rowNum}
								   edit={false}
								   label={"Способ оплаты"}
								   name={""}
								   defVal={order.pay}
								   ico={"credit-card"}
			/>);

		if (order.cmnts !== '')
			content.push(
				<FormItem key={++rowNum}
						  edit={false}
						  label={"Комментарии заказчика"}
						  name={""}
						  defVal={order.cmnts}
						  fieldType={"txt"}
						  ico={"comment-o"}
				/>
			);

		console.dir(info);


		let infoIsset = (param) => {
			let isTrue = false;
			let txt = 'Нет';

			if (info[param] != undefined)
				[isTrue, txt] = bool2YesNo(info[param], true);

			return [isTrue ? ' active' : '', txt];
		};
		let infoItem = (param, title, ico) => {
			return <div className={`order-info-item${infoIsset(param)[0]}`} key={`order-info-${param}`}>
				<i className={`fa fa-${ico}`}></i>
				<span>{title}:</span>
				{infoIsset(param)[1]}
			</div>
		}
		let infoContent = [];

		infoContent.push(infoItem('razgruzka', 'Нужна разгрузка', 'male'));
		infoContent.push(infoItem('sborka', 'Нужна сборка', 'wrench'));
		//нигде нет, скрыто 03/12/24
		// infoContent.push(infoItem('sborkaPr', 'Нужна сборка на производстве', 'industry'));
		infoContent.push(infoItem('upakovka', 'Нужна упаковка', 'cube'));
		infoContent.push(infoItem('strahovka', 'Страховка груза', 'shield'));
		infoContent.push(infoItem('obreshetka', 'Обрешетка груза', 'hash'));
		infoContent.push(infoItem('dostavka2door', 'Доставка груза до двери', 'truck'));



		content.push(
			<div className={"order-info"} key={"order-info"}>
				{infoContent}
			</div>
		)

		/*
		content.push(<div className="mt20" key={++rowNum}>
			<strong>Нужна разгрузка:</strong> {info.razgruzka != undefined ? bool2YesNo(info.razgruzka) : 'Нет'}
		</div>);

		content.push(<div key={++rowNum}>
			<strong>Нужна сборка:</strong> {info.sborka != undefined ? bool2YesNo(info.sborka) : 'Нет'}
		</div>);

		if (info.sborkaPr)
			content.push(<div key={++rowNum}>
				<strong>Нужна сборка на производстве:</strong> {info.sborkaPr}
			</div>);

		content.push(<div key={++rowNum}>
			<strong>Нужна упаковка:</strong> {info.upakovka != undefined ? bool2YesNo(info.upakovka) : 'Нет'}
		</div>);

		content.push(<div key={++rowNum}>
			<strong>Страховка груза:</strong> {info.strahovka != undefined ? bool2YesNo(info.strahovka) : 'Нет'}
		</div>);

		content.push(<div key={++rowNum}>
			<strong>Обрешетка груза:</strong> {info.obreshetka != undefined ? bool2YesNo(info.obreshetka) : 'Нет'}
		</div>);

		content.push(<div key={++rowNum}>
			<strong>Доставка груза до двери:</strong> {info.dostavka2door != undefined ? bool2YesNo(info.dostavka2door) : 'Нет'}
		</div>);
		 */


		content.push(<div className="mt20" key={++rowNum}></div>);

		if (info.rekvizity && kpCompany === null)
			content.push(
				<FormItem key={++rowNum}
						  edit={false}
						  label={"Реквизиты"}
						  name={""}
						  defVal={info.rekvizity}
						  fieldType={"txt"}
						  ico={"file-text"}
				/>
			);

		if (info.rekvizityFile && kpCompany === null)
			content.push(<div className="" key={++rowNum}>
				<strong>Реквизиты:</strong> <a href={info.rekvizityFile} target={"_blank"}>скачать по ссылке</a>
			</div>);

		if (info.managerComments)
			content.push(
				<FormItem key={++rowNum}
						  edit={false}
						  label={"Комментарии менеджера"}
						  name={""}
						  defVal={info.managerComments}
						  fieldType={"txt"}
						  ico={"commenting"}
				/>
			);

		// console.dir(order);

		if (in_array(user.role, rolesModerator)) {

			let statusOptions = [];
			ORDERS_STATUS.forEach((title, s) => {
				statusOptions.push(
					<option
						key={"status-" + s}
						value={s}>
						{title}
					</option>
				)
			});

			content.push(<div className="mt20" key={++rowNum}></div>);
			if (kpCompany === null)
				content.push(
					<div className="row" key={++rowNum}>
						<div className="col-2-3">
							<FormItem key={++rowNum}
									  edit={true}
									  label={"Статус заказа"}
									  name={"status"}
									  defVal={order.status}
									  fieldType={"select"}
									  ico={"bookmark"}
									  optionsComponent={statusOptions}
									  reff={statusRef}
									  changeAction={e => {
										  setStatusCommentVisible(parseInt(e.target.value) === ORDERS_STATUS_CANCELLED)
									  }}
							/>
						</div>
						<div className="col-3 form-item no-print">
							<label style={{opacity: 0}}> a</label>
							<Button
								type={"save"}
								title={"Обновить статус"}
								onClick={e => saveOrder()}
							/>
						</div>
					</div>
				);

			if (statusCommentVisible)
				content.push(
					<FormItem
						key={++rowNum}
						edit={true}
						label={"Комментариии к статусу"}
						isRequire={true}
						name={"statusComments"}
						defVal={order.statusComments}
						fieldType={"txt"}
						reff={statusCommentRef}
					/>
				);

		}
		// console.log(order);

	}

	if (order.prim)
		content.push(
			<FormItem
				key={"order-prim"}
				edit={false}
				label={"Примечание"}
				isRequire={false}
				name={"prim"}
				defVal={order.prim}
				// fieldType={"txt"}
				// reff={statusCommentRef}
			/>
		);

	let invoiceFromSelect = null;
	let ksSelect = null;
	if (companyList.length) {
		invoiceFromSelect = (
			<select
				onChange={e => generateInvoice(e.target.value)}
			>
				<option value={""}>Сформировать счет от:</option>
				{
					companyList.map((comp, i) => {
						return <option
							key={"cl-" + i}
							value={i}
						>
							{comp.name}
						</option>;
					})
				}
			</select>
		);

		{
			ksSelect = <select
				onChange={e => {
					setKpCompany(e.target.value === '' ? null : companyList[e.target.value]);
				}}
			>
				<option value={""}>Создать КП от:</option>
				{
					companyList.map((comp, i) => {
						return <option
							key={"kp-cl-" + i}
							value={i}
						>
							{comp.name}
						</option>;
					})
				}
			</select>
		}
	}


	let winFooter = (
		<div className={"win-ftr-btns flx -sb order-win-ftr-btns"}>
			<div className={"-btns"}>
				<Button
					type="apply"
					title={"Создать заявку"}
					ico={"tasks"}
					onClick={e => props.history.push(`/tasks/edit/${order.id}-order--`)}
				/>
				<Button
					title={"Распечатать"}
					ico={"print"}
					onClick={printAction}
					cls={"-lgrey"}
				/>
				<Button
					type={"close"}
					onClick={e => props.history.push('/orders')}
				/>

				<div className={"-s4et-kp flx -alc mt20"}>
					<div>
						{invoiceFromSelect}
					</div>
					<div>{ksSelect}</div>
				</div>
			</div>

			{
				in_array(user.role, rolesModerator) ?
					<div className="-del">
						<Button
							type={"delete"}
							ico={"times"}
							onClick={e => onDeleteOrder()}
						/>
					</div>
					: null
			}

		</div>
	);

	let chTitle = '';
	let chParent = [];
	if (kpCompany !== null) {
		winFooter = <div className="flx -sb">
			<Button
				type="apply"
				title={"Закрыть редактирование КП"}
				ico={"close"}
				onClick={e => setKpCompany(null)}
			/>
			<Button
				type={BUTTON_TYPE.delete}
				title={"Скачать в Excel"}
				ico={"file-excel-o "}
				onClick={saveKpAsFile}
			/>
			<Button
				type="save"
				title={"Печать"}
				ico={"print"}
				onClick={e => window.print()}
				style={{padding: '0 3vw'}}
			/>
		</div>
		chTitle = `КП №${order.onum} от ${kpCompany.name}`;

		content.push(
			<FormItem
				key={'kp-comments'}
				edit={true}
				label={"Комментарии менеджера"}
				isRequire={false}
				name={"kpComments"}
				defVal={''}
				fieldType={"txt"}
				cls={"order-kp-comments"}
				descr={"не сохраняются"}
				// reff={statusCommentRef}
			/>
		);

		content.push(
			<div className="order-kp-footer flx -alc -c" key={"kp-ftr"}>
				<div>
					{
						kpCompany.kpp === ''
							? 'Предприниматель'
							: 'Генеральный директор'
					}
				</div>
				{
					(kpCompany.stamp && kpCompany.stamp.url)
						? <div>
							<img src={kpCompany.stamp.url}/>
						</div>
						: null
				}
				<div className={"-nm"}>
					/{kpCompany.boss}/
				</div>
			</div>
		);
	} else {
		chTitle = MENU_TITLE.orders.one
			+ " №" + order.onum
			+ " от " + date("d.m.Y", new Date(order.odate * 1000))
			+ " (" + order.site + ")";
		chParent = [{
			url: '/orders',
			name: MENU_TITLE.orders.many
		}];
	}

	return (
		<div className={cn({'order-kp-view': kpCompany !== null})}>
			<ContentHeader
				title={chTitle}
				showDates={false}
				parent={chParent}
			/>
			<Win
				header={winHeader}
				onClose={e => props.history.push('/orders')}
				footer={winFooter}
			>
				{content}
			</Win>
		</div>
	)

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, /*setCalendarSelectDateAction, setSearchAction*/
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(OrdersShowContainer);


const productNameContent = (domain, product) => {

	let img = product.img;
	if (img && img.indexOf(domain) == -1)
		img = `//${domain}${img}`;

	let url = product.url;
	if (url)
		url = `//${domain}${url}`;


	return <div className="order-prod-name flx">
		{
			img
				? <div className="opn-img">
					<ReactFancyBox
						thumbnail={img}
						image={img}/>
				</div>
				: null
		}
		<div className="opn-r">
			<div className={"nm"}>
				{product.name}
			</div>
			{
				url
					? <div className="link2product">
						<a href={url} target={"_blank"}>Страница товара</a>
					</div>
					: null
			}
		</div>
	</div>;
}

