import {USER_LOGIN, USER_LOGOUT, USER_SET, USER_SET_SETTINGS} from "actions/UserActions";

const initialState = null;

const UserReducer = (state = initialState, action) => {
	// console.log(action.type);
	switch(action.type) {
		case USER_LOGIN:
			return {
				...state,
				...action.payload
			};
		case USER_LOGOUT:
			return null;
		case USER_SET:
			return {
				...state,
				...action.payload
			};
		case USER_SET_SETTINGS:
			let settings = {...state.settings};
			settings[action.payload.key] = action.payload.value;
			return {
				...state,
				settings: settings
			};
		default:
			return state;
	}
}
export default UserReducer;