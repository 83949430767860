import React from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import TasksListContainer from "containers/tasks/TasksListContainer";
import TasksEditContainer, {TasksFields} from "containers/tasks/TasksEditContainer";
import InstallContainer from "containers/tasks/InstallContainer";
import ChangelogContainer from "containers/changelog/ChangelogContainer";
import DeliveryContainer from "containers/tasks/DeliveryContainer";

const TasksRouter = props => {

	return(
		<Switch>
			<Route
				path="/"
				exact={true}
				key={"tasks-index"}
			>
				<TasksListContainer {...props} />
			</Route>
			<Route
				path="/tasks"
				exact={true}
				key={"tasks"}
			>
				<TasksListContainer {...props} />
			</Route>
			<Route
				path="/tasks/date/:paramDateStart"
				exact={true}
				key={"tasks-4-day"}
			>
				<TasksListContainer {...props} />
			</Route>
			<Route path="/tasks/todo/:todo" exact={true} key={"tasks-todo"}>
				<TasksListContainer {...props} forTodo={true} />
			</Route>
			<Route path="/tasks/edit/:params" exact={true}>
				<TasksEditContainer {...props} />
			</Route>
			<Route path="/tasks/changelog/:id" exact={true}>
				<ChangelogContainer
					{...props}
					part={"tasks"}
					fields={TasksFields}
				/>
			</Route>
			<Route path="/tasks/install/:id" exact={true}>
				<InstallContainer {...props} />
			</Route>
			<Route path="/tasks/delivery/:id" exact={true}>
				<DeliveryContainer {...props} />
			</Route>

			<Route component={Page404} />
		</Switch>
	)

};

export default TasksRouter;
