import React, {useState} from 'react';
import SheetsTableItem from "./SheetsTableItem";

const SheetsTable = props => {
    const {
        sheetName, columns, data, onCellChange, user, tabId,
        selectedCellCoord, onSelectedCellCoord,
        canEdit
    } = props;

    // const onChange = (e, key, val = null) => {
    //     onCellChange(tabId, data.id, key, val ? val : e.currentTarget.innerText);
    // };
    // const {data:grid = {}} = data;
    // console.log( typeof data.data[tabId], Array.isArray(data.data[tabId]));
    // console.dir(data.data[tabId]);
    // console.log(Object.keys(data.data[tabId]), Math.max(Object.keys(data.data[tabId])));

    let _maxRows = 1;
    if (data.data[tabId]) {
        // _maxRows = data.data[tabId].length + _maxRows;
        let m = 0;
        Object.keys(data.data[tabId]).map((rowNum) => {
            let irowNum = parseInt(rowNum);
            if (irowNum > m)
                m = irowNum;
        });
        // console.log(m);
        _maxRows = m + 1;
    }
    // console.log(_maxRows);
    const [maxRows, setMaxRows] = useState(_maxRows);


    // console.log(sheetName, canEdit);

    return (
        <>
            <table className={"sheets-table tbl dark hdr-cntr -bordered"}>
                <thead>
                <tr>
                    <td className={"col-num"} width={"3%"}>
                        &nbsp;
                    </td>
                    {
                        columns.map((col, i) => {
                            // console.log(i, col);
                            return (
                                <td
                                    // className={"Xtd"}
                                    key={`sheets-tbl-thead-${tabId}-${i}`}
                                    // style={{width: `${col.width}%`}}
                                    width={`${col.width}%`}
                                >
                                    {col.name}
                                </td>
                            );
                        })
                    }

                </tr>
                </thead>
                <tbody className={"tbody"}>
                {
                    [...Array(maxRows)].map((val, rowNumber) => {
                        // console.log(rowNumber);
                        return <SheetsTableItem
                            key={`sti-${sheetName}-${tabId}-${rowNumber}`}
                            sheetName={sheetName}
                            columns={columns}
                            rowNumber={rowNumber}
                            data={data}
                            onCellChange={onCellChange}
                            user={user}
                            tabId={tabId}
                            selectedCellCoord={selectedCellCoord}
                            onSelectedCellCoord={onSelectedCellCoord}
                            editable={canEdit}
                        />
                    })
                }

                {/*<SheetsTableItem*/}
                {/*    sheetName={sheetName}*/}
                {/*    columns={columns}*/}
                {/*    rowNumber={0}*/}
                {/*    data={data}*/}
                {/*    onCellChange={onCellChange}*/}
                {/*    user={user}*/}
                {/*    tabId={tabId}*/}
                {/*    selectedCellCoord={selectedCellCoord}*/}
                {/*    onSelectedCellCoord={onSelectedCellCoord}*/}
                {/*/>*/}
                </tbody>
            </table>
            <div
                className={"sheets-add-row"}
                onClick={() => setMaxRows(maxRows + 1)}
            >
                + добавить ряд
            </div>
        </>
    );

};

export default SheetsTable;