import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {NavLink, useLocation} from "react-router-dom";
import {urlGetParams} from "utils/func";
import Api, {apiAction} from "utils/Api";
import {toastr} from "react-redux-toastr";
import Button, {BUTTON_TYPE} from "components/_common/Button";

/**
 * Получение заказа с сайта,
 * http://sklad-domain.tld/orders/get/?url=https://shop-name.ru&id=123
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const OrdersGet = props => {

	const {user, actions, history} = props;

	const GET = urlGetParams(useLocation());

	const [orderAddedInfo, setOrderAddedInfo] = useState(null);


	let apiActionProcess = false;
	const sendRequest = async (isSubscribe = true, action, params, callback = null) => {

		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('orders.php', {
				...params,
				action: action,
				userId: user.id,
			});

			apiAction(res, (data) => {

				if (data.id && parseInt(data.id)) {

					setOrderAddedInfo({
						id: parseInt(data.id),
						managerInfo: data.managerInfo
					});
					// history.push('/forms/');
				}

				if (typeof callback == 'function')
					callback(data);

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});


		}
	};
	useEffect(() => {
			let isSubscribe = true;

			let params = GET;
			if (GET.email !== undefined && GET.email !== '') {

				if (user.email == '')
					toastr.warning('Внимание', 'Чтобы получать именные заказы, необходимо указать свой email в настройках аккаунта');
				else  {

					if (user.email !== GET.email)
						toastr.confirm(
							'Эта заявка другого менеджера. Вы точно уверены что хотите её забрать?',
							{
								okText: 'Да',
								cancelText: 'Нет',
								onOk: () => {
									sendRequest(isSubscribe, 'getFromSite', params).then();
								},
								onCancel: () =>  history.push('/orders/')

							}
						);
					else
						sendRequest(isSubscribe, 'getFromSite', params).then();
				}
			}
			else {
				console.log('send');
				sendRequest(isSubscribe, 'getFromSite', params).then(r => {
				});
			}


			return () => {
				isSubscribe = false;
			}
		},
		[]
	);

	let infoBlock;
	if (orderAddedInfo) {
		if (orderAddedInfo.managerInfo && orderAddedInfo.managerInfo.id !== user.id) {
			infoBlock = (
				<div>
					<h2>Заказ оформлен на менеджера <span className={"clr-red"}>{orderAddedInfo.managerInfo.name}</span></h2>
					<h3>Если он замечтался и до сих пор его не принял просьба его уведомить об этом! </h3>
					<div className="_tac mt20">
						<h2>Перезаписать его на Вас?</h2>
					</div>
					<div className="mt20 flx -c">

						<Button
							onClick={async () => await sendRequest(true, 'setField', {
								id: orderAddedInfo.id,
								field: 'uid',
								value: user.id
							}, () => {
								history.push('/orders/show/' + orderAddedInfo.id);
							})}
							title={"Да"}
							type={BUTTON_TYPE.save}
							style={{padding: '0 40px'}}
						/>

						<Button
							href={"/orders/"}
							title={"Нет"}
							style={{padding: '0 30px', margin: '0 0 0 30px'}}
							type={BUTTON_TYPE.close}
						/>
					</div>
				</div>
			);
		} else
			infoBlock = (
				<div>
					<h2>Заказ принял <span className={"clr-red"}>{orderAddedInfo.managerInfo.name}</span></h2>
					<br/>
					<NavLink to={"/orders/show/" + orderAddedInfo.id}>
						Перейти к редактированию заказа
					</NavLink>
				</div>
			);
	}
	else
		infoBlock = 'Ошибка получения заказа';

	return (
		<>
			<h1>Получение данных заказа.</h1>
			{
				orderAddedInfo ?
					<div className={"_tac"}>
						<h2>Заказ получен с сайта {GET.url}</h2>
						<br />
						{infoBlock}
					</div>
					: null

			}

		</>
	)

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(OrdersGet);
