import React from 'react';

const ManagerSelect = ({managers, colNumber, rowNumber, value = 0, onChange}) => {

    return (
        <select
            defaultValue={value}
            onChange={e => {
                console.log(e.target.value);
                onChange(e.target.value);
            }}
        >
            <option value={0}>Выберите менеджера</option>
            {
                managers.map(man => {
                    return (
                        <option
                            key={`man-sel-${rowNumber}-${colNumber}-${man.id}`}
                            value={man.id}

                        >
                            {man.name}
                        </option>
                    );
                })
            }
        </select>
    )

};

export default ManagerSelect;