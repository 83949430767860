import React from 'react';
import * as uniqid from "uniqid";

/**
 *
 * @param Map statuses
 * @param Object statusSum
 * @param Map styles
 * @returns {JSX.Element}
 * @constructor
 */
const StatusThermometer = ({statuses, statusSum, styles}) => {

	// console.dir(statusSum);
	let statusSumNew = {};
	let totalSum = 0;
	for (let status in statusSum) {
		let st = status;
		totalSum += statusSum[status];

		if (`${status}`.indexOf('4') === 0) {
			st = 4;
		}
		if (!statusSumNew.hasOwnProperty(st))
			statusSumNew[ st ] = 0;

		statusSumNew[ st ] += statusSum[status];
	}
	// console.dir(statusSumNew);
	// console.log(`totalSum = ${totalSum}`);


	return (
		<div className={"status-thermometer"}>
			{
				Object.keys(statusSumNew).map((status, i) => {
					status = parseInt(status);
					let value = statusSumNew[status];
					let percent = Math.ceil((value / totalSum) * 100);
					// console.log(status, `${percent}%`);
					// console.log(styles.get(status));
					// console.log({...styles.get(status), width: `${percent}%`});

					let statusStyle = styles.get(status);
					// console.log(statusStyle);
					if (statusStyle == undefined)
						statusStyle = {background: '#fff'};


					return <div
						key={status + uniqid()}
						className={""}
						style={{...statusStyle, width: `${percent}%`}}
					>
						<div className={"tip"}>
							{statuses.get(status)} - {value}шт
						</div>
					</div>
				})
			}
		</div>
	)

};

export default StatusThermometer;