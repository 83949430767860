import React from 'react';
import {MENU, rolesAdmin} from "utils/config";
import {NavLink} from "react-router-dom";
import {in_array} from "utils/func";

const SideMenu = (props) => {

	const {setScrollClassName, user, GUI} = props;

	// console.dir(GUI.menuCounters);
	// let location = useLocation();
	// console.log(user);

	const getSubmenu = (menu, i) => {
		let hasIco = false;
		menu.map((item, j) => {
			if (item.ico)
				hasIco = true;
		});
		return <ul className="menu-sub pup" key={"sm-sub-" + i}>
			{menu.map((item, j) => {
				if (!item.roles.length || in_array(user.role, item.roles))
					return (
						<li key={"sm-sub-itm-" + j}>
							<NavLink
								to={item.url}
								exact
							>
								{item.ico ? <i className={"fa " + item.ico} /> : (hasIco ? <i /> : null)}
								<span>
								{item.name}
								</span>
							</NavLink>
							{(item.menu && item.menu.length) ? getSubmenu(item.menu, j) : null}
						</li>
					);

				return null;
			})}
		</ul>;
	};

	const menuItem = (item, i) => {


		// console.log(item);
		if (typeof item == 'string' && item === 'separator')
			return (
				<li key={"sm-" + i}>
					<hr/>
				</li>
			);
		else {
			let onMouseEnterFunc = null;
			let onMouseLeaveFunc = null;
			let submenu = null;
			let submenuList = item.menu;

			const itemCode = item.url.substring(1, item.url.length);

			if (item.url === '/user' && user.userRoles) {

				const sm = submenuList;


				let subMenuUser = [];
				for (const role in user.userRoles) {
					subMenuUser.push({
						name: user.userRoles[role],
						url: '/user/role/' + role,
						ico: 'fa-user',
						roles: []
					});
				}
				submenuList = subMenuUser.concat(sm);
			}


			if (submenuList) {
				onMouseEnterFunc = (e) => {
					// setScrollClassName('menu-side-scroll');
					const submObj = e.target.nextElementSibling;
					if (submObj) {
						// submObj.position();
						const {height, top} = submObj.getBoundingClientRect();
						//
						// console.log(window.innerHeight);
						// console.log(top);
						// console.log(height);
						if ((top + height) > window.innerHeight) {

							submObj.style.top = window.innerHeight - (top + height) + 'px';
						}
						else
							submObj.style.top = top;


					}

				};
				onMouseLeaveFunc = () => {
					// setScrollClassName('');
				};
				if (submenuList && submenuList.length)
					submenu = getSubmenu(submenuList);
			}
			let navExact = false;


			// console.dir(item);
			let counter = (GUI.menuCounters && GUI.menuCounters[ itemCode ]) ? GUI.menuCounters[ itemCode ] : 0;


			return (
				<li
					key={"sm-" + i}
					// className=""
					onMouseEnter={onMouseEnterFunc}
					onMouseLeave={onMouseLeaveFunc}
					title={item.name}
				>
					<NavLink
						to={item.url}
						key={"sma-" + i}
						exact={navExact}
					>
						{item.ico ? <i className={"fa " + item.ico} /> : <i className={"fa fa-cubes"}/>}
						<span>
							{item.name}
						</span>
						{ submenu ? <i className={"fa fa-angle-right"}></i> : null}
						{
							(parseInt(counter) > 0)
								? <div className="counter">
									{counter}
								</div>
								: null
						}
					</NavLink>
					{submenu}
				</li>
			);
		}
	};
	// let menuTmp = MENU.concat(MENU);

	return (
		<nav className="menu">
			<ul className="_z">
				{MENU.map((item, i) => {
					if (!item.roles.length || in_array(user.role, item.roles))
						if (!item.divisions
							|| in_array(parseInt(user.divId), item.divisions)
							|| in_array(user.role, rolesAdmin))
							return menuItem(item, i);

					return null;
				})}
			</ul>
		</nav>
	)
};
export default SideMenu;
