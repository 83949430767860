import React, {createRef, useState} from 'react';
import cn from "classnames";
import {toastr} from "react-redux-toastr";
import Switch from "react-switch";
import {Button, SignatureWindow, FormItem, FilesList} from "components/_common";
import {BUTTON_TYPE} from "components/_common/Button";
import Api, {apiAction} from "utils/Api";
import DocsUploadBox from "components/docs/DocsUploadBox";
import date from "php-date";
import * as strtotime from "strtotime";
import {LOGO_TITLE, MESSAGES} from "utils/config";
import {downloadFile, in_array} from "utils/func";
import DocsListFiles from "components/docs/DocsListFiles";
import {logger} from "redux-logger/src";
import {deleteTask} from "../../containers/tasks/TasksListContainer";


const API_URL = 'tasks.php';

const DeliveryEdit = props => {
    const {
        GUI, user, actions, closeUrl, history,
        task,
        delivery,
        moversAll,
    } = props;

    let _movers = {};
    if (delivery && delivery.movers && delivery.movers.length) {
        delivery.movers.map((mid, i) => {
            _movers[i] = mid;
        });
    }


    const [data, setData] = useState({
        taskId: task ? task.id : 0,

        // comments: delivery ? delivery.comments : '',

        deliveryOblast: (task && task.mkadKm > 0),
        mkadKm: task ? task.mkadKm : 0,

        hasMovers: false,
        movers: _movers,// ID list

        photosFiles: delivery ? delivery.photosFiles : [],
        photosFiles4Upload: [],
    });


    let apiActionProcess = false;
    const deleteFile = (fileId) => {
        console.log('delete file', data.taskId, fileId);
        toastr.confirm(
            MESSAGES.confirmAction,
            {
                okText: 'Да',
                cancelText: 'Нет',
                onOk: async () => {
                    // console.log('delete file', data.id, fileId);
                    if (!apiActionProcess) {
                        actions.showLoading();
                        apiAction(await Api.post(API_URL, {
                            action: 'deleteDeliveryFiles',
                            user: user,
                            id: data.taskId,
                            fileId: fileId
                        }), (resp) => {

                            // setData(prev => ({
                            //     ...prev,
                            //     [photosFiles]: resp.files
                            // }));

                        }, () => {
                            apiActionProcess = false;
                            actions.hideLoading();
                        });
                    }
                }
            }
        );
    };
    const save = async () => {
        // console.clear();
        // console.log('save');
        console.dir(data);

        let errors = [];

        if (data.deliveryOblast && parseInt(data.mkadKm) === 0)
            errors.push('Укажите расстояние от МКАД');
        if (data.hasMovers && Object.values(data.movers).length === 0)
            errors.push('Выберите грузчиков');

        if (errors.length) {
            {
                errors.map((txt, i) => {
                    if (txt != '')
                        toastr.error('Ошибка', txt);
                })
            }
        }
        else {

            toastr.confirm(
                MESSAGES.confirmAction,
                {
                    okText: 'Да',
                    cancelText: 'Нет',
                    onOk: async () => {

                        const formData = new FormData();

                        formData.append('user', JSON.stringify({id: user.id}));
                        formData.append('action', 'saveDelivery');

                        data.photosFiles4Upload.map((file, i) => {
                            formData.append('file_' + i, file);
                        });

                        for (let param in data) {
                            let value = data[param];
                            if (param !== 'photosFiles4Upload') {

                                // if (param == 'movers')
                                //     value = Object.values(value);

                                formData.append(param, JSON.stringify(value));
                            }
                        }

                        if (!apiActionProcess) {
                            actions.showLoading();
                            apiAction(await Api.post(API_URL, formData), (resp) => {
                                // console.dir('saveDelivery', resp);

                                console.log(closeUrl);
                                history.push(closeUrl);

                            }, () => {
                                apiActionProcess = false;
                                actions.hideLoading();
                            });
                        }

                    }
                });
        }


    }

    let moversSelects = [];
    if (data.hasMovers) {

        let moversOptions = {
            0: {
                value: 'выберите грузчика'
            }
        };
        Object.values(moversAll).map(mover => {
            moversOptions[mover.id] = {
                value: mover.name,
                disabled: false
            };
        });

        let maxMovers = Object.values(data.movers).length + 1;

        for (let i = 0; i < maxMovers; i++) {
            let _moversOptions = {...moversOptions};
            let _id = data.movers.hasOwnProperty(i) ? data.movers[i] : 0;
            if (_id !== 0 && _moversOptions.hasOwnProperty(_id))
                _moversOptions[_id]['disabled'] = true;

            moversSelects.push(
                <FormItem
                    key={`movers-selects-${i}`}
                    edit={true}
                    name={"mover"}
                    fieldType={"select"}
                    options={_moversOptions}
                    label={""}
                    defVal={_id}
                    changeAction={(e, val) => {
                        console.log(val);
                        let moversNew = {...data.movers};
                        moversNew[i] = val;
                        setData(prev => ({
                            ...prev,
                            movers: moversNew
                        }));
                    }}
                    isRequire={false}
                />
            );
        }

    }


    return (
        <div className={"task-delivery"}>
            <div className={"flx -alc oblast"}>
                <div className={"-switch"}>
                    <span>Доставка в область</span>
                    <Switch
                        onChange={() => {
                            setData((prev) => ({
                                ...prev,
                                deliveryOblast: !data.deliveryOblast
                            }));
                        }}
                        checked={data.deliveryOblast}
                    />
                </div>
                {
                    data.deliveryOblast ?
                        <FormItem
                            name={"mkadKm"}
                            label={"Расстояние от МКАД, км"}
                            labelLeft={true}
                            fieldType={"text"}
                            isRequire={true}
                            defVal={data.mkadKm}
                            blurAction={(e, val) => {
                                setData((prev) => ({
                                    ...prev,
                                    mkadKm: parseInt(val)
                                }));
                            }}
                        />
                        : null
                }
            </div>

            <div className={"flx movers"}>
                <div className={"-switch"}>
                    <span>Разгрузка наша</span>
                    <Switch
                        onChange={() => {
                            setData((prev) => ({
                                ...prev,
                                hasMovers: !data.hasMovers
                            }));
                        }}
                        checked={data.hasMovers}
                    />
                </div>
                <div className={"movers-list"}>
                    {moversSelects}
                </div>
            </div>
            <div className={"photos form-item"}>
                <label>Фото</label>
                <DocsUploadBox
                    showOnlyUploadBox={true}
                    settings={user.settings}
                    filesList={data.photosFiles4Upload}
                    setFilesList={(_files) => {
                        // console.dir(_files);
                        setData((prev) => ({
                            ...prev,
                            photosFiles4Upload: _files,
                        }));

                    }}
                    GUI={GUI}
                    formRef={null}
                />
                <div className="mt20">
                    <FilesList
                        files={data.photosFiles}
                        onDelete={(fileId) => deleteFile(fileId)}
                    />
                </div>
            </div>
            <Button
                type={"save"}
                title={"Завершить доставку"}
                ico={"check-circle-o"}
                onClick={e => save()}
            />
        </div>
    )

};

export default DeliveryEdit;