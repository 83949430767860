import React, {createRef, useEffect, useState, useCallback} from 'react';
import {Win, Button} from "components/_common";
import cn from "classnames";
import date from "php-date";
import {useDropzone} from 'react-dropzone';
import FormItem from "components/_common/FormItem";
import {toastr} from "react-redux-toastr";
import {getFileSize, array_unset, in_array} from "utils/func";

const DocsUploadBox = props => {

	const {
		GUI, formRef, settings,
		filesList, setFilesList, uploadAction,
		forUser = false,
		showOnlyUploadBox = false
	} = props;
	// const [buttonDisabled, setButtonDisabled] = useState(true);

	const {upload_max_filesize = 2097152} = settings;


	const onDrop = useCallback(files => {
		// console.log(files);
		let fsSum = 0;
		files.forEach((file, i) => {
			fsSum += file.size;

			files[i]['preview'] = URL.createObjectURL(file);

		});

		// if (fsSum > upload_max_filesize) {
		// 	toastr.error('Ошибка',
		// 		'Максимальный размер загружаемых файлов не должен превышать '
		// 		+ getFileSize(upload_max_filesize)
		// 	);
		// }

		setFilesList(files);
	}, []);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDrop: onDrop,
		noClick: filesList.length !== 0,
	});

	const form = <form ref={formRef}>
		<FormItem
			name={"name"}
			fieldType={"text"}
			label={"Название файла"}
		/>
		{
			!forUser ? (
				<>
					<FormItem
						name={"naklNum"}
						fieldType={"text"}
						label={"Номер накладной"}
					/>
					<FormItem
						name={"fDate"}
						fieldType={"date"}
						label={"Дата"}
						defVal={(GUI && GUI.calendarDates && GUI.calendarDates.start)
							? date('Y-m-d', GUI.calendarDates.start)
							: ''}
						descr={"на которую загружается файл"}
					/>
				</>
			) : null
		}

	</form>;


	// const removeFileFromList = ();
	let dropBoxFiles = null;
	if (filesList.length) {
		let filesSizeTotal = 0;

		dropBoxFiles = (
			<table className="tbl dark docs-upl-files-list">
				<thead>
				<tr>
					<td width={"5%"}>#</td>
					<td width={"10%"}>&nbsp;</td>
					<td width={"55%"}>Наименование</td>
					<td width={"20%"} align={"center"}>Размер</td>
					<td width={"10%"} align={"center"}>
						<i
							title={"Удалить все файлы"}
							className={"fa fa-trash-o"}
							onClick={e => setFilesList([])}
						/>
					</td>
				</tr>
				</thead>
				<tbody>
				{
					filesList.map((file, i) => {
						filesSizeTotal += file.size;
						// console.dir(file);
						let img;
						if (file.type.indexOf('image/') !== -1)
							img = <img src={file.preview}/>;
						else
							img = <i className={"fa fa-file"}></i>
						return (
							<tr key={"drop-box-file-list-" + i}>
								<td>{(i + 1)}</td>
								<td>{img}</td>
								<td>{file.name}</td>
								<td align={"center"}>{getFileSize(file.size)}</td>
								<td align={"center"}>
									<i
										className={"fa fa-close"}
										onClick={e => {
											let tmp = [...filesList];
											tmp = array_unset(tmp, i);
											// console.log(tmp);
											setFilesList(tmp);
										}}
									/>
								</td>
							</tr>
						)
					})
				}
				</tbody>
				{
					filesList.length > 1 ? (
						<tfoot>
						<tr>
							<td colSpan={2}>&nbsp;</td>
							<td align={"center"}>{getFileSize(filesSizeTotal)}</td>
							<td></td>
						</tr>
						</tfoot>
					) : null
				}

			</table>
		);
	}


	const uploadBox = <div
		className={cn("docs-uploader", {isDragActive: isDragActive})}
		{...getRootProps()}
	>
		{
			filesList.length === 0 ? (
				<div className="docs-uploader-txt _tac">
					Нажмите сюда для выбора файлов<br /> или<br /> перетащите их на поле
				</div>
			) : null
		}

		{dropBoxFiles}

		<input {...getInputProps()} />
	</div>;


	if (showOnlyUploadBox)
		return uploadBox;

	return (
		<Win
			header={"Загрузка файлов"}
			minimized={true}
			id={'DocsUploadBox'}
			footer={(
				<div className={"win-ftr-btns _tac"}>
					<Button
						type="apply"
						title={"Загрузить"}
						onClick={uploadAction}
						ico={"upload"}
						disabled={!filesList.length}
					/>
				</div>
			)}
		>
			<div

			>
				<div className="row">
					<div className="col-3">
						{form}
					</div>
					<div
						className={"col-2-3"}
					>
						{uploadBox}
					</div>

					{/*<div*/}
					{/*	className={cn("docs-uploader col-2-3", {isDragActive: isDragActive})}*/}
					{/*	{...getRootProps()}*/}
					{/*>*/}
					{/*	{*/}
					{/*		filesList.length === 0 ? (*/}
					{/*			<div className="docs-uploader-txt">*/}
					{/*				Выберите файл(ы) или перетащите сюда*/}
					{/*			</div>*/}
					{/*		) :  null*/}
					{/*	}*/}
					{/*	{dropBoxFiles}*/}
					{/*</div>*/}
				</div>


			</div>


		</Win>
	)

};

export default DocsUploadBox;
