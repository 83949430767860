import React from 'react';
import {NavLink} from "react-router-dom";
import cn from "classnames";
import {rolesAdmin} from "utils/config";
import {in_array, isModerator} from "utils/func";

/**
 * Кноки- вкладок/подразделов в заявках
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TasksTabs = props => {

    const {
        id = 0,
        user,
        tab = '',
        task = null,
    } = props;

    const isAdmin = in_array(user.role, rolesAdmin);
    const isModer = isModerator(user);
    const isInstaller = in_array(user.role, ['installer']);
    const isDriver = in_array(user.role, ['driver']);

    let hasInstall = task && task.installers && task.installers.length > 0;
    let hasDelivery = task && task.driverId != 0;


    return (
        <div className={"tasks-tabs"}>
            <div className={cn("-tab", {active: tab == 'edit'})}>
                <NavLink to={`/tasks/edit/${id}`}>
                    <i className={"fa fa-info-circle"}></i>
                    Заявка
                </NavLink>
            </div>
            {
                (hasInstall && (isModer || isInstaller))
                    ? <div className={cn("-tab", {active: tab == 'install'})}>
                        <NavLink to={`/tasks/install/${id}`}>
                            <i className={"fa fa-puzzle-piece"}></i>
                            Монтаж


                        </NavLink>
                    </div>
                    : null
            }
            {
                (hasDelivery && (isModer || isDriver))
                    ? <div className={cn("-tab", {active: tab == 'delivery'})}>
                        <NavLink to={`/tasks/delivery/${id}`}>
                            <i className={"fa fa-truck"}></i>
                            Доставка
                        </NavLink>
                    </div> : null
            }
            {
                isAdmin ? <div className={cn("-tab", {active: tab == 'changelog'})}>
                    <NavLink to={`/tasks/changelog/${id}`}>
                        <i className={"fa fa-history"}></i>
                        История изменений
                    </NavLink>
                </div> : null
            }

        </div>
    )

};

export default TasksTabs;
