import React from "react";
import * as strtotime from "strtotime";
import {in_array} from "utils/func";
import {rolesModerator} from "utils/config";

const toolsIcoStyle = {
	height: '38px',
	marginRight: '5px'
};
// export const ToolsIcons = [
// 	<img src={"/img/drilling-machine.png"} style={toolsIcoStyle} />,
// 	<img src={"/img/angle-grinder.png"} style={toolsIcoStyle} />,
// 	<img src={"/img/fretsaw.png"} style={toolsIcoStyle}/>,
// 	<img src={"/img/soldering.png"} style={toolsIcoStyle}/>
// ];
export const ToolsIcons = {
	perforator: "/img/drilling-machine.png",
	bolgarka: "/img/angle-grinder.png",
	lobzik: "/img/fretsaw.png",
	payalnik: "/img/soldering.png"
};
export const isEditableTask = (taskDate, actualDays ) => {
	taskDate = taskDate + ' 23:59:00';

	let daysMilliseconds = actualDays * 86400 * 1000;

	return strtotime(taskDate) * 1000 >= Date.now() - daysMilliseconds;
};
export const driverSelectComponent = (userRole, drivers, driverId, onChangeAction) => {
	let driverSelect = null;


	const likeAdmin = in_array(userRole, ['logist', ...rolesModerator]);

	if (!userRole || likeAdmin) {
		let defVal = 0;
		if (parseInt(driverId) && (driverId in drivers)) {
			defVal = driverId;
		}

		driverSelect = (
			<select
				name={"driverId"}
				defaultValue={defVal}
				onChange={e => {
					if (onChangeAction)
						onChangeAction(e);
				}}
			>
				<option
					key={0}
					value={0}
				>
					не назначен
				</option>
				{
					Object.keys(drivers).map(id => {
						return (<option
								key={id}
								value={drivers[id].id}
							>
								{drivers[id].name}
								{
									(!likeAdmin && drivers[id].car)
									? ` (${drivers[id].car})`
 									: null
								}
							</option>
						);
					})
				}
			</select>
		);
	}

	return driverSelect

	// return (
	// 	<>
	// 		<div className="no-print">
	//
	// 		</div>
	// 		<div className="print-only">
	// 			{driverSelectedPrint}
	// 		</div>
	// 	</>
	// );
};
export const installersSelectComponent = (userRole, isEditable, installersMax, installers, taskInstallers, onChangeAction,
										  docsLinks = []) => {
	let installersSelect = [];
	let installersPrint = [];


	for (let i = 0; i < installersMax; i++) {
		let defVal = 0;
		if (taskInstallers[ i ] !== undefined) {
			defVal = taskInstallers[i];

			if (installers[defVal])
				installersPrint.push(
					<div key={"inst-print-" + i}>
						{installers[defVal].name}
					</div>
				);
		}

		if (isEditable && in_array(userRole, ['logist', ...rolesModerator])) {
			installersSelect.push(
				<div className="mt5" key={"task-installers-" + i}>
					<select
						name={"installerId"}
						defaultValue={defVal}
						onChange={e => {
							if (onChangeAction)
								onChangeAction(e, i);
						}}
					>
						<option
							key={'installerId' + i + 0}
							value={0}
						>
							не назначен
						</option>
						{
							Object.keys(installers).map(id => {
								return (<option
										key={'installerId' + i + id}
										value={id}
									>
										{installers[id].name}
									</option>
								);
							})
						}
					</select>
					{
						docsLinks[i] && docsLinks[i]
					}
				</div>
			);
		}
		else {
			if (defVal in installers) {
				installersSelect.push(
					<div className="mt5 w100" key={"task-installers-" + i}>
						{i + 1}. {installers[defVal].name} ({installers[defVal].phone})
						{
							docsLinks[i] ? (
								<>
									<br />
									{docsLinks[i]}
								</>

							) : null
						}
					</div>
				);
			}
		}
	}

	return [installersSelect, installersPrint];
};
export const expectedTaskInstallers = task => {
	let count = 0;
	if (task.deliveryInfo && task.deliveryInfo.installers_max)
		count = parseInt(task.deliveryInfo.installers_max);
	else {
		//поддержка старых заявок
		if (in_array(task.todo, ['di', 'mkaddi', 'nightdi', 'nightmkaddi', 'instself']))
			count = 1;

		if (in_array(task.todo, ['di2', 'mkaddi2', 'nightdi2', 'nightmkaddi2', 'dovoz_dodelka']))
			count = 2;
	}

	return count;
};

export const sortTasks = (tasks, user, divisions, users) => {

	let groupSnabjenie = [],
		groupPeremeshenie = [],
		//для кладовщика
		groupKladovshik = [],
		//для закупок
		groupBuy		= [],
		//массив массивов по разным офисам
		divisionsGroups = {},
		//всего заявок
		total = tasks.length,
		//всего водителей
		totalDrivers	= 0,
		//всего монтажников
		totalInstallers = 0,
		//монтажники, указанные при создании заявки
		expectedTaskInstallersTotal = 0;
		// notAssign			= [];


	tasks.map(task => {
		// console.log(task);

		let _todo = task.todo;

		if (/moving/.test(task.todo))
			_todo = 'moving';

		switch (_todo) {
			case "snab":
				groupSnabjenie.push(task);
				break;
			case "moving":
				groupPeremeshenie.push(task);
				break;
			case "buy":
				groupBuy.push(task);
				break;
			case "storekeeper-bor":
			case "storekeeper-kol":
				groupKladovshik.push(task);
				break;
			case "buy":
				groupBuy.push(task);
				break;
			default:

				if (!divisionsGroups.hasOwnProperty(task.divId) && divisions.hasOwnProperty(task.divId))
					divisionsGroups[task.divId] = {
						id: task.divId,
						name: divisions[task.divId].name,
						forUser: {}
					};
				if (task.divId && divisionsGroups[task.divId]) {
					if (!divisionsGroups[task.divId].forUser.hasOwnProperty(task.userId))
						divisionsGroups[task.divId].forUser[task.userId] = {
							name: users[task.userId] ? users[task.userId].name : 'Пользователь не найден ('+task.userId+')',
							tasks: []
						};

					divisionsGroups[task.divId].forUser[task.userId].tasks.push(task);
				}
				break;
		}

		if (parseInt(task.driverId))
			totalDrivers++;

		const thisMaxInstallers = expectedTaskInstallers(task);
		expectedTaskInstallersTotal += thisMaxInstallers;


		if (task.installers && thisMaxInstallers)
			totalInstallers += task.installers.length;


		return null;
	});


	let tmpDivGroups = [];
	if (user.divId && divisionsGroups.hasOwnProperty(user.divId)) {

		tmpDivGroups.push(divisionsGroups[user.divId]);

		Object.keys(divisionsGroups).map(divId => {
			divId = parseInt(divId);

			if (divId !== user.divId )
				tmpDivGroups.push(divisionsGroups[divId]);

			return null;
		});

		divisionsGroups = tmpDivGroups;
		tmpDivGroups = null;
	}
	else
		divisionsGroups = Object.values(divisionsGroups);


	return {
		groupSnabjenie: groupSnabjenie,
		groupPeremeshenie: groupPeremeshenie,
		groupKladovshik: groupKladovshik,
		groupBuy: groupBuy,
		divisionsGroups: divisionsGroups,
		total: total,
		totalDrivers: totalDrivers,
		totalInstallers: totalInstallers,
		expectedTaskInstallers: expectedTaskInstallersTotal,
	};
};

export const canSendSms = task => {
	if (!task)
		return false;
	if (task.smsSend == 1)
		return false;

	if (task.deliveryInfo && task.deliveryInfo.type && task.deliveryInfo.type === 'tk')
		return false;

	return true;
}
export const canCreateTask = userRole => {

	return in_array(userRole, ['buyer', 'manager', ...rolesModerator]);
	// if (userRole !== 'manager')
	// 	return true;
	// // let d = new Date();
	// // let dow = d.getDay();
	// // console.log(dow, d.getHours());
	// // убрал 10/07/23
	// // if ((dow == 5 && d.getHours() >= 17) || (dow == 6 && d.getHours() >= 16))
	// // 	return false;
	//
	// return true;
}