import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

import {ContentHeader, Win, Button, FormItem} from "components/_common";


const SignatureWindow = props => {

	const {
		title = 'Заголовок',
		onSave = null,
		onClose
	} = props;

	let sigCanvas = {};


	return (
		<div className="popup-win">
			<Win
				winClass="signature-window"
				header={title}
				// onClose={e => onClose(false)}
				onClose={onClose}
				footer={
					(
						<div className={"win-ftr-btns flx -sb"}>

							<Button
								type="close"
								title={"Стереть"}
								onClick={() => sigCanvas.clear()}
							/>

							<Button
								title={"Сохранить"}
								onClick={() => {
									console.clear();
									console.log('save sig');
									let sig = sigCanvas.getTrimmedCanvas().toDataURL('image/png');

									if (typeof onSave === 'function') {
										onSave(sig, sig.split(',')[1]);
									}
								}}
							/>
						</div>
					)
				}
			>
				<div className="canvas-wrp">
					<SignatureCanvas
						penColor='blue'
						canvasProps={{
							width: window.innerWidth * 0.8,
							height: 400,
							className: 'sig-canvas'
						}}
						// ref={sigRef}
						ref={(ref) => { sigCanvas = ref }}
					/>
				</div>



			</Win>
		</div>

	)

};

export default SignatureWindow;
