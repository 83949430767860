import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {useParams} from "react-router-dom";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Button} from "components/_common";
import {in_array} from "utils/func";
import {rolesModerator} from "utils/config";
import {UsersList} from "components/user";

const UserListContainer = props => {

	const {user, actions} = props;
	const {roleName = ''} = useParams();


	const [users, setUsers] = useState([]);
	const [usersShow, setUsersShow] = useState([]);

	let apiActionProcess = false;
	const getUsers = async (isSubscribe) => {

		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('users.php',  {
				action: 'getUsers',
				user: user,
				onlyRole: roleName
			}).catch(er => {
				toastr.error('Ошибка запроса', er.toString(), {timeOut: 0});
			});

			apiAction(res, (data) => {
				setUsers(data.users);
				setUsersShow(data.users);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}

	};
	useEffect(() => {
			let isSubscribe = true;
			getUsers(isSubscribe).then(r => {});

			return () => {
				isSubscribe = false;
				// setUsers([]);
				// setUsersShow([]);
			}
		},
		[roleName]
		// []
	);

	const deleteAction = async (id) => {
		actions.showLoading();
		const res = await Api.post('users.php',  {
			action: 'delete',
			target: 'user',
			user: user,
			id: id
		});
		actions.hideLoading();
		apiAction(res, (data) => {

			if (data.deleted) {
				let tmpUsers = [];
				users.map((user) => {
					if (user.id !== id)
						tmpUsers.push(user);

					return null;
				});
				setUsersShow(tmpUsers);
			}

		});
	};
	const onDelete = (e, user) => {
		e.preventDefault();
		toastr.confirm(
			'Вы точно хотите удалить пользователя: ' + user.name + '?',
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: () => deleteAction(user.id),

			}
		);
	};
	{/*<NavLink*/}
	{/*	to={"/user/edit/0"}*/}
	{/*	className="btn -green"*/}
	{/*>*/}
	{/*	<i className="fa fa-user-plus"></i> Создать пользователя*/}
	{/*</NavLink>*/}
	let panel = null;
	if (usersShow.length)
		panel = (
			<div className="flx -sb -alc">
				<div className="">
					Всего: {usersShow.length}
				</div>
				{
					in_array(user.role, rolesModerator) ?
						(
							<Button
								type={"save"}
								title={"Создать пользователя"}
								href={"/user/edit/0" + (roleName ? '/' + roleName : '')}
								// href={"/user/edit/0"}
								ico={"user-plus"}
							/>

						): ''
				}
			</div>
		);
	let contentHeaderParent = [{url: '/user', name: 'Пользователи'}];
	let contentHeaderTitle = 'Пользователи';
	if (roleName !== '') {
		if (user.userRoles)
			contentHeaderTitle = user.userRoles[roleName];
			// contentHeaderParent.push({
			// 	url: '',
			// 	name: user.userRoles[roleName]
			// });
	}
	else {
		contentHeaderTitle = 'Все';
		// contentHeaderParent.push({
		// 	url: '',
		// 	name: 'Все'
		// });
	}


	// return (
	// 	<div className="">
	// 	123
	// 	</div>
	// );
	return (
		<>
			<ContentHeader
				title={contentHeaderTitle}
				parent={contentHeaderParent}
			/>
			{panel}

			{
				(usersShow && usersShow.length) ? <UsersList
					users={usersShow}
					user={user}
					onDelete={onDelete}
					roleInit={roleName}
				/> : null
			}
		</>
	);

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);