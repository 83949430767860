import React from 'react';
import {Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import TaskManager from "containers/task_manager/TaskManager";
import TodoEdit from "containers/task_manager/TodoEdit";
const TaskManagerRouter = props => {

	return (
		<Switch>
			<Route
				path="/task_manager"
				exact={true}
				key={"task_manager"}
			>
				<TaskManager {...props} />
			</Route>
			<Route path={["/task_manager/edit/:id"]} exact={true} key={"task_manager-edit"}>
				<TodoEdit {...props} edit={true} />
			</Route>
			<Route path={["/task_manager/show/:id"]} exact={true} key={"task_manager-edit"}>
				<TodoEdit {...props} edit={false} />
			</Route>


			<Route component={Page404} />
		</Switch>
	)

};

export default TaskManagerRouter;
