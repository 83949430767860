import React from 'react';
import {MONTHS} from "utils/config";
import * as strtotime from "strtotime";

const BillsMonths = ({GUI, onClick = null, dates}) => {
    // console.clear();
    // console.dir(GUI.calendarDates);
    // console.dir(typeof dates.start);
    // console.dir(dates);

    // let datesNowFromServer = new Date();
    let dateStart;
    if (typeof dates?.start == 'object')
        dateStart = dates?.start;
    else
        dateStart = strtotime(dates.start) * 1000;




    const dateNow = new Date();
    const dateSelected = new Date(dateStart);

    // console.log(dateNow, dateSelected);

    let yearNow = dateNow.getFullYear();
    let monthNow = dateNow.getMonth();

    let yearSelected = dateSelected.getFullYear();
    let monthSelected = dateSelected.getMonth();


    let monthsMaxLength = 12;
    let months = [];
    for (let i = monthNow; i >=0; i--)
        months.push({
            name: MONTHS[i],
            month: i,
            year:  yearNow
        });

    let i = 11;
    while (months.length < monthsMaxLength) {
        months.push({
            name: MONTHS[i],
            month: i,
            year:  yearNow - 1
        });
        i--;
    }
    // console.dir(months);
    return (
        <ul className={"tales-bills-months _z flx -alc"}>
            {
                months.map((item) => {
                    return(
                        <li
                            key={`${item.year}-${item.month}`}
                            className={(item.year == yearSelected && item.month == monthSelected) ? "active" : null}
                            onClick={() => {

                                let _thisDateStart = new Date(item.year, item.month);

                                onClick({
                                        start: _thisDateStart,
                                        end: _thisDateStart,
                                        year: item.year,
                                        month: item.month + 1,
                                        day: 1
                                    });
                            }}
                        >
                            {item.name}
                            {item.year != yearNow ? ` ${item.year}` : ''}
                        </li>
                    );
                })
            }
            {/*<li*/}
            {/*    className={(yearSelected == 0 && monthSelected == 0) ? "active" : null}*/}
            {/*    onClick={() => {*/}
            {/*        onClick({*/}
            {/*            start: 0,*/}
            {/*            end: 0,*/}
            {/*            year: 0,*/}
            {/*            month:0,*/}
            {/*            day: 0*/}
            {/*        });*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Все*/}
            {/*</li>*/}
        </ul>
    )

};

export default BillsMonths;