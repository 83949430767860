import React, {useState} from 'react';
import {Win, FormItem} from "components/_common";
import {number_format, copyClipboard} from "utils/func";
import {NavLink} from "react-router-dom";

const UnloadCalc = props => {
    const {onClose, user} = props;
    const [data, setData] = useState({
        weight: 0,
        priceKilo: 10,
        floor: 2,
        itog: 0,
        itogLift: 0,
    });
    const calc = (param, value) => {
        let newData = {...data};
        newData[param] = value;

        newData.itog = newData.weight * newData.priceKilo * newData.floor;
        newData.itogLift = newData.weight * newData.priceKilo * 2;

        setData(newData);

    }
    console.log(user.settings.unloadCalcTips);

    let tips = null;
    if (user.settings && user.settings.unloadCalcTips && user.settings.unloadCalcTips != '')
        tips = user.settings.unloadCalcTips.split("\n");


    return (
        <div className="popup-win">
            <Win
                onClose={() => onClose()}
                header={"Расчет разгрузки"}
            >
                <FormItem
                    fieldType={"text"}
                    name={"weight"}
                    label={"Введите вес груза, кг"}
                    defVal={data.weight}
                    changeAction={e => {
                        calc('weight', parseInt(e.target.value.trim()));
                    }}
                />
                <FormItem
                    fieldType={"text"}
                    name={"priceKilo"}
                    label={"Стоимость 1кг разгрузки, руб."}
                    defVal={data.priceKilo}
                    changeAction={e => {
                        calc('priceKilo', parseInt(e.target.value.trim()));
                    }}
                />
                <FormItem
                    fieldType={"text"}
                    name={"floor"}
                    label={"Этаж подъема без лифта"}
                    defVal={data.floor}
                    changeAction={e => {
                        let floor =  parseInt(e.target.value.trim());
                        if (floor < 1)
                            floor = 1;
                        calc('floor', floor);
                    }}
                />

                <div className="mt20 flx -alc -sb">
                    <div>
                        <strong>Итого: </strong> {number_format(data.itog, 0, '.', ' ')} руб.
                    </div>
                    <i className={"fa fa-copy"}
                       title={"Скопировать в буфер"}
                       onClick={() => copyClipboard(`${number_format(data.itog, 0, '.', ' ')} руб.`, true)}
                    />
                </div>
                <div className="mt20 flx -alc -sb">
                    <div>
                        <strong>Итого на лифте: </strong> {number_format(data.itogLift, 0, '.', ' ')} руб.
                    </div>
                    <i className={"fa fa-copy"}
                       title={"Скопировать в буфер"}
                       onClick={() => copyClipboard(`${number_format(data.itogLift, 0, '.', ' ')} руб.`, true)}
                    />
                </div>

                {
                    tips
                        ? <div className="unload-calc-tips mt20">
                            {tips.map((tip, i) => <p key={`unload-calc-tip-${i}`}>{tip}</p>)}

                            {user.role == 'admin' ? <NavLink to={"/settings/general#tab-4"}>редактировать список</NavLink> : null}
                        </div>
                        : null
                }



                {/*http://localhost:3005/settings/general#tab-4*/}
            </Win>
        </div>
    )

};

export default UnloadCalc;
