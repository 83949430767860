import React, {useEffect, useState, useRef} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {setCalendarSelectDateAction, setIsPrint, setSearchAction} from "actions/GUIActions";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Button} from "components/_common";
import {rolesModerator,  MENU_TITLE, FORM_TYPES, FORMS_STATUS} from "utils/config";
import {in_array, spreadByDivisions, user4api} from "utils/func";
import FormsList from "components/forms/FormsList";
import {FormItem} from "components/_common";
import {logger} from "redux-logger/src";


const API_URL = 'forms.php';
const FormsContainer = props => {

	const {user, GUI, actions} = props;

	let {code = ''} = useParams();

	const selectedManagerId = useRef(0);
	const selectedCode = useRef(code);
	const selectedStatus = useRef(-1);
	const selectedDomain = useRef('');
	// console.log(selectedManagerId.current + '/' + selectedCode.current);
	// const [selectedManagerId, setSelectedManagerId] = useState(0);
	const [managers, setManagers] = useState([]);
	const [managers4domains, setManagers4domains] = useState([]);
	const [forms, setForms] = useState([]);
	const [showForms, setShowForms] = useState([]);
	const [divisions, setDivisions] = useState([]);

	// const [popupWin, setPopupWin] = useState(null);


	let apiActionProcess = false;
	const getForms = async (isSubscribe = true, calDates = null, formCode = null, search = '') => {
		// console.log('getForms', isSubscribe, apiActionProcess);
		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'getForms',
				user: user4api(user),
				dates: calDates ? calDates : GUI.calendarDates,
				search: search
			});

			apiAction(res, (response) => {

				setForms(response.forms);
				setManagers(response.managers);
				setManagers4domains(response.managers4domains);
				setDivisions(response.divisions);


				const filteredForms = filterForms(
					response.forms,
					selectedManagerId.current,
					formCode !== null ? formCode : selectedCode.current,
					selectedStatus.current,
					selectedDomain.current
				);
				console.log(filteredForms.length);

				setShowForms(
					filteredForms
					// filterForms(data.forms, selectedManagerId.current, selectedCode.current)
				);


			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
			apiActionProcess = false;
		}
	};

	const onCalendarSelectDate = (dates) => {
		getForms(true, dates).then(null);
	};

	const doSearch = str => {
		getForms(true, [], null, str).then(null);
	};
	// console.log('FormContainer Render', code);
	useEffect(() => {
			let isSubscribe = true;
			// console.clear();
			// console.log('FormContainer useEffect', code);

			getForms(isSubscribe, null, code).then(null);
			actions.setCalendarSelectDateAction(onCalendarSelectDate);
			actions.setSearchAction(doSearch);


			if (GUI.socket)
				GUI.socket.on('serverHasNewData', res => {
					// console.log('FORMS LIST serverHasNewData', res);
					if (res.newFormsCount !== undefined)
						getForms(isSubscribe).then(null);
				});


			return () => {
				isSubscribe = false;
				//отключаем функцию обработчик на календаре
				actions.setCalendarSelectDateAction(null);
				// setPopupWin(null);
			}
		},
		[
			// GUI.socket,
			code
		]
	);

	const printAction = () => {
		actions.setIsPrint(true);
		window.print();
	};
	const setField = async (id, field, value, setFormField = true) => {
		// console.clear();
		// console.log(`${id}, ${field}, ${value}, ${setFormField}`);
		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('forms.php', {
				action: 'setField',
				user: user,
				id: id,
				field: field,
				value: value
			});

			apiAction(res, (data) => {
				if (setFormField) {
					let _forms = [...showForms];

					_forms.map((form, key) => {
						if (form.id === id) {
							// _forms[key][field] = data.value;

							// if (data.status != -1)
							// 	_forms[key]['status'] = data.status;

							_forms[key][field] = value;
						}
						return null;
					});
					// console.log(_forms.length);

					setShowForms(_forms);
				}

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
				// setPopupWin(false);
			});


		}
	};

	const filterForms = (formsData, managerId, code, status, selectedDomain) => {
		// console.clear();
		// console.dir(formsData);
		console.log(`filterForms managerId=${managerId} code=${code} status=${status}  selectedDomain=${selectedDomain}`);
		if (!formsData.length)
			return [];

		managerId = parseInt(managerId);
		status = parseInt(status);


		let _forms = [];
		formsData.map(form => {

			let isOk = true;
			let falseIn = [];
			if (managerId !== 0 && form.uid !== managerId) {
				falseIn.push('manager');
				isOk = false;
			}
			if (code !== '' && form.code !== code) {
				falseIn.push('code');
				isOk = false;
			}
			if (status >= 0 && form.status !== status) {
				falseIn.push('status');
				isOk = false;
			}
			if (selectedDomain !== '' && form.site !== selectedDomain) {
				falseIn.push('domain');
				isOk = false;
			}

			// console.log(form.uid, isOk, falseIn);
			if (isOk)
				_forms.push(form);
			return null;
		});

		console.log(_forms.length);

		return _forms;
	};


	let codeOptions = {
		"": {
			value: "все формы"
		}
	};
	for (const ft in FORM_TYPES) {
		codeOptions[ft] = {
			value: FORM_TYPES[ft]
		};
	}


	let filterStatusOptionsMap = new Map();
	filterStatusOptionsMap.set("", {
		value: "все статусы"
	});
	FORMS_STATUS.forEach((title, s) => {
		filterStatusOptionsMap.set(s, {
			value: title
		});
	});
	let formsByDivision = null;
	if (showForms && showForms.length && divisions) {
		formsByDivision = spreadByDivisions(divisions, showForms);
	}

	let allDomains = [];
	divisions.map(division => {
		if (division.domains.length > 0) {
			if (in_array(user.role, rolesModerator))
				allDomains = allDomains.concat(division.domains);
			else if (user.divId == division.id)
				allDomains = division.domains;
		}
		return null;
	});

	allDomains = [...new Set(allDomains)];
	allDomains.sort();
	// console.log(allDomains);
	// console.dir(formsByDivision);

	const panelDoFilter = () => {
		setShowForms(
			filterForms(forms,
				selectedManagerId.current,
				selectedCode.current,
				selectedStatus.current,
				selectedDomain.current
			)
		);
	}

	const panel = (
		<div className="panel-top forms mt20m no-print">
			<div className="-sum-info mt20 flx ">
				{
					formsByDivision != null ? divisions.map(division => {
						if (!formsByDivision[division.id])
							return null;

						return (
							<div key={"osi-" + division.id}>
								<p>{division.name}</p>
								Кол-во: {formsByDivision[division.id].items.length} шт
							</div>
						);
					}) : null
				}
			</div>
			<div className="flx -sb">


				<div className="mt20 row -filters">
					{(allDomains && allDomains.length > 1) ? (
						<div className="col">
							<select
								name={"selectedDomain"}
								defaultValue={selectedDomain.current}
								onChange={e => {

									selectedDomain.current = e.target.value;
									panelDoFilter();
									// setShowForms(
									// 	filterForms(forms,
									// 		selectedManagerId.current,
									// 		selectedCode.current,
									// 		selectedStatus.current,
									// 		selectedDomain.current
									// 	)
									// );

								}}

							>
								<option key={"site-0"} value={""}>все сайты</option>
								{
									allDomains.map(domain => {
										return <option key={"site-" + domain} value={domain}>{domain}</option>
									})
								}
							</select>
						</div>
					) : null}

					{(managers && managers.length) ? (
						<div className="col">
							<select
								name={"sortManagerId"}
								defaultValue={selectedManagerId}
								onChange={e => {

									selectedManagerId.current = e.target.value;
									panelDoFilter();
									// setShowForms(
									// 	filterForms(forms, selectedManagerId.current, selectedCode.current, selectedStatus.current)
									// );

								}}
							>
								<option key={"manager-0"} value={0}>все менеджеры</option>
								{
									managers.map(man => {
										return <option key={"manager-" + man.id} value={man.id}>{man.name}</option>
									})
								}
							</select>
						</div>
					) : null}


					<div className="col">
						<FormItem
							edit={true}
							fieldType={"select"}
							name={"code"}
							defVal={code}
							options={codeOptions}
							changeAction={e => {
								selectedCode.current = e.target.value;
								panelDoFilter();
								// setShowForms(
								// 	filterForms(forms, selectedManagerId.current, selectedCode.current, selectedStatus.current)
								// );
							}}
						/>
					</div>
					<div className="col">
						<FormItem
							edit={true}
							fieldType={"select"}
							name={"filterStatus"}
							defVal={selectedStatus.current}
							optionsMap={filterStatusOptionsMap}
							changeAction={e => {
								selectedStatus.current = e.target.value;
								panelDoFilter();
								// setShowForms(
								// 	filterForms(forms, selectedManagerId.current, selectedCode.current, selectedStatus.current)
								// );
							}}
						/>
					</div>
				</div>
				<div className="mt20 -btns">
					<Button
						title={"Распечатать"}
						ico={"print"}
						onClick={printAction}
						// type={""}
						cls={"-lgrey"}
						// href={user.settings.PRINT_URL + '?forms&to=print' + () }
						// hrefBlank={true}
					/>
				</div>
			</div>
		</div>
	);

	let isEditable = in_array(user.role, [...rolesModerator, 'manager']);


	const managerSelect = (formId, formSite, managerId, whenSelectAction = null) => {

		if (!managers4domains[formSite])
			return <span className={"clr-red"}>Нет менеджеров для домена "{formSite}". Укажите домен в настройках офиса.</span>;

		return (
			<select
				name={"manId"}
				key={"forms-man-select-" + formId}
				defaultValue={managerId}
				onChange={e => {
					const selectedManagerId = e.target.value;

					let _forms = [...showForms];

					_forms.map((form, key) => {

						if (form.id === formId) {
							_forms[key].uid = selectedManagerId;

							managers4domains[formSite].map(man => {
								if (man.id === selectedManagerId)
									_forms[key].userName = man.name;

								return null;
							});

							if (!parseInt(selectedManagerId))
								_forms[key].userName = 'не выбран';

							_forms[key].mdate = Date.now() / 1000;
						}

						return null;
					});

					setShowForms(_forms);
					// whenSelectAction(false);
					setField(formId, 'uid', selectedManagerId, false).then(null);
				}}
				// onFocus={e => {
				// 	whenSelectAction(true);
				// }}
				// onBlur={e => {
				// 	whenSelectAction(false);
				// }}
			>
				<option key={"manager-0"} value={0}>не выбран</option>
				{
					managers4domains[formSite].map(man => {
						return <option key={"manager-" + man.id} value={man.id}>{man.name}</option>
					})
				}
			</select>
		);
	};
	const statusSelect = (formId, status, whenSelectAction = null) => {

		let statusOptions = [];
		FORMS_STATUS.forEach((title, s) => {
			statusOptions.push(
				<option
					key={"status-" + s}
					value={s}>
					{title}
				</option>
			)
		});

		return (
			<select
				name={"status"}
				key={"forms-status-select-" + formId}
				defaultValue={status}
				onChange={async(e) => {
					await setField(formId, 'status', e.target.value, true);//.then(null);
				}}
				// onFocus={e => {
				// 	whenSelectAction(true);
				// }}
				// onBlur={e => {
				// 	whenSelectAction(false);
				// }}
			>
				{statusOptions}
			</select>
		);
	};
	// console.log(FORMS_STATUS);

	let chParent = [];
	let chTitle = MENU_TITLE.forms.many;
	if (code !== '' && FORM_TYPES[code]) {
		chTitle = FORM_TYPES[code];
		chParent = [
			{
				url: '/forms',
				name: MENU_TITLE.forms.many
			},
			// {
			// 	url: '/forms/' + code,
			// 	name: FORM_TYPES[code]
			// }
		]
	}


	// if (user && !in_array(user.role, rolesAdmin) && parseInt(user.divId) !== 4)
	// 	return <div className="">
	// 		{MESSAGES.accessDenied}
	// 	</div>;

	const columnsTitle = {
		num: '№',
		date: 'Дата',
		manager: 'Менеджер',
		status: 'Статус',
		site: 'Сайт',
		// txt: 'Данные',
		type: 'Инфо',
		nakl: '№ накл.',
		prim: 'Примечание',
		name: 'Контактное лицо',
		tel: 'Телефон',
		itogSum: 'Итоговая сумма',
	};

	return (
		<>
			<ContentHeader
				title={chTitle}
				showDates={true}
				datesAction={getForms}
				parent={chParent}
			/>
			{panel}
			{
				(showForms && showForms.length) ? (
					<>
						<div className="forms-total">
							Всего: {showForms.length}
						</div>
						<div className={"forms-tbl tbl dark hdr-cntr -bordered"}>
							<div className="thead">
								<div className="tr">
									<div className="td col-num">
										{columnsTitle.num}
									</div>
									<div className="td col-name">
										{columnsTitle.name}
									</div>
									<div className="td col-tel">
										{columnsTitle.tel}
									</div>
									<div className="td col-site">
										{columnsTitle.site}
									</div>
									<div className="td col-date">
										{columnsTitle.date}
									</div>
									<div className="td col-type">
										{columnsTitle.type}
									</div>
									<div className="td col-manager">
										{columnsTitle.manager}
									</div>
									<div className="td col-status">
										{columnsTitle.status}
									</div>
									<div className="td col-nakl">
										{columnsTitle.nakl}
									</div>
									<div className="td col-prim">
										{columnsTitle.prim}
									</div>
									<div className={"td col-itog-sum"}>
										{columnsTitle.itogSum}
									</div>
								</div>
							</div>
							<div className="tbody">
								{divisions.map(division => {
									if (formsByDivision[division.id])
										return <FormsList
											division={division}
											formsInfo={formsByDivision[division.id]}

											key={"forms-list-" + division.id}
											user={user}
											columnsTitle={columnsTitle}
											isEditable={isEditable}

											managerSelect={in_array(user.role, rolesModerator) ? managerSelect : null}
											selectedManagerId={selectedManagerId.current}

											statusSelect={statusSelect}
											setField={setField}
											showForms={showForms}
										/>
								})}

								{
									formsByDivision[0].items.length
										? <FormsList
											division={{id: 0, name: 'Не определено'}}
											formsInfo={formsByDivision[0]}

											key={"forms-list-0"}
											user={user}
											columnsTitle={columnsTitle}
											isEditable={isEditable}

											managerSelect={in_array(user.role, rolesModerator) ? managerSelect : null}
											selectedManagerId={selectedManagerId.current}

											statusSelect={statusSelect}
											setField={setField}
											showForms={formsByDivision[0].items}
										/>
										: null
								}

								{/*{showForms.map(form => {*/}
								{/*	return <FormsListItem*/}
								{/*		user={user}*/}
								{/*		key={"forms-list-item-" + form.id}*/}
								{/*		form={form}*/}
								{/*		// getPopupEditor={getPopupEditor}*/}
								{/*		isEditable={isEditable}*/}
								{/*		managerSelect={in_array(user.role, rolesModerator) ? managerSelect : null}*/}
								{/*		statusSelect={statusSelect}*/}
								{/*		formCode={code}*/}
								{/*		columnsTitle={columnsTitle}*/}
								{/*		setField={setField}*/}
								{/*	/>*/}
								{/*})}*/}
							</div>
						</div>
					</>
				) : null
			}
			{/*{popupWin}*/}
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setCalendarSelectDateAction, setIsPrint, setSearchAction
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(FormsContainer);
