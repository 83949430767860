import React, {useState} from 'react';
import date from "php-date";
import cn from "classnames";
import {FORM_TYPES, FORMS_STATUS, FORMS_STATUS_STYLE, rolesModerator} from "utils/config";
import {NavLink} from "react-router-dom";
import {in_array, format_phone, diffDays} from "utils/func";


/**
 * Может ли пользователь просматривать форму
 * @param user
 * @param order
 */
export const canSeeForm = (user, form) => {

    let canSee = in_array(user.role, rolesModerator);
    if (!parseInt(form.uid))
        canSee = true;
    else if (form.uid == user.id)
        canSee = true;


    return canSee;
};

const FormsListItem = props => {

    const {
        user,
        form,
        isEditable,
        managerSelect, statusSelect,
        formCode = null,
        columnsTitle,
        setField,
        formState
    } = props;

    // console.log(`${formState.id} ${formState.status}`);

    const [isEditNaklText, setEditNaklText] = useState(false);
    const [isEditPrimText, setEditPrimText] = useState(false);
    const [isEditItogSum, setEditItogSum] = useState(false);//@since 12/11/2024

    form.uid = parseInt(form.uid);
    form.mdate = parseInt(form.mdate);
    form.openUserId = parseInt(form.openUserId);


    let isFieldsEditable = isEditable;
    if (!isEditable && parseInt(user.id) === form.uid)
        isFieldsEditable = true;

    // if (form.id == 2842) {
    // 	console.log(isFieldsEditable, form.openUserId);
    // 	// console.log(form.id, user.id == form.uid, user.id, form.uid);
    // }

    if (!form.openUserId)
        isFieldsEditable = false;

    let info = '';
    switch (form.code) {
        case "missing_calls":
            info = form.txt;
            break;
        default:
            // info = form.name;
            break;
    }

    let name = '-';
    if (form.name !== undefined && form.name != '')
        name = form.name;

    let phone = '-';
    if (form.phone !== undefined && form.phone != '')
        phone = format_phone(form.phone);

    let formId = parseInt(form.fid) != 0 ? form.fid : form.id;



    return (
        <div className={cn("tr", {userOwn: user.id === form.uid})}>
            <div className="td col-num">
                <div className="td-title">
                    {columnsTitle.num}
                </div>
                {
                    canSeeForm(user, form)
                        ? <NavLink
                            to={`/forms/show/${form.id}`}>
                            {formId}
                        </NavLink>
                        : formId
                }
                {
                    form.openUserId === 0 ?
                        <div className={"mt15"} title={"Не просмотрено"}><i className={"fa fa-eye-slash"}></i>
                        </div> : null
                }
            </div>
            <div className="td col-name">
                <div className="td-title">
                    {columnsTitle.name}
                </div>
                {name}
            </div>
            <div className="td col-tel">
                <div className="td-title">
                    {columnsTitle.tel}
                </div>
                {phone}
            </div>
            <div className="td col-site">
                <div className="td-title">
                    {columnsTitle.site}
                </div>
                {form.site}
            </div>
            <div className="td col-date">
                <div className="td-title">
                    {columnsTitle.date}
                </div>
                {date('d.m.Y H:i', new Date(form.created * 1000))}
            </div>
            <div className="td col-type">
                <div className="td-title">
                    {columnsTitle.type}
                </div>
                <div className="-tit">
                    {
                        FORM_TYPES[form.code]
                            ? FORM_TYPES[form.code]
                            : 'Не задан тип формы ' + form.code
                    }
                </div>

                <div className={"mt10"}>
                    {info}
                </div>
            </div>
            <div className={cn("td col-manager", /*{editable: isEditable}*/)}
                // onDoubleClick={e => setEditManager(true)}
                 title={isEditable ? 'Двойнок клик для редактирования' : ''}
            >
                <div className="td-title">
                    {columnsTitle.manager}
                </div>
                <div>
                    {/*{*/}
                    {/*	!isFieldsEditable ? 'не открыта' : (*/}
                    {/*		isEditable*/}
                    {/*			? managerSelect(form.id, form.site, form.uid)*/}
                    {/*			: (*/}
                    {/*				(form.uid && form.userName)*/}
                    {/*					? form.userName*/}
                    {/*					+ (*/}
                    {/*						form.uid == user.id ? ' (Вы)' : ''*/}
                    {/*					)*/}
                    {/*					: 'не выбран'*/}
                    {/*			)*/}
                    {/*		)*/}
                    {/*}*/}
                    {
                        (isFieldsEditable && managerSelect)
                            ? managerSelect(form.id, form.site, form.uid)
                            : (
                                (form.uid && form.userName)
                                    ? form.userName
                                    + (
                                        form.uid == user.id ? ' (Вы)' : ''
                                    )
                                    : 'не выбран'
                            )

                    }
                    {form.mdate > 0 ? <div className="-date" title="Дата назначения менеджера">
                        {date('d.m.Y H:i', new Date(form.mdate * 1000))}
                    </div> : null}
                </div>
            </div>
            <div className={cn("td col-status", /*{editable: isEditable}*/)}
                 style={FORMS_STATUS_STYLE.get(form.status) ?? null}
                // onDoubleClick={e => setEditStatus(true)}
            >
                <div className="td-title">
                    {columnsTitle.status}
                </div>
                {
                    (
                        isFieldsEditable
                            ? statusSelect(form.id, form.status)
                            : FORMS_STATUS.get(form.status)
                    )
                }
                {
                    (form.status === 5 && diffDays(Date.now(), form.created * 1000) >= 4)
                        ? <div className={"forms-call"}>
                            Обзвонить
                        </div>
                        : null
                }
            </div>

            {/*<div className="td col-txt forms-tbl-data">*/}
            {/*	<div className="td-title">*/}
            {/*		{columnsTitle.txt}*/}
            {/*	</div>*/}
            {/*	{formData}*/}
            {/*</div>*/}

            <div className={cn("td col-nakl", {editText: isFieldsEditable})}
                // onDoubleClick={e => isEditable && getPopupEditor('nakl', form.id, form.nakl)}
            >
                <div className="td-title">
                    {columnsTitle.nakl}
                </div>
                <div className={cn("editor", {notEdit: !isEditNaklText})}
                    // contentEditable={(isFieldsEditable && isEditNaklText)}
                     contentEditable={isFieldsEditable}
                     suppressContentEditableWarning={true}
                     onBlur={e => {
                         // setEditNaklText(false);
                         setField(form.id, 'nakl', e.currentTarget.innerHTML);
                     }}
                    // onClick={e => setEditNaklText(true)}
                >
                    {form.nakl !== '' ? form.nakl : ''}
                </div>
            </div>
            <div className={cn("td col-prim", {editText: isFieldsEditable})}
                // onDoubleClick={e => isEditable && getPopupEditor('prim', form.id, form.prim)}
            >
                <div className="td-title">
                    {columnsTitle.manager}
                </div>
                <div className={cn("editor", {notEdit: !isEditPrimText})}
                    // contentEditable={(isFieldsEditable && isEditPrimText)}
                     contentEditable={isFieldsEditable}
                     suppressContentEditableWarning={true}
                     onBlur={e => {
                         setEditPrimText(false);
                         setField(form.id, 'prim', e.currentTarget.innerHTML)
                     }}
                    // onClick={e => setEditPrimText(true)}
                >

                    {form.prim}
                </div>
            </div>
            <div className={cn("td col-itog-sum", {editText: isFieldsEditable})}
            >
                <div className="td-title">
                    {columnsTitle.manager}
                </div>
                <div className={cn("editor", {notEdit: !isEditItogSum})}

                     contentEditable={isFieldsEditable}
                     suppressContentEditableWarning={true}
                     onBlur={e => {
                         // setEditPrimText(false);
                         if (form.itogSum != e.currentTarget.innerHTML)
                            setField(form.id, 'itogSum', e.currentTarget.innerHTML)
                     }}
                    // onClick={e => setEditPrimText(true)}
                >

                    {form.itogSum}
                </div>
            </div>
        </div>
    );

};

export default FormsListItem;
