import React, {createRef, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import date from "php-date";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Win, Button} from "components/_common";
import {MENU_TITLE, FORM_TYPES, FORMS_STATUS, rolesModerator} from "utils/config";
import {in_array, format_phone} from "utils/func";
import {FormItem} from "components/_common";
import {canSeeForm} from "components/forms/FormsListItem";


const API_URL = 'forms.php';

// const statusSelect = (formId, status, callback) => {
// 	return (
// 		<select
// 			name={"status"}
// 			key={"forms-status-select-" + formId}
// 			defaultValue={status}
// 			onChange={e => {
// 				callback(e.target.value);
// 			}}
// 		>
// 			{
// 				Object.keys(FORMS_STATUS).map(s => {
// 					return <option key={"status-" + s} value={s}>{FORMS_STATUS[s]}</option>
// 				})
// 			}
// 		</select>
// 	);
// };

const FormsShowContainer = props => {

	const {user, actions, GUI} = props;

	let { id = 0 } = useParams();
	const [data, setData] = useState(null);

	const formRef = createRef();


	let apiActionProcess = false;
	const getData = async (isSubscribe = true, calDates = null) => {
		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'getForm',
				user: user,
				id: id
			});

			apiAction(res, (data) => {
				setData(data.data);

				if (GUI.socket && !parseInt(data.data.openUserId))
					GUI.socket.emit(
						'setOpenByUser',
						{
							userId: user.id,
							what: 'form'
						}
					);

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	};

	useEffect(() => {
			let isSubscribe = true;
			getData(isSubscribe).then(null);

			return () => {
				isSubscribe = false;
			}
		},
		[id]
	);
	const setField = async (id, field, value, setFormField = true) => {

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('forms.php', {
				action: 'setField',
				user: user,
				id: id,
				field: field,
				value: value
			});

			apiAction(res, (response) => {
				console.log(response);
				if (setFormField) {
					setData({
						...data,
						status: response.status
					});
				}
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});


		}
	};

	const onSave = (formId, isClose = false) => {
		const form = formRef.current;

		// console.log(form.status.value, form.nakl.value, form.prim.value);
		//от такая ебанина, зато быстро
		setField(formId, 'status', form.status.value, false).then(
			() => {
				setField(formId, 'nakl', form.nakl.value, false).then(() => {
					setField(formId, 'prim', form.prim.value, false).then(() => {
						if (isClose)
							props.history.push('/forms');
					});
				});
			}
		);
	};

	if (!data || !data.id)
		return null;


	let isEditable = in_array(user.role, rolesModerator);
	if (!isEditable && parseInt(user.id) === data.uid)
		isEditable = true;

	let isMailbox = data.code == 'from_mailbox';

	let winHeader = null;
	if (data.userName)
		winHeader = 'Менеджер: '
			+ data.userName
			+ ' назначен '
			+ date('d.m.Y H:i', new Date(data.mdate * 1000));


	const content = [];

	if (!canSeeForm(user, data))
		content.push(<div key={"role-err"}>
			Нет прав для просмотра
		</div>);
	else if (data) {
		content.push(
			// <FormItem
			// 	key={"ftype"}
			// 	edit={false}
			// 	name={"ftype"}
			// 	defVal={FORM_TYPES[data.code] ? FORM_TYPES[data.code] : 'Не задан тип формы ' + data.code}
			// />
			<h2 key={"ftype"}>
				{FORM_TYPES[data.code] ? FORM_TYPES[data.code] : 'Не задан тип формы ' + data.code}
			</h2>
		);
		content.push(
			<div className="mt20" key={"user-info"}>
				{/*<div className="col-3">*/}
				{/*	*/}
				{/*</div>*/}
				{/*<div className="col-3">*/}
				{/*	*/}
				{/*</div>*/}
				{/*<div className="col-3">*/}
				{/*	*/}
				{/*</div>*/}
				{
					(data.name && data.name !== '')
						? <FormItem
							key={"name"}
							edit={false}
							name={"name"}
							defVal={data.name}
							ico={"user"}
						/>
						: null
				}

				{
					(data.phone && data.phone !== '')
						? <FormItem
							edit={false}
							name={"phone"}
							defVal={format_phone(data.phone)}
							ico={"phone"}
						/>
						: null
				}

				{
					(data.email && data.email !== '')
						? <FormItem
							edit={false}
							name={"email"}
							defVal={data.email}
							ico={"envelope"}
						/>
						: null
				}



			</div>

		);
		if (data.txt && data.txt !== '') {

			console.log(data.txt);
			let txt = data.txt;
			// txt = txt.replaceAll('<br>', "\n");
			// txt = txt.replaceAll('<br />', "\n");
			txt = txt.replaceAll("\n", "<br>");
			let fieldType;
			// if (isMailbox) {
				fieldType = <div
					className="from-mailbox"
					dangerouslySetInnerHTML={{__html: txt}} />;
			// }
			// else
			// 	fieldType = "txt";

				content.push(
					<FormItem
						key={"txt"}
						edit={false}
						name={"txt"}
						fieldType={fieldType}
						label={"Текст"}
						// defVal={data.txt.replaceAll('<br>', "\n")}
						cls={"textarea-big"}
					/>
				);
		}
		if (data.url !== '' && !isMailbox)
			content.push(
				<FormItem
					key={"url"}
					edit={false}
					name={""}
					// fieldType={}
					label={"URL страницы с которой отправлена форма"}
					defVal={data.url}
				/>
			);

		//setField(formId, 'status', e.target.value, true).then(null);
		// console.log(isEditable, FORMS_STATUS);
		if (isEditable) {
			const statusOptions = {};
			FORMS_STATUS.forEach((title, s) => {
				statusOptions[s] = {value: title}
			});

			content.push(
				<FormItem
					key={"status"}
					fieldType={"select"}
					edit={true}
					name={"status"}
					label={"Статус"}
					options={statusOptions}
					// optionsComponent={statusSelect(data.id, data.status, (val) => {
					// 	setField(data.id, 'status', val, true).then(null);
					// })}
					defVal={data.status ? data.status : 0}
				/>
			);
		}
		else
			content.push(
				<FormItem
					key={"status"}
					edit={false}
					name={"status"}
					label={"Статус"}
					defVal={FORMS_STATUS[data.status] ? FORMS_STATUS[data.status] : data.status}
				/>
			);
		content.push(
			<FormItem
				key={"nakl"}
				edit={true}
				name={"nakl"}
				label={"№ накладной"}
				defVal={data.nakl}
				// blurAction={(e, val) => {
				// 	console.log(val);
				// 	setField(data.id, 'nakl', val);
				// }}
			/>
		);
		content.push(
			<FormItem
				key={"prim"}
				fieldType={"txt"}
				edit={true}
				name={"prim"}
				label={"Примечание"}
				defVal={data.prim}
				// blurAction={(e, val) => {
				//
				// 	setField(data.id, 'prim', val);
				// }}
			/>
		);

		if (data.openUserName)
			content.push(
				<div key={"openUserName"}>
					Открыл: {data.openUserName}
				</div>
			);
	}

	const winFooter = (
		<div className={"win-ftr-btns flx -sb"}>
			<div>
				<Button
					type="apply"
					onClick={e => onSave(data.id,false)}
				/>
				<Button
					type="save"
					onClick={e => onSave(data.id,true)}
				/>
				<Button
					type={"close"}
					onClick={e => props.history.push('/forms')}
				/>
			</div>

		</div>
	);
	return (
		<>
			<ContentHeader
				title={MENU_TITLE.forms.one
				+ " №" + data.fid
				+ " от " + date("d.m.Y", new Date(data.fdate * 1000))
				+ " (" + data.site + ")"}
				showDates={false}
				parent={[{
					url: '/forms',
					name: MENU_TITLE.forms.many
				}]}
			/>
			<Win
				header={winHeader}
				onClose={e => props.history.push('/forms')}
				footer={winFooter}
			>
				<form ref={formRef}>
				{content}
				</form>
			</Win>
		</>
	)

};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, /*setCalendarSelectDateAction, setSearchAction*/
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(FormsShowContainer);
