import React, {useEffect, useState} from 'react';
import cn from "classnames";
import {SHOW_SCROLLTOP_OFFSET} from "utils/config";
import {scrollTo} from "utils/func";

const ScrollTopButton = props => {

	const [active, setActive] = useState({
		toTop: window.pageYOffset >= SHOW_SCROLLTOP_OFFSET,
		toBottom: true
	});
	const onScroll = (e) => {
		// let scrollTop = e.srcElement.body.scrollTop;
		// console.log('onScroll');
		// console.log(e);
		let html = document.documentElement;
		// console.log(window.pageYOffset, document.body.scrollHeight, html.clientHeight);
		// console.log(window.pageYOffset + html.clientHeight);

		setActive({
			...active,
			toTop: window.pageYOffset >= SHOW_SCROLLTOP_OFFSET,
			toBottom: (window.pageYOffset + html.clientHeight) < document.body.scrollHeight
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		}
	}, []);



	return (
		<div
			className={cn("scroll-top-btn", {active: true})}

		>
			<i
				className={cn("fa fa-chevron-up", {active: active.toTop})}
				onClick={() => scrollTo(0, true)}
			/>
			<i
				className={cn("fa fa-chevron-down", {active: active.toBottom})}
				onClick={() => {scrollTo(document.body.scrollHeight, true)}}
			/>
		</div>
	)

};

export default ScrollTopButton;
