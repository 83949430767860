import React, {useState, useEffect, createRef} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Win, Button} from "components/_common";
import {setCalendarSelectDateAction, setSearchAction} from "actions/GUIActions";
import {TasksEdit, CompanySelect, TasksTabs} from "components/tasks";
import {toastr} from "react-redux-toastr";
import {in_array, getFileSize, isManagerModerator, isManager, diffDays} from "utils/func";
import {canCreateTask, isEditableTask} from "components/tasks/utils";
import {deleteTask} from "./TasksListContainer";
import {rolesModerator} from "utils/config";
import {BUTTON_TYPE} from "components/_common/Button";
import * as strtotime from "strtotime";
import $ from 'jquery';

export const TasksFields = {
    phoneMobile: "Моб. телефон",
    phones: "Доп. телефоны"
};

const TasksEditContainer = props => {

    const {
        //GUI,
        user,
        actions
    } = props;
    let {params} = useParams();

    let [id, taskType = '', copyDate = null, paramTodo = ''] = params.split('-');
    id = parseInt(id);
    let isNewTask = id === 0;
    let isCopy = taskType === 'copy';
    let fromOrder = taskType === 'order';

    const userIsManager = isManager(user);


    const formRef = createRef();
    const attachFileRef = createRef();


    const [tasksData, setTasksData] = useState(null);
    const [filesList, setFilesList] = useState([]);
    const [popupWin, setPopupWin] = useState(null);

    //для записи истории изменения полей
    // const [changeLog, setChangeLog] = useState({});//не нужно совсем, так как получается и открывается в отд. компоненте
    const [changeFieldsData, setChangeFieldsData] = useState({});
    const [changeFieldsToastTimeout, setChangeFieldsToastTimeout] = useState(0);
    const changeFieldsTxtRef = createRef();

    let apiActionProcess = false;
    const getTask = async (isSubscribe = true, id) => {

        if (!isSubscribe)
            return;

        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;


            const res = await Api.post('tasks.php', {
                action: 'getTask',
                user: user,
                id: id,
                todoOnly: paramTodo,
                fromOrder: fromOrder
            });

            apiAction(res, (data) => {

                if (isCopy) {
                    data.task.driverId = 0;
                    data.task.installers = [];
                }

                setTasksData(data);

                if (data.task.filesList)
                    setFilesList(data.task.filesList);

                if (isCopy)
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });


            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
                // selectCompanyDialog('client');

            });


        }
    };
    useEffect(() => {
            let isSubscribe = true;

            let [id, taskType = '', copyDate = null, paramTodo = ''] = params.split('-');
            isCopy = taskType === 'copy';
            fromOrder = taskType === 'order';

            getTask(isSubscribe, id).then(null);

            actions.setCalendarSelectDateAction(() => {
                props.history.push('/tasks')
            });

            return () => {
                isSubscribe = false;
                //отключаем функцию обработчик на календаре
                actions.setCalendarSelectDateAction(null);
                setTasksData(null);
                setFilesList([]);
                // actions.setSearchAction(null);
            }
        },
        // []
        [params]
    );
    // console.log(paramTodo);
    // console.log(copy);

    let onCloseUrl = '/tasks';
    // if (paramTodo != '' && copy == '')
    //  	onCloseUrl += '/todo/' + paramTodo;
    if (tasksData && tasksData.task && tasksData && tasksData.task.todo === 'zamer')
        onCloseUrl += '/todo/zamer';


    const saveTask = async (isReturn = false) => {
        const form = formRef.current;
        const attachFile = attachFileRef.current;

        let todo = form.todo.value;

        console.clear();
        // console.dir(form);
        // console.dir(Array.from(form.elements));


        if (todo === '') {
            toastr.error('Ошибка заполнения', 'Укажите "Что делаем"');
            return;
        }


        const {requireFields = []} = tasksData.todoList[todo];
        let err = [];

        const formData = new FormData();

        let apiUser = {...user};
        delete apiUser['settings'];
        delete apiUser['userRoles'];
        delete apiUser['yearMin'];


        formData.append('action', 'saveTask');
        formData.append('user', JSON.stringify(apiUser));

        let data = {
            id: (isCopy || fromOrder) ? 0 : id,
            userId: user.id,
            todo: todo,
            tkInfo: {},
            changeFieldsData: changeFieldsData,
            clientId: (tasksData.task && tasksData.task.clientId) ? tasksData.task.clientId : 0
        };


        const fillData = (name, title, isReq = true) => {
            if (in_array(name, requireFields) && (!form[name] || form[name].value == ''))
                err.push(title);
            if (form[name])
                data[name] = form[name].value;

        };


        fillData('ddate', 'Дата исполнения');
        fillData('actualDays', 'Действительна дней');


        let dateNow = new Date();
        let ddateTime = strtotime(data.ddate) * 1000;
        let dateNowTime = strtotime(`${dateNow.getFullYear()}-${dateNow.getMonth() + 1}-${dateNow.getDate()}`) * 1000;

        if (userIsManager && dateNow.getHours() >= 17 && diffDays(ddateTime, dateNowTime) === 1) {
            toastr.error('Ошибка заполнения', 'Невозможно создать заявку на завтра после 17:00');
            return;
        }


        formData.append(
            'createClient',
            form.createClient ? form.createClient.checked : false
        );



        console.log(requireFields);

        const companyInfoFields = ['company', 'name', 'address', 'nakl', 'cmnts', 'phoneMobile', 'phones', 'market_num'];
        const moreCompany = {};
        Array.from(form.elements).map((formItem) => {
            companyInfoFields.map(cif => {
                if (formItem.name.indexOf(cif) === 0) {
                    // console.log(formItem.name, formItem.value);
                    // console.log(cif, formItem.name.indexOf(cif));
                    let fieldTitle = $(formItem).closest('.form-item').find('label').text().replace('*', '');

                    if (formItem.name === cif) {
                        fillData(formItem.name, fieldTitle);
                    }
                    //доп поля
                    else {
                        let fieldPostfix = parseInt(formItem.name.replace(cif, ''));
                        console.log(fieldPostfix);
                        if (!moreCompany.hasOwnProperty(fieldPostfix))
                            moreCompany[fieldPostfix] = {};

                        if (in_array(cif, requireFields) && formItem.value == '')
                            err.push(`${fieldTitle} (в доп. компаниях)`);
                        else
                            moreCompany[fieldPostfix][ cif ] = formItem.value;
                    }

                }
            });
        });

        console.dir(moreCompany);

        formData.append('moreCompany', JSON.stringify(moreCompany));


        let deliveryInfo = {};
        if (todo === 'delivery') {

            deliveryInfo['type'] = form.deliveryType.value;

            if (form.deliveryTime)
                deliveryInfo['time'] = form.deliveryTime.value;

            if (form.tkName) {
                if (in_array('tkName', requireFields) && form.tkName.value.trim() == '')
                    err.push('Транспортная компания');

                data.tkInfo['tkName'] = form.tkName.value.trim();
            }

            if (form.strahovka && form.strahovka.checked)
                data.tkInfo['strahovka'] = form.strahovka.value;
            if (form.obreshetka && form.obreshetka.checked)
                data.tkInfo['obreshetka'] = form.obreshetka.value;
            if (form.dostavka2door && form.dostavka2door.checked)
                data.tkInfo['dostavka2door'] = form.dostavka2door.value;

        }
        // console.error(err);
        // console.dir(moreCompany);
        // return;

        if (form.installers_max && parseInt(form.installers_max.value))
            deliveryInfo['installers_max'] = parseInt(form.installers_max.value);


        if (form.mkadKm)
            fillData('mkadKm', 'Расстояние от МКАД');

        fillData('driverId', 'Водитель', false);

        if (form.payOnPlace && form.payOnPlace.checked) {
            fillData('payOnPlace', 'Оплата на месте');
            fillData('payOnPlacePrice', 'Оплата на месте: Сумма к оплате');

            deliveryInfo['payOnPlacePrice'] = parseFloat(form.payOnPlacePrice.value);
        }
        if (form.razgr && form.razgr.checked) {
            fillData('razgr', 'Разгрузка');

            if (form.lift && form.lift.checked)
                deliveryInfo['lift'] = 1;
            if (form.floor && form.floor.value != '')
                deliveryInfo['floor'] = form.floor.value;
        }


        fillData('cmnts', 'Примечание', false);


        if (tasksData.task.installers && tasksData.task.installers.length) {
            // console.dir(tasksData.task.installers);
            data['installers'] = [];
            tasksData.task.installers.map(_id => {
                if (parseInt(_id))
                    data['installers'].push(_id);
            });
        }
        // console.dir(tasksData.task);

        // console.dir(form.driverId.value);
        if (form.driverId)
            fillData('driverId', 'Водитель');
        else
            data['driverId'] = tasksData.task.driverId;

        //28/01/2022
        let tools = [];
        for (var i in form.tools) {
            if (isFinite(i)) {
                let itm = form.tools[i];
                if (itm.checked)
                    tools.push(itm.value);
            }
        }
        if (tools.length)
            deliveryInfo['tools'] = tools;

        if (form.toolsOther && form.toolsOther.value !== '')
            deliveryInfo['toolsOther'] = form.toolsOther.value;

        data['deliveryInfo'] = deliveryInfo;

        fillData('priceInstall', 'Стоимость монтажа');
        fillData('priceLights', 'Стоимость монтажа подсветки');
        fillData('priceUnload', 'Стоимость разгрузки');


        // console.dir(data);
        // return;

        formData.append('task', JSON.stringify(data));

        if (attachFile) {

            const uploadMaxSize = (user.settings && user.settings.upload_max_filesize)
                ? parseInt(user.settings.upload_max_filesize)
                : 0;

            // if ( && attachFile.files[0] && attachFile.files[0].size >= user.settings.upload_max_filesize) {
            // 	toastr.error('Внимание!', 'Размер загружаемого файла превышает ' + getFileSize(user.settings.upload_max_filesize));
            // 	err.push('');
            // }
            // formData.append('attachFile', attachFile.files[0]);
            // console.log(attachFile.files);


            if (attachFile.files.length) {
                var i = 0;
                for (let file of attachFile.files) {
                    // console.log(file);
                    if (file.size >= uploadMaxSize) {
                        toastr.error(
                            'Внимание!',
                            'Размер загружаемого файла превышает '
                            + getFileSize(uploadMaxSize)
                        );

                        err.push('');
                    } else {
                        formData.append('attachFile_' + i, file);
                        i++;
                    }

                }
            }
        }


        if (err.length) {
            {
                err.map((txt, i) => {
                    if (txt != '')
                        toastr.error('Не заполнено поле', txt);
                })
            }
        } else if (!apiActionProcess) {

            actions.showLoading();
            apiActionProcess = true;


            const res = await Api.post('tasks.php', formData);

            apiAction(res, (data) => {

                let tmp = tasksData;
                tmp.task.fileId = data.fileId;
                tmp.task.fileName = data.fileName;
                tmp.task.fileExt = data.fileExt;
                setTasksData(tmp);

                if (data.filesList)
                    setFilesList(data.filesList);

                if (isReturn)
                    props.history.push(onCloseUrl);

            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            });


        }
    };

    const onSelectClient = client => {

        const tmpTD = {...tasksData};
        tmpTD.task.clientId = client.id;
        tmpTD.task.name = client.name;
        tmpTD.task.company = client.company;
        tmpTD.task.phoneMobile = client.phoneMobile;
        tmpTD.task.address = client.adrs;
        tmpTD.task.phones = client.phones;
        tmpTD.task.cmnts = client.cmnts;

        setTasksData(tmpTD);
        setPopupWin(null);

    };

    const selectCompanyDialog = async (ctype) => {

        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;


            const res = await Api.post('users.php', {
                action: 'getClients',
                user: {id: user.id},
                ctype: ctype
            });

            apiAction(res, (response) => {

                setPopupWin(<CompanySelect
                    ctype={ctype}
                    data={response}
                    onClose={() => {
                        console.log('close');
                        setPopupWin(null);
                    }}
                    onSelect={onSelectClient}
                />);

            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            });


        }

    };
    const showMkadDialog = ctype => {
        toastr.message(
            "Сколько км от МКАД",
            "",
            {
                timeOut: 0, // by setting to 0 it will prevent the auto close
                showCloseButton: true, // false by default
                closeOnToastrClick: false, // false by default, this will close the toastr when user clicks on it
                position: "top-center",
                component: (
                    <div className={"task-popup-map"}>
                        <iframe
                            src={"https://www.online-perevozka.ru/tablica-rasstojanij.html"}
                        >

                        </iframe>
                    </div>
                )
            });
    };

    const deleteFile = async (taskId, fileId, callback) => {
        console.clear();
        console.log('deleteFile ' + taskId + ' / ' + fileId);

        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;


            const res = await Api.post('tasks.php', {
                action: 'deleteTaskFile',
                user: user,
                taskId: taskId,
                fileId: fileId
            });


            apiAction(res, (data) => {
                apiActionProcess = false;
                console.log(data);
                //
                // let tmp = tasksData;
                // tmp.edit = 1;
                // tmp.task.fileId = 0;
                // setTasksData(tmp);

                let newFilesList = [];
                filesList.map(file => {
                    if (data.deleted && file.fileId !== fileId)
                        newFilesList.push(file);
                });
                setFilesList(newFilesList);

                // console.log(typeof callback);
                if (typeof callback == 'function')
                    callback();
            });

            actions.hideLoading();
        }
    };

    const changeFieldsAction = (fieldName, fieldLabel, valueWas, valueNew, confirmedText) => {

        if (isNewTask || isCopy || fromOrder || valueWas == valueNew)
            return;
        console.log('changeFieldAction', fieldName, valueWas, valueNew, confirmedText);

        setChangeFieldsData((prev) => ({
            ...prev,
            [fieldName]: {
                value_was: valueWas,
                value_new: valueNew,
                txt: ''
            }
        }));

        var toastObj;
        if (confirmedText) {
            let txt = '';
            const showPrompt = () => {
                txt = prompt(`Впишите причину изменения "${fieldLabel}"`, '');
                if (!txt)
                    showPrompt();
            };
            showPrompt();
            if (txt != '') {
                setChangeFieldsData((prev) => ({
                    ...prev,
                    [fieldName]: {
                        ...prev[fieldName],
                        txt: txt
                    }
                }));
            }

            // if (0)
            // toastr.message(
            // 	`Вы изменили поле "${fieldLabel}"`,
            // 	{
            // 		timeOut: 0, // by setting to 0 it will prevent the auto close
            // 		showCloseButton: false, // false by default
            // 		closeOnToastrClick: false, // false by default, this will close the toastr when user clicks on it
            // 		position: "top-center",
            //
            // 		component: (
            // 			<div className={"task-popup-map"}>
            // 				<FormItem
            // 					edit={true}
            // 					fieldType={"text"}
            // 					name={"changeFieldTxt"}
            // 					label={"Впишите причину изменения"}
            // 					defVal={""}
            // 					isRequire={true}
            // 					ico={"asterisk"}
            // 					reff={changeFieldsTxtRef}
            // 				/>
            // 				<div className="_tac">
            // 					<Button
            // 						type="apply"
            // 						title={"OK"}
            // 						onClick={() => {
            // 							console.log(changeFieldsTxtRef.current.value);
            //
            // 							setChangeFieldsData((prev) => ({
            // 								...prev,
            // 								[fieldName]: {
            // 									...prev[fieldName],
            // 									txt: changeFieldsTxtRef.current.value.trim()
            // 								}
            // 							}));
            // 							setChangeFieldsToastTimeout(5);
            // 						}}/>
            // 				</div>
            // 			</div>
            // 		),
            // 		ok: true
            // 	});
        }
    };


    if (tasksData && tasksData.task) {
        if (isCopy) {
            tasksData.task.ddate = undefined;
            // delete tasksData.task['created'];
            // tasksData.task.created = undefined;
            // tasksData.task.edited = null;
            tasksData.task.id = 0;
            id = 0;
        }
        if (fromOrder) {
            tasksData.task.id = 0;
            id = 0;
        }
    }

    // if (tasksData && tasksData.task)
    //     console.dir(tasksData.task);

    let canEdit = (tasksData && tasksData.task && tasksData.task.ddate)
        ? isEditableTask(
            tasksData.task.ddate,
            tasksData.task.actualDays
        )
        : true;

    if (!isManagerModerator(user))
        canEdit = false;


    // console.log(tasksData);
    // const title = id ? (canEdit ? 'Редактирование заявки №' + id : 'Просмотр заявки №' + id) : 'Новая заявка';
    const title = id ? 'Заявка №' + id : 'Новая заявка';
    let saveButtons = null;

    const saveButtonsContent = (
        <>
            <Button type={BUTTON_TYPE.applyIco}
                    cls={"-green"}
                    onClick={() => saveTask(1)}
                // tip={"Сохранить и вернуться в заявкам"}
                // tipTimeout={1000}
            />
            {/*<Button type="save" onClick={() => saveTask(1)}/>*/}
        </>
    );


    // console.log(id);
    let deleteButton = null,
        copyButton = null;
    const copyDateRef = createRef();
    if (tasksData && tasksData.task && id) {

        if (!id || in_array(user.role, rolesModerator) || parseInt(tasksData.task.userId) === parseInt(user.id))
            saveButtons = saveButtonsContent;

        if (in_array(user.role, rolesModerator) || tasksData.task.userId === user.id)
            deleteButton = <Button
                type={"delete"}
                onClick={e => deleteTask(tasksData.task.id, props)}
            />;


        const copyAction = () => {
            // console.log(copyDateRef.current.value);
            if (copyDateRef.current.value === '')
                toastr.error('Ошибка', 'Выберите дату для копирования заявки');
            else
                props.history.push('/tasks/edit/' + tasksData.task.id + '-copy-' + copyDateRef.current.value.replaceAll('-', '.'))
        };

        if (!isCopy)
            copyButton = (
                <div className={"task-copy iflx -sb -alc ml"}>
                    <Button type={""}
                            ico={"clone"}
                            title={<div className={""}>
                                Копировать
                            </div>}
                            onClick={e => copyAction()}/>
                    <input type={"date"} name={"copyDate"} ref={copyDateRef}/>
                </div>
            );

    } else if (id === 0)
        saveButtons = saveButtonsContent;

    const closeButton = <Button
        type={BUTTON_TYPE.closeIco}
        onClick={() => {
            props.history.push(onCloseUrl)
        }}
        tip={"Закрыть без сохранения"}
    />;

    let managerCanCreateTask = id !== 0 || canCreateTask(user.role) === true;


    const winHeader = (
        <div className="flx -sb -alc w100">
            <div className="">

                {
                    id ? <TasksTabs
                        user={user}
                        id={id}
                        tab={"edit"}
                        task={
                            (tasksData && tasksData.task) ? tasksData.task : null
                        }

                    /> : null
                }


            </div>
            <div className="win-ftr-btns">
                {managerCanCreateTask ? saveButtons : null}
                {closeButton}
            </div>
        </div>
    );


    return (
        <>
            <ContentHeader
                title={title}
                parent={[{url: '/tasks', name: 'Заявки'}]}
                showDates={false}
            />

            <Win
                // onClose={() => props.history.push('/tasks')}
                header={winHeader}
                footer={
                    (
                        <div className={"win-ftr-btns flx -sb"}>
                            <div className="">

                                {managerCanCreateTask ? saveButtons : null}
                                {closeButton}
                                {copyButton}
                            </div>
                            <div className="_tar">
                                {deleteButton}
                            </div>
                        </div>
                    )
                }
            >

                {
                    !managerCanCreateTask
                        ? 'Создание заявки запрещено'
                        : (
                            (tasksData) ?
                                <TasksEdit
                                    filesList={filesList}
                                    edit={canEdit}
                                    formRef={formRef}
                                    attachFileRef={attachFileRef}
                                    // task={task}
                                    // setTask={setTask}
                                    tasksData={tasksData}
                                    setTasksData={setTasksData}

                                    // todoList={tasksData.todoList}
                                    // predefined={tasksData.predefined}
                                    // drivers={tasksData.drivers}
                                    // installers={tasksData.installers}
                                    // todoDostavkaCode={tasksData.todoDostavkaCode}
                                    // todoInstallCode={tasksData.todoInstallCode}
                                    // todoTwoInstaller={tasksData.todoTwoInstaller}


                                    selectCompanyDialog={selectCompanyDialog}
                                    // showMkadDialog={showMkadDialog}
                                    deleteFile={deleteFile}
                                    isCopy={isCopy}
                                    copyDate={copyDate}
                                    paramTodo={paramTodo}
                                    changeFieldsAction={changeFieldsAction}

                                    {...props}
                                />
                                : null
                        )
                }

            </Win>
            {popupWin}
        </>
    )

};

const mapStateToProps = store => ({
    user: store.user,
    GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        showLoading, hideLoading, setCalendarSelectDateAction, setSearchAction,
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TasksEditContainer);
