import React from 'react';
import {Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import FormsContainer from "containers/forms/FormsContainer";
import FormsGet from "containers/forms/FormsGet";
import FormsShowContainer from "containers/forms/FormsShowContainer";

const FormsRouter = props => {

	return(
		<Switch>
			<Route
				path="/forms"
				exact={true}
				key={"forms"}
			>
				<FormsContainer {...props} />
			</Route>
			<Route path="/forms/get" key={"forms-get"}>
				<FormsGet {...props} />
			</Route>
			<Route path="/forms/:code" exact={true} key={"forms-code"}>
				<FormsContainer {...props} />
			</Route>
			<Route path={["/forms/show/:id"]} exact={true} key={"form-show"}>
				<FormsShowContainer {...props} />
			</Route>


			<Route component={Page404} />
		</Switch>
	)
	// return <FormsContainer {...props} />

};

export default FormsRouter;