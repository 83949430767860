import React, {useEffect, useState} from 'react';
import {UsersListItem} from "components/user";
import {in_array} from "utils/func";
import cn from "classnames";
import {DIVISION_TITLE} from "utils/config";
// import {preventDuplication} from "react-redux-toastr/lib/utils";

const UsersList = props => {

	const {users, user, onDelete, roleInit} = props;

	const [usersShow, setUsersShow] = useState(users);
	const [showRole, setShowRole] = useState(roleInit);

	/**
	 * скрываем сортировки
	 * @param e
	 */
	const handleClickOutside = e => {
		if (e.target.className.indexOf('tbl-sorter-select') === -1) {
			setRoleIsHide(true);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		setUsersShow(users);
		setShowRole(roleInit);
		// console.log(roleInit);

		return () => {
			// document.removeEventListener("mousedown", handleClickOutside);
			// setUsersShow([]);
			// setShowRole('');
		}
	}, [props]);

	const [sorter, setSorter] = useState({});
	const usersSort = (field, value) => {
		let sorter_new = sorter;
		sorter_new[ field ] = value;
		setSorter(sorter_new);


		let stepUsers = users;
		console.log(stepUsers);
		const sortStep = (selUsers, fld, val) => {
			let res = [];
			selUsers.map((user) => {
				if (val == '' || user[fld] == val)
					res.push(user);

			});
			return res;
		};
		for (const fld in sorter) {
			stepUsers = sortStep(stepUsers, fld, sorter[fld]);
		}
		setUsersShow(stepUsers);
	};

	/*
	 * Выбор роли
	 */

	const [roleIsHide, setRoleIsHide] = useState(true);
	const roleOptions = [];
	const divisionOptions = [];
	let usedRolesOptions = [];
	let usedDivisionOptions = [];
	users.map((_user) => {
		_user.divId = parseInt(_user.divId);
		if (!in_array(_user.role, usedRolesOptions)) {
			usedRolesOptions.push(_user.role);
			roleOptions.push({
				label: _user.roleName,
				value: _user.role
			})
		}
		if (!in_array(_user.divId, usedDivisionOptions)) {
			usedDivisionOptions.push(_user.divId);
			divisionOptions.push(
				_user.divId  ? _user.division : {id: _user.divId}
			);
		}
	});
	let roleHeaderSelect = 'Роль';
	if (roleInit === '')
		roleHeaderSelect = (
		<select
			className={"tbl-sorter-select " + (roleIsHide ? 'hidden' : '')}
			onMouseEnter={() => setRoleIsHide(false)}
			onChange={(e) => {
				setRoleIsHide(true);
				usersSort('role', e.target.value);
				setShowRole(e.target.value)
			}}
		>
			<option key={"role-"} value={""} className={"tbl-sorter-select-option"}>Роль</option>
			{roleOptions.map((itm) => {
				return (
					<option
						value={itm.value}
						key={"role-" + itm.value}
						className={"tbl-sorter-select-option"}
					>
						{itm.label ? itm.label : 'без роли'}
					</option>);
			})}
		</select>
	);

	const [sortField, setSortField] = useState('id');
	const [sortAsc, setSortAsc] = useState(true);
	const sortBy = (field) => {
		usersShow.sort((a, b) => {
			if (a[field] > b[field])
				return !sortAsc ? 1 : -1;
			if (a[field] < b[field])
				return !sortAsc ? -1 : 1;

			return 0;
		});
		setSortField(field);
		setSortAsc(!sortAsc);
	};

	// console.log(showRole);
	let tblColumnRoleTitle = '';
	switch(showRole) {
		case 'driver':
			tblColumnRoleTitle = 'Автомобиль';
			break;
		case 'installer':
			tblColumnRoleTitle = 'Паспортные данные';
			break;
		default:
			tblColumnRoleTitle = 'Email/Телефон';
			break;
	}

	return (
		<div className="mt20">
			<table className="tbl users-list">
				<thead>
				<tr>
					<td width="3%" className="_tac">
						&nbsp;&nbsp;
					</td>
					<td width="30%" className={"sortable"} onClick={() => sortBy('name')}>
						Пользователь
						<div className={cn("tbl-sort", {active: sortField === 'name'})}>
							<i className={cn("fa",{"fa-caret-down": sortAsc,"fa-caret-up": !sortAsc})}></i>
						</div>
					</td>
					<td width="7%">
						Логин
					</td>
					<td width="15%">
						{roleHeaderSelect}
					</td>
					<td width="30%">
						{tblColumnRoleTitle}
					</td>
					<td width="20%">
						{DIVISION_TITLE.one}
					</td>
				</tr>
				</thead>
				<tbody>

				{usersShow.map((item, i) =>
					<UsersListItem
						key={i}
						sysUser={user}
						user={item}
						onDelete={onDelete}
						showRole={showRole}
					/>
				)
				}
				</tbody>
			</table>

		</div>
	)

};

export default UsersList;
