import React from 'react';
import date from "php-date";
import {NavLink} from "react-router-dom";
import Button, {BUTTON_TYPE} from "components/_common/Button";
import Win from "components/_common/Win";
import {MESSAGES} from "utils/config";

const MailboxListItem = props => {

	// data.body !== '' ? data.body.replace("\n", "<br />") : "&nbsp;"
	const {data, sendToForms, deleteEmail} = props;

	// console.dir(data);
	const attachments = [];

	if (data.attachments.length)
		data.attachments.map((item, i) => {
			attachments.push(
				<div key={i} >
					<a href={item.url} target={"_blank"}>
						<i className={"fa fa-file-o"}></i> {item.name}
					</a>
				</div>
			)
			return null;
		});
	// console.log(props);

	const header =
		<div className="mi-hdr flx -sb -alc">
			<div className="-subj">
				<span>Тема письма: </span>
				{data.subject}
				{/*({data.number}; {data.id})*/}
			</div>
			<div className="-dt">
				{date('d.m.Y H:i', new Date(data.timestamp * 1000))}
			</div>
		</div>;

	const content = <div>
		<div className="-body"
			 // dangerouslySetInnerHTML={{__html: data.body}}
		>
			<iframe
				srcDoc={data.body}
			/>
		</div>
		{attachments.length ? <div className="attach-list">{attachments}</div>: null}
	</div>;

	const footer = <div className="flx -sb">
		{
			data.formId
				? <NavLink
					to={"/forms/show/" + data.formId}
					className={"btn"}
				>
					Открыть в формах
			</NavLink>
				: <Button
					type={BUTTON_TYPE.save}
					onClick={e => sendToForms(data.number)}
					title={"Отправить менеджерам"}
					confirmText={MESSAGES.confirmAction}
				/>
		}

		<Button
			type={BUTTON_TYPE.delete}
			onClick={e => deleteEmail(data.number)}
			confirmText={MESSAGES.confirmDelete}
		/>
	</div>;

	return <Win
		winClass={"mailbox-item"}
		header={header}
		children={content}
		footer={footer}
	/>

	//
	// return (
	// 	<ul className="tr">
	// 		<li className="td col-date">
	// 			{date('d.m.Y H:i', new Date(data.timestamp * 1000))}
	// 		</li>
	// 		<li className="td col-email">
	// 			{data.email}
	// 			<br />
	// 			{data.name}
	// 		</li>
	// 		<li className="td col-subject">
	// 			{data.subject}
	// 		</li>
	// 		<li className="td col-txt">
	// 			<div className="-body"
	// 				 dangerouslySetInnerHTML={{__html: data.body}}
	// 			>
	//
	// 			</div>
	// 			{attachments.length ? <div className="attach-list">{attachments}</div>: null}
	//
	// 			<br /><br />
	//
	// 			{/*<Button*/}
	// 			{/*	type={"save"}*/}
	// 			{/*	title={"Без дополнительной информации"}*/}
	// 			{/*	onClick={e => alert('Действие не описано заказчиком')}*/}
	// 			{/*/>*/}
	// 			{/*<br /><br />*/}
	// 			{/*<Button*/}
	// 			{/*	type={"save"}*/}
	// 			{/*	title={"С дополнительной информацией"}*/}
	// 			{/*	onClick={e => alert('Действие не описано заказчиком')}*/}
	// 			{/*/>*/}
	// 		</li>
	// 		<li className="td col-acts">
	// 			<Button
	// 				type={BUTTON_TYPE.save}
	// 				onClick={e => sendToForms(data.id)}
	// 				title={"Отправить менеджерам"}
	// 			/>
	// 		</li>
	// 	</ul>
	// )

};

export default MailboxListItem;
