import React from 'react';
import {FormItem} from "components/_common";
import DocsListFiles from "components/docs/DocsListFiles"

const UserInfo = props => {

	return (
		<>
			<FormItem
				edit={false}
				name={"name"}
				// label={"Имя"}
				defVal={props.name}
				ico={"user"}
			/>
			{
				props.role === 'driver' ? (
					<>
						<FormItem
							edit={false}
							name={"car"}
							// label={"Атомобиль"}
							defVal={props.car}
							ico={"car"}
						/>
					</>
				): null
			}
			{
				props.role === 'installer' ? (
					<>
						<FormItem
							edit={false}
							name={"passport"}
							label={"Паспортные данные"}
							defVal={props.passport}
							// ico={"id-card"}
						/>
					</>
				): null
			}

			{
				props.files.length
					? <DocsListFiles
						files={props.files}
						user={props}
					/> : null
			}


		</>
	)

};

export default UserInfo;