import React, {useEffect} from 'react';
import {FormItem} from "components/_common";
import {DAYS_FULL, LIMITS_DELIVERY_BY_DAY, LIMITS_INSTALL_BY_DAY} from "utils/config";

const Limits = props => {
	const {settings,
		savedSettings, setSavedSettings
	} = props;

	let limit_delivery, limit_installers;
	if (settings.limit_delivery === undefined)
		limit_delivery = new Array(7).fill(LIMITS_DELIVERY_BY_DAY, 0, 7);
	else
		limit_delivery = settings.limit_delivery;

	if (settings.limit_installers === undefined)
		limit_installers = new Array(7).fill(LIMITS_INSTALL_BY_DAY, 0, 7);
	else
		limit_installers = settings.limit_installers;


	useEffect(() => {

			setSavedSettings({
				...savedSettings,
				limit_delivery: limit_delivery,
				limit_installers: limit_installers
			});

			return () => {

			}
		},
		[]
	);

	let installersDays = [],
		deliveryDays = [];
	for (let i = 0; i < 7; i++) {
		deliveryDays.push(
			<div className={"limits-inst-days"} key={"lim-dl-" + i}>
				<FormItem
					fieldType={"text"}
					name={"limit_delivery_" + i}
					label={DAYS_FULL[i]}
					defVal={limit_delivery[i]}
					changeAction={e => {
						let sets = {...savedSettings};
						sets.limit_delivery[i] = parseInt(e.target.value.trim());
						setSavedSettings(sets);
					}}
					numericRound={0}
				/>
			</div>
		);

		installersDays.push(
			<div className={"limits-inst-days"} key={"lim-inst-" + i}>
				<FormItem

					fieldType={"text"}
					name={"limit_installers_" + i}
					label={DAYS_FULL[i]}
					defVal={limit_installers[i]}
					changeAction={e => {
						let sets = {...savedSettings};
						sets.limit_installers[i] = parseInt(e.target.value.trim());
						setSavedSettings(sets);
					}}
				/>
			</div>
		);
	}

	return (
		<div className={"row"}>
			<div className="col-2">
				<h2>для Доставки по дням недели</h2>
				<div className="mt20">
					{deliveryDays}
				</div>
			</div>
			<div className="col-2">
				<h2>для Монтажников по дням недели</h2>
				<div className="mt20">
					{installersDays}
				</div>
			</div>
		</div>
	)

};

export default Limits;