import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {setCalendarSelectDateAction, setSearchAction} from "actions/GUIActions";
import {rolesAdmin} from "utils/config";
import {in_array} from "utils/func";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Win} from "components/_common";
import {TasksTabs, DeliveryView, DeliveryEdit} from "components/tasks";
// import {toastr} from "react-redux-toastr";
import date from "php-date";
import * as strtotime from "strtotime";



const API_URL = 'tasks.php';

const DeliveryContainer = props => {

    const {
        // GUI,
        user,
        actions
    } = props;

    let {id = 0} = useParams();

    const isAdmin = in_array(user.role, rolesAdmin);
    const isDriver = in_array(user.role, ['driver']);

    const [data, setData] = useState(null);

    let apiActionProcess = false;
    const getData = async (isSubscribe = true) => {
        if (!isSubscribe)
            return;

        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;

            const res = await Api.post(API_URL, {
                action: 'getDelivery',
                user: user,
                id: id
            });

            apiAction(res, (resp) => {
                // console.dir(resp);
                setData(resp);
            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            });
            apiActionProcess = false;
        }
        actions.hideLoading();
    };

    useEffect(() => {
            let isSubscribe = true;

            getData(isSubscribe).then(null);

            return () => {
                isSubscribe = false;
            }
        },
        []
    );




    const backUrl = '/tasks/edit/' + id;

    const winHeader = (
        <div className="flx -sb -alc w100">
            {
                id ? <TasksTabs
                    user={user}
                    id={id}
                    tab={"delivery"}
                    task={(data && data.task) ? data.task : null}
                /> : null
            }
        </div>
    );
    let content = null;

    let closeUrl = '/tasks/';


    if (data !== null) {

        // console.log(isDriver);
        // console.log(data.movers);

        if (data.task)
            closeUrl = '/tasks/date/' + date('Y-n-j', new Date(strtotime(data.task.ddateReal) * 1000));

        if (isDriver) {
            //если не сдан
            if (data.delivery == null
                || (data.delivery.completeDate != undefined && parseInt(data.delivery.completeDate) === 0)
            )
                content = <DeliveryEdit
                    {...props}
                    task={data.task}
                    delivery={data.delivery}
                    moversAll={data.movers}
                    closeUrl={closeUrl}
                />
            else
                content = <DeliveryView
                    {...props}
                    task={data.task}
                    delivery={data.delivery}
                    moversAll={data.movers}
                    closeUrl={closeUrl}
                />;
        }
        else
            content = <DeliveryView
                {...props}
                task={data.task}
                delivery={data.delivery}
                moversAll={data.movers}
            />;
    }

    return (
        <>
            <ContentHeader
                title={"Доставка"}
                parent={[{url: '/tasks', name: 'Заявки'}, {url: backUrl, name: `Заявка №${id}`}]}
                showDates={false}
            />
            <Win
                onClose={() => props.history.push(closeUrl)}
                header={winHeader}
            >
                {content}
            </Win>
        </>
    )

};

const mapStateToProps = store => ({
    user: store.user,
    GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        showLoading, hideLoading, setCalendarSelectDateAction, setSearchAction,
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryContainer);
