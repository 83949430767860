import React, {useState} from 'react';
import cn from "classnames";
import {DEBUG} from "utils/config";

const TabsById = props => {

    let {
        tabs = [], activeTabInit = null,
        tabPrefix = 'tab',
        url = '',
        useUrl = true,
        onChange = null
    } = props;


    if (useUrl && window.location.hash != '') {
        let hashTabId = window.location.hash.split('-').pop();
        if (hashTabId != undefined)
            activeTabInit = hashTabId;
    }
    if (activeTabInit === null)
        activeTabInit = tabs[0].id;


    const [activeTabId, setActiveTab] = useState(activeTabInit);

    const tabLen = tabs.length;

    const changeTab = (id) => {
        if (useUrl)
            window.history.pushState(
                {
                    page: 'settings',
                    tab: id
                },
                '',
                `${url}#${tabPrefix}-${id}`
            );
            setActiveTab(id);
        if (typeof onChange == 'function')
            onChange(id);
    }


    return (
        <div className="tabs">
            <ul className="tabs-list flx _z">
                {
                    tabs.map((tab, i) => {

                        let title = tab.title ? tab.title : 'undefined';
                        return (
                            <li
                                key={`tabs-list-item-${tab.id}`}
                                className={cn({active: activeTabId == tab.id})}
                                onClick={() => changeTab(tab.id)}
                                style={{
                                    zIndex: ((i - tabLen) * -1) + (activeTabId == tab.id ? 1 : 0)
                                }}
                            >
                                {title}
                                { DEBUG ? ` (${tab.id})` : null}
                            </li>
                        )
                    })
                }
            </ul>
            <div className="tabs-panels">
                {
                    tabs.map((tab, i) => {
                        return (<div
                            key={`tabs-panel-${tab.id}`}
                            className={cn({active: activeTabId == tab.id})}
                        >
                            {
                                tab.content
                                    ? tab.content
                                    : null
                            }
                        </div>);
                    })
                }
            </div>
        </div>
    )

};

export default TabsById;
