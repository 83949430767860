import React from 'react';
import {Win} from "components/_common";
import {NavLink} from "react-router-dom";
import TaskManagerListItem, {SetTaskManagerDataItem} from "components/task_manager/TaskManagerListItem";
import {in_array} from "utils/func";
import {rolesModerator} from "utils/config";

const TaskManagerList = props => {
	const {
		user,
		type,
		config,
		data = [],
		setStatus
	} = props;

	if (data.length === 0)
		return null;

	const isAdmin = in_array(user.role, rolesModerator);
	const isManager = user.role == 'manager';

	return (
		<div className="tm-item-wrp">
			<Win
				winClass={"tm-item"}
				header={
					<div className="tit">
						<i className={`fa fa-${config.ico}`}></i> {config.title}
					</div>
				}
			>
				{
					data.map(item => {
						return <TaskManagerListItem
							key={"TaskManagerListItem-" + item.id}
							{...SetTaskManagerDataItem(type, item, user, isAdmin)}
							isAdmin={isAdmin}
							isManager={isManager}
							setStatus={setStatus}
						/>
					})
				}
			</Win>
		</div>

	)

};

export default TaskManagerList;


