import React, {createRef, useState} from 'react';
// import cn from "classnames";
// import date from "php-date";
// import * as strtotime from "strtotime";
// import {toastr} from "react-redux-toastr";
import {FormItem, Tabs} from "components/_common";
import {in_array, array_unset} from "utils/func";
import {mailboxSubMenu} from "utils/config";

const General = props => {

	const {
		settings, divisions, initTodoSelectColors,
		savedSettings, setSavedSettings,
		userRoles,
		todoList, initTodoListBgr
	} = props;

	// console.log(initTodoSelectColors);
	const [todoSelectColors, setTodoSelectColors] = useState(initTodoSelectColors);

	// console.log(todoSelectColors);
	const divisionsArray = Object.keys(divisions);


	const formRef = createRef();

	const tabsData = [];

	let colors4division = [],
		sms4divisions = [];

	if (divisionsArray.length) {


		// console.log(initTodoSelectColors);

		for (const divId in divisions) {

			const {name} = divisions[divId];

			colors4division.push(
				<div className="row flx -alc mt20" key={"todo=colors-" + divId}>
					<div className="col-3">
						Для кладовщика {name}
					</div>
					<div className="col-3">
						цвет фона&nbsp;
						<input
							type={"color"}
							name={"g_todo-select-bgr-" + divId}
							// value={'#ffffff'}
							value={todoSelectColors[divId].bgr}
							// value={(todoSelectColors[divId] && todoSelectColors[divId].bgr) ? todoSelectColors[divId].bgr : ''}
							onChange={e => {
								console.log('onChange');
								let tmp = {...todoSelectColors};
								tmp[divId].bgr = e.target.value;
								// console.log(tmp[divId]);
								setTodoSelectColors(tmp);

								let s = {...savedSettings};
								s["g_todo-select-bgr-" + divId] = e.target.value;
								setSavedSettings(s);
							}}
						/>
						&nbsp;
						цвет текста&nbsp;
						<input
							align={"middle"}
							type={"color"}
							name={"g_todo-select-color-" + divId}
							value={todoSelectColors[divId].color}
							onChange={e => {
								// let tmp = {...todoSelectColors};
								// setTodoSelectColors(tmp)
								let tmp = {...todoSelectColors};
								tmp[divId].color = e.target.value;
								setTodoSelectColors(tmp);

								let s = {...savedSettings};
								s["g_todo-select-color-" + divId] = e.target.value
								setSavedSettings(s);
							}}
						/>
					</div>
					<div className="col-3">
						<div className="input" style={{backgroundColor: todoSelectColors[divId].bgr, color: todoSelectColors[divId].color}}>
							Пример: Для кладовщика Боровая
						</div>

					</div>
				</div>
			);
			// console.log(divId);
			sms4divisions.push(
				<div key={"sms-key-" + divId}>
					<div className="row">
						<div className="col-2">
							<FormItem
								fieldType={"text"}
								name={"g_sms_api_id_" + divId}
								label={divisions[divId].name + ": API ID"}
								defVal={settings['g_sms_api_id_' + divId] && settings['g_sms_api_id_' + divId]}
								changeAction={e => {
									let s = {...savedSettings};
									s["g_sms_api_id_" + divId] = e.target.value;
									setSavedSettings(s);
								}}
							/>
						</div>
						<div className="col-2">
							<FormItem
								fieldType={"text"}
								name={"g_sms_code_" + divId}
								label={divisions[divId].name + ": буквенный код отправителя"}
								defVal={settings['g_sms_code_' + divId] && settings['g_sms_code_' + divId]}
								changeAction={e => {
									let s = {...savedSettings};
									s["g_sms_code_" + divId] = e.target.value;
									setSavedSettings(s);
								}}
							/>
						</div>
					</div>
					<FormItem
						fieldType={"txt"}
						name={"g_sms_text_" + divId}
						label={`${divisions[divId].name} (${divId}): Текст СМС о доставке`}
						defVal={settings['g_sms_text_' + divId] && settings['g_sms_text_' + divId]}
						descr={"Используйте шаблоны для замены: {date} - дата заявки; {name} - имя водителя; {phone} - телефон водителя"}
						changeAction={e => {
							let s = {...savedSettings};
							s["g_sms_text_" + divId] = e.target.value;
							setSavedSettings(s);
						}}
					/>
					<FormItem
						fieldType={"txt"}
						name={"g_sms_text_install_" + divId}
						label={`${divisions[divId].name} (${divId}): Текст СМС о монтаже`}
						defVal={settings['g_sms_text_install_' + divId] && settings['g_sms_text_install_' + divId]}
						descr={"Используйте шаблоны для замены: {date} - дата заявки; {installers} - список монтажников (в конце будут дописаны имена монтажников и их телефоны)"}
						changeAction={e => {
							let s = {...savedSettings};
							s["g_sms_text_install_" + divId] = e.target.value;
							setSavedSettings(s);
						}}
					/>
				</div>
			);
		}
	}
	let sms4roles = [];
	if (userRoles) {

		const {g_send_sms_roles: sms_roles_init = []} = settings;
		const {g_send_sms_roles = sms_roles_init} = savedSettings;


		for (const role in userRoles) {
			sms4roles.push(
				<label
					key={"sets-sms-roles-" + role}
					className={"flx"}>
					<input

						type={"checkbox"}
						name={"g_send_sms_roles"}
						// isChecked={in_array(role, g_send_sms_roles)}
						defaultChecked={in_array(role, g_send_sms_roles)}
						// checked={in_array(role, g_send_sms_roles)}
						onChange={e => {
							let tmp = g_send_sms_roles;
							if (e.target.checked) {
								if (!in_array(role, tmp))
									tmp.push(role);
							} else
								tmp = array_unset(tmp, role);
							setSavedSettings({
								...savedSettings,
								g_send_sms_roles: tmp
							});
						}}
					/>
					{userRoles[role]}
				</label>
			)
		}
	}


	const [todoListBgr, setTodoListBgr] = useState(initTodoListBgr);

	// useEffect(() => {
	//
	// 		let tlbInit = {};
	// 		for (let todo in todoList) {
	// 			let clr;
	//
	// 			if (todoList[todo].hideInTodo === undefined || !todoList[todo].hideInTodo) {
	// 				if (settings.task_list_bgr && settings.task_list_bgr[todo])
	// 					clr = settings.task_list_bgr[todo];
	// 				else
	// 					clr = '#ffffff';
	// 					// clr = '#FD7E14';
	//
	// 				tlbInit[todo] = clr;
	// 			}
	// 		}
	// 		console.log(tlbInit);
	// 		setTodoListBgr(tlbInit);
	//
	// 		return () => {
	// 			setTodoListBgr({});
	// 		}},
	// 	[]
	// );

	let colorsTodo = [];


	for (let todo in todoList) {

		if (todoList[todo].hideInTodo === undefined || !todoList[todo].hideInTodo) {
			colorsTodo.push(
				<div
					className="row flx -alc mt20"
					key={"todo-colors-" + todo}
					style={{backgroundColor: todoListBgr[todo]}}
				>
					<div className="col-3">
						{todoList[todo].name}
					</div>
					<div className="col-3">
						цвет фона&nbsp;
						<input
							type={"color"}
							name={"task_list_bgr_" + todo}
							value={todoListBgr[todo]}

							onChange={e => {
								// console.log(todo + '>'+e.target.value);
								// let tmp = {...todoListBgr};
								// tmp[todo] = e.target.value;
								// setTodoListBgr(tmp);

								setTodoListBgr({
									...todoListBgr,
									[todo]: e.target.value
								})

								setSavedSettings({
									...savedSettings,
									task_list_bgr: todoListBgr
								});
							}}
						/>
					</div>
				</div>
			);
		}
	}

	tabsData.push({
		title: 'Цвета',
		content: (
			<>
				<fieldset>
					<legend>Цвета в списке "Что делаем"</legend>
					{colors4division}
				</fieldset>
				{
					colorsTodo.length
						? <fieldset className={"mt20 pt20"}>
							<legend>Цвета строк таблицы в списке заявок</legend>
							{colorsTodo}
						</fieldset>
						: null
				}
			</>
		)
	});

	tabsData.push({
		title: 'SMS-уведомления',
		content: <>
			<FormItem
				fieldType={"checkbox"}
				defVal={"1"}
				name={"g_sms_test"}
				label={"Включить тестовый режим"}
				isChecked={settings.g_sms_test && parseInt(settings.g_sms_test)}
				changeAction={e => {
					setSavedSettings({
						...savedSettings,
						g_sms_test: e.target.checked ? 1 : 0
					});
				}}
			/>
			{sms4divisions}

			<div className="form-item">
				<label>Отправка СМС доступна:</label>
				<div>
					{sms4roles}
				</div>
			</div>
		</>
	});

	tabsData.push({
		title: 'Asterisk',
		content: <>
			<div className="row">
				<div className="col-2">
					<FormItem
						fieldType={"text"}
						name={"asterisk_host"}
						label={"Host"}
						defVal={settings.asterisk_host && settings.asterisk_host}
						changeAction={e => {
							setSavedSettings({
								...savedSettings,
								asterisk_host: e.target.value.trim()
							});
						}}
					/>
				</div>
				<div className="col-2">
					<FormItem
						fieldType={"text"}
						name={"asterisk_db_name"}
						label={"DB name"}
						defVal={settings.asterisk_db_name && settings.asterisk_db_name}
						changeAction={e => {
							setSavedSettings({
								...savedSettings,
								asterisk_db_name: e.target.value.trim()
							});
						}}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-2">
					<FormItem
						fieldType={"text"}
						name={"asterisk_user"}
						label={"user"}
						defVal={settings.asterisk_user && settings.asterisk_user}
						changeAction={e => {
							setSavedSettings({
								...savedSettings,
								asterisk_user: e.target.value.trim()
							});
						}}
					/>
				</div>
				<div className="col-2">
					<FormItem
						fieldType={"text"}
						name={"asterisk_password"}
						label={"password"}
						defVal={settings.asterisk_password && settings.asterisk_password}
						changeAction={e => {
							setSavedSettings({
								...savedSettings,
								asterisk_password: e.target.value.trim()
							});
						}}
					/>
				</div>
			</div>
		</>
	});

	let imapFields = [
		{
			name: 'host',
			label: 'Host',
			descr: null,
		},
		{
			name: 'port',
			label: 'Порт',
			descr: 'число',
		},
		{
			name: 'flags',
			label: 'Флаги',
			descr: (<a href={"https://www.php.net/manual/ru/function.imap-open.php"} target={"_blank"}>о флагах тут</a>),
		},
		{
			name: 'user',
			label: 'Пользователь',
			descr: null,
		},
		{
			name: 'password',
			label: 'Пароль',
			descr: null,
		},
	];

	let imapContent = [];

	for (let mailbox of mailboxSubMenu) {

		let imapFieldsContent = [];
		for (let imapField of imapFields) {
			let imapParamName = `${mailbox.id}_imap_${imapField.name}`;
			imapFieldsContent.push(
				<FormItem
					key={`sets-${imapParamName}`}
					fieldType={"text"}
					name={imapParamName}
					label={imapField.label}
					descr={imapField.descr}
					defVal={settings[imapParamName] ?? ''}
					changeAction={e => {
						// console.dir(e.target.name, e.target.value);
						setSavedSettings({
							...savedSettings,
							[e.target.name]: e.target.value.trim()
						});
					}}
				/>
			);
		}

		imapContent.push(
			<fieldset key={`sets-imap-${mailbox.id}`} className={"mt20"}>
				<legend>{mailbox.name}</legend>
				<div className={"mt20"}>{imapFieldsContent}</div>
			</fieldset>
		);
	}
	/**
	 * Сама блилиотека тут
	 * https://github.com/ddeboer/imap
	 */
	tabsData.push({
		title: 'IMAP',
		content: imapContent
	});
	/*
	tabsData.push({
		title: 'IMAP2',
		content: <>
			<div className="row">
				<div className="col-3">
					<FormItem
						fieldType={"text"}
						name={"imap_host"}
						label={"Host"}
						descr={<a href={"https://github.com/ddeboer/imap"} target={"_blank"}>библиотека</a>}
						defVal={settings.imap_host && settings.imap_host}
						changeAction={e => {
							setSavedSettings({
								...savedSettings,
								[e.target.name]: e.target.value.trim()
							});
						}}
					/>
				</div>
				<div className="col-3">
					<FormItem
						fieldType={"text"}
						name={"imap_port"}
						label={"Порт"}
						descr={"число"}
						defVal={settings.imap_port && settings.imap_port}
						changeAction={e => {
							setSavedSettings({
								...savedSettings,
								[e.target.name]: e.target.value.trim()
							});
						}}
					/>
				</div>
				<div className="col-3">
					<FormItem
						fieldType={"text"}
						name={"imap_flags"}
						label={"Флаги"}
						descr={<a href={"https://www.php.net/manual/ru/function.imap-open.php"} target={"_blank"}>о флагах тут</a>}
						defVal={settings.imap_flags && settings.imap_flags}
						changeAction={e => {
							setSavedSettings({
								...savedSettings,
								[e.target.name]: e.target.value.trim()
							});
						}}
					/>
				</div>
			</div>

			<div className="row">
				<FormItem
					cls={"col-2"}
					fieldType={"text"}
					name={"imap_user"}
					label={"Пользователь"}
					defVal={settings.imap_user && settings.imap_user}
					changeAction={e => {
						setSavedSettings({
							...savedSettings,
							[e.target.name]: e.target.value.trim()
						});
					}}
				/>
				<FormItem
					cls={"col-2"}
					fieldType={"text"}
					name={"imap_password"}
					label={"Пароль"}
					defVal={settings.imap_password && settings.imap_password}
					changeAction={e => {
						setSavedSettings({
							...savedSettings,
							[e.target.name]: e.target.value.trim()
						});
					}}
				/>
			</div>
		</>
	});
	 */
	tabsData.push({
		title: 'Прочее',
		content: <>
			<FormItem
				fieldType={"text"}
				name={"mkad_url"}
				label={"Расчет расстояния от МКАД, URL"}
				defVal={settings.mkad_url && settings.mkad_url}
				changeAction={e => {
					setSavedSettings({
						...savedSettings,
						mkad_url: e.target.value.trim()
					});
				}}
			/>
			<a name={"truckTime"} id={"truckTime"}/>
			<FormItem
				fieldType={"text"}
				name={"truckTime"}
				label={"Время приезда машины с производства"}
				defVal={settings.truckTime && settings.truckTime}
				changeAction={e => {
					setSavedSettings({
						...savedSettings,
						truckTime: e.target.value.trim()
					});
				}}
			/>
			<FormItem
				fieldType={"text"}
				name={"tasksActualDaysMax"}
				numericRound={0}
				label={"Максимальное кол-во дней действия заявок"}
				defVal={settings.tasksActualDaysMax && settings.tasksActualDaysMax}
				changeAction={e => {
					setSavedSettings({
						...savedSettings,
						tasksActualDaysMax: e.target.value.trim()
					});
				}}
			/>
			<FormItem
				fieldType={"textarea"}
				name={"unloadCalcTips"}
				label={"Калькулятора расчета разгрузки, подсказки по весу мебели"}
				defVal={settings.unloadCalcTips ? settings.unloadCalcTips : ""}
				changeAction={e => {
					setSavedSettings({
						...savedSettings,
						unloadCalcTips: e.target.value.trim()
					});
				}}
				descr={"Разделитель - перевод строки"}
			/>
		</>
	});

	return (
		<form encType={"multipart/form-data"} ref={formRef}>
			<Tabs
				data={tabsData}
				url={'/settings/general'}
			/>
		</form>
	);

};

export default General;
