import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Button} from "components/_common";
import {DIVISION_TITLE} from "../../utils/config";
import {NavLink} from "react-router-dom";
import ClientsList, {clearCompanyName} from "components/user/ClientsList";
import {Storage} from "../../utils/Storage";
// import {in_array} from "utils/func";
// import {rolesModerator} from "utils/config";


const API_URL = 'users.php';
const ClientsSortStorageName = 'clients_sort';


export const doDeleteClient = (user, client, actions, apiActionProcess, callback) => {

    console.log('delete ', client);
    toastr.confirm(
        `Вы точно хотите удалить клиента "${client.company}"?`,
        {
            okText: 'Да',
            cancelText: 'Нет',
            onOk: async() => {


                if (!apiActionProcess) {
                    actions.showLoading();
                    apiActionProcess = true;

                    const res = await Api.post(API_URL, {
                        action: 'deleteClient',
                        user: {id: user.id},
                        id: client.id
                    });

                    apiAction(res, (response) => {
                        if (typeof callback == 'function')
                            callback(response);

                    }, () => {
                        apiActionProcess = false;
                        actions.hideLoading();
                    });
                    apiActionProcess = false;
                }
            },

        }
    );
}


const ClientsContainer = props => {

    const {user, actions} = props;
    const [clients, setClients] = useState([]);
    // const [sort, setSort] = useState(Storage.get(ClientsSortStorageName, {field : 'company', asc: true}, true));
    const [sort, setSort] = useState({field : 'company', asc: true});

    let apiActionProcess = false;
    const getData = async (isSubscribe = true, calDates = null, search = '') => {
        if (!isSubscribe)
            return;

        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;

            const res = await Api.post(API_URL, {
                action: 'getClients',
                user: {id: user.id},
            });

            apiAction(res, (response) => {
                console.dir(response);
                setClients(sortClients(response.clients, sort));

            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            });
            apiActionProcess = false;
        }
    };

    useEffect(() => {
            let isSubscribe = true;

            getData(isSubscribe, null).then(null);

            return () => {
                isSubscribe = false;
            }
        },
        []
    );

    const deleteClient = (e, client) => {
        e.preventDefault();

        doDeleteClient(user, client, actions, apiActionProcess, (resp) => {
            let clientsNew = [];
            clients.map(_client => {

                if (_client.id != client.id)
                    clientsNew.push(_client);

                return null;
            });
            setClients(sortClients(clientsNew, sort));
        });
    };


    const doSort = (field) => {
        // console.log('sortBy', field, sort);
        let newSort = {
            field: field,
            asc: sort.field == field ? !sort.asc : sort.asc
        };
        Storage.set(ClientsSortStorageName, newSort);
        setSort(newSort);
        setClients(sortClients(clients, newSort));
    }
    const sortClients = (clientsWas, _sort) => {
        let clientsSorted = [...clientsWas];
        let sortField = _sort.field;
        console.log(sort);

        if (sortField == 'company') {
            sortField = 'companyPure';
            clientsSorted.map((cl, i) => {
               let companyPure = clearCompanyName(cl.company);

                console.log(cl.company, `>${companyPure}<`);
                clientsSorted[i].companyPure = companyPure;
            });
        }

        clientsSorted.sort((a, b) => {
            // console.log(a[sort.field], b[sort.field]);
            if (a[sortField] > b[sortField])
                return _sort.asc ? 1 : -1;
            if (a[sortField] < b[sortField])
                return _sort.asc ? -1 : 1;

            return 0;
        });
        console.log(clientsSorted);

        return clientsSorted;
    }

    return (
        <>
            <ContentHeader
                parent={[{url: '/user', name: "Пользователи"}]}
                title={'Клиенты'}
            />
            <div className="flx -sb -alc">
                <div className="">
                    Всего: {clients.length}
                </div>
                <Button
                    type={"save"}
                    title={"Добавить клиента"}
                    ico={"address-book-o"}
                    href={"/user/clients/0"}
                />
            </div>
            {
                clients.length ?
                    <ClientsList
                        clients={clients}
                        user={user}
                        onDelete={deleteClient}
                        sort={sort}
                        onSort={doSort}
                    />
                    : null
            }
        </>
    )

};

const mapStateToProps = store => ({
    user: store.user
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        showLoading, hideLoading
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientsContainer);

