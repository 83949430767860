import React from 'react';
import TablesContainer from "containers/tables/TablesContainer";
import {Route, Switch} from "react-router-dom";
import SheetsContainer from "../containers/sheets/SheetsContainer";
import Page404 from "../components/Page404";

const TablesRouter = props => {

    return (
        <Switch>
            <Route
                path="/tables/:tableName"
            >
                <TablesContainer {...props} />
            </Route>
            <Route component={Page404} />
        </Switch>
    )

};

export default TablesRouter;