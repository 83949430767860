import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router-dom";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {urlGetParams} from "utils/func";
import Api, {apiAction} from "utils/Api";

/**
 * Получение форм с сайта,
 * открывается по адресу http://sklad-domain.tld/forms/get/?url=https://shop-name.ru&id=123
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormsGet = props => {

	const {user, actions, history} = props;

	const GET = urlGetParams(useLocation());


	let apiActionProcess = false;
	const sendRequest = async (isSubscribe = true) => {

		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('forms.php', {
				...GET,
				action: 'getFormFromSite',
				user: user,
				// url: GET.url,
				// id: GET.id
			});

			apiAction(res, (data) => {

				if (data.id && parseInt(data.id))
					history.push('/forms/show/' + data.id);

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});


		}
	};
	useEffect(() => {
			let isSubscribe = true;
			sendRequest(isSubscribe).then(r => {});


			return () => {
				isSubscribe = false;
			}
		},
		[GET]
	);

	return (
		<>
			<h1>Получение данных форм...</h1>
		</>
	);

};
const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(FormsGet);