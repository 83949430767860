import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import TopbarUserinfo from "components/user/TopbarUserinfo";
import {NavLink} from "react-router-dom";
import {bindActionCreators} from "redux";
import {sideBarToggle} from "actions/GUIActions";
import {toastr} from "react-redux-toastr";
import {useScript} from "utils/func";
import {SKIP_SEARCH_ACTION_NAME} from "utils/config";
import cn from "classnames";
import UnloadCalc from "components/UnloadCalc";
import {logger} from "redux-logger/src";


const TopbarContainer = (props) => {

    const {
        GUI, user, actions,
        mobileSearchShow = false
    } = props;

    const [searchString, setSearchString] = useState('');
    // const [popupWin, setPopupWin] = useState(null);

    const [newOrdersCount, setNewOrdersCount] = useState(0);
    const [newFormsCount, setNewFormsCount] = useState(0);
    const [showUnloadCalc, setShowUnloadCalc] = useState(false);

    useScript('https://dostavka.sbl.su/api/delivery.js?comp=0&startCt=Москва&startCntr=RU&btn=no&dopLathing=1&dopInsure=1&startPick=1', 'dcsbl');


    // const popupWinContentWidget = (
    // 	<div className="popup-win">
    // 		<Win
    // 			header={""}
    // 			onClose={e => setPopupWin(null)}
    // 			// footer={null}
    // 			// winClass={"half"}
    // 		>
    // 			<div className="ec-delivery"></div>
    // 			<Script
    // 				url="https://dostavka.sbl.su/api/delivery.js?comp=0&startCt=Москва&startCntr=RU&btn=no&dopLathing=1&dopInsure=1&innerDeliv=1&startPick=1"
    // 				onError={() => console.log('onError')}
    // 				onLoad={() => console.log('onLoad')}
    // 			/>
    //
    // 		</Win>
    // 	</div>
    // );

    useEffect(() => {
        // console.log(GUI.socket);
        if (GUI.socket) {
            GUI.socket.on('serverHasNewData', res => {
                // console.clear();
                // console.log('serverHasNewData');


                if (res.newOrdersCount !== undefined)
                    setNewOrdersCount(res.newOrdersCount);
                if (res.newFormsCount !== undefined)
                    setNewFormsCount(res.newFormsCount);
            });
        }
    });

    /**
     * 01/04/2024  - в поиск добавлен фильтр
     */
    let searchHidden = !GUI.searchAction;
    // console.log(typeof GUI.searchAction);
    let searchAction = GUI.searchAction;
    let searchFilter = null;
    if (GUI.searchAction !== null && typeof GUI.searchAction === 'object') {
        const {action: _searchAction, filter: _searchFilter} = GUI.searchAction;

        searchAction = _searchAction;
        searchFilter = _searchFilter;
        // console.log(searchFilter);
    }


    const onSearch = (skip = false) => {

        if (!skip && searchString.trim() == '') {
            toastr.error('Ошибка', 'Введен пустой поисковый запрос');
            return;
        }

        if (!skip && searchString.length < 3) {
            toastr.error('Ошибка', 'Введен слишком короткий поисковый запрос, минимум 3 знака');
            return;
        }
        if (searchAction)
            searchAction(skip ? SKIP_SEARCH_ACTION_NAME : searchString.trim());
    };
    const onEnterPressedCatcher = e => {
        if (e.key.toLowerCase() === 'enter')
            onSearch();
    };


    let notification = [];
    if (user && parseInt(user.divId) === 4) {
        if (newOrdersCount)
            notification.push(
                <NavLink
                    key={"not-orders"}
                    to="/orders"
                    title={"Есть новые заказы"}>
                    <i className="fa fa-shopping-basket"></i>
                    <div className="count">
                        {newOrdersCount}
                    </div>
                </NavLink>
            );

        if (newFormsCount)
            notification.push(
                <NavLink
                    key={"not-forms"}
                    to="/forms"
                    title={"Есть непросмотренные формы"}>
                    <i className="fa fa-comments-o"></i>
                    <div className="count">
                        {newFormsCount}
                    </div>
                </NavLink>
            );
    }



    return (
        <div className={"top-bar flx -alc no-print " + (!GUI.sideBarOpen ? 'full' : '')}>

            <div className="tb-left flx -alc">
                <div
                    className="menu-bars"
                    onClick={() => actions.sideBarToggle(!props.GUI.sideBarOpen)}
                >
                    <i className="fa fa-bars"></i>
                </div>

                <ul className="tb-menu flx -alc _z">
                    {
                        (user.settings && user.settings.mkad_url && user.settings.mkad_url !== '') ?
                            <li>
                                <a href={user.settings.mkad_url} target={"_blank"}>
                                    <i className={"fa fa-map-pin"}></i> Расчет расстояния от МКАД
                                </a>
                            </li> : null
                    }


                    <li>
                        <a href={"#"} className={"call-ec-widget"}>
                            <i className={"fa fa-usd"}></i> Расчет стоимости веса
                        </a>
                    </li>
                    <li>
                        <a href={"#"}
                           className={""}
                           onClick={(e) => {
                               e.preventDefault();
                               setShowUnloadCalc(!showUnloadCalc)
                           }}
                        >
                            <i className={"fa fa-share-square"}></i> Расчет разгрузки
                        </a>
                    </li>


                    {/*<li title={"Время приезда машины с производства"}>*/}
                    {/*	<i className="fa fa-truck fa-flip-horizontal"></i>*/}
                    {/*	{user.settings.truckTime && user.settings.truckTime}*/}
                    {/*	<NavLink to="/settings/general/#truckTime" className={"show-hover"} style={{marginLeft: '10px'}}><i className={"fa fa-edit"}></i></NavLink>*/}

                    {/*</li>*/}

                </ul>
            </div>
            {/*mobileSearchShow*/}
            <div className={cn("tb-srch flx", {hidden: searchHidden, showMobile: mobileSearchShow})}>
                {
                    searchFilter
                    ? <div className={"tb-srch-filter"}>
                            {searchFilter}
                    </div>
                        : null
                }
                <div className={"tb-srch-input"}>
                    <input
                        type="text"
                        name="srch"
                        placeholder="Поиск"
                        value={searchString}
                        onChange={e => setSearchString(e.target.value)}
                        onKeyDown={e => onEnterPressedCatcher(e)}
                        // onFocus={e => {
                        //     setSearchString('');
                        // }}
                    />
                </div>

                <button
                    onClick={e => onSearch()}
                >
                    <i className="fa fa-search"></i>
                </button>
                <i
                    className="fa fa-close"
                    onClick={e => {
                        setSearchString('');
                        onSearch(true);
                    }}
                ></i>
            </div>
            <div className="tb-right flx -e -alc">
                <div className="tb-notifs flx">
                    {notification}
                </div>
                <TopbarUserinfo/>
            </div>

            {
                showUnloadCalc ?
                    <UnloadCalc
                    onClose={() => setShowUnloadCalc(false)}
                    user={user}
                    />
                    : null
            }
        </div>
    )
};

const mapStateToProps = store => ({
    user: store.user,
    GUI: store.GUI
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        sideBarToggle
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TopbarContainer);
