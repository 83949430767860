import React, {Component, Fragment} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {io} from "socket.io-client";
// import Cookies from "universal-cookie";
import {toastr} from "react-redux-toastr";
import * as Containers from "containers";
import {Storage} from "utils/Storage";
import {SESSION_USER, USER_LOGIN, userAuth} from "actions/UserActions";
import {sideBarToggle, setSocket, setMenuCounters} from "actions/GUIActions";
import {APP_TITLE, SOCKET_URL, MENU, MENU_TITLE, TASK_MANAGER_MENU_COUNTER_REFRESH_TIME} from "utils/config";
// import {connectSocket} from "utils/func";
import * as Routers from "routers/index";
import Api, {apiAction} from "utils/Api";
import {in_array} from "utils/func";




class Routing extends Component {

	constructor(props) {
		super(props);

		this.backgroundFetch = this.backgroundFetch.bind(this);
		this.clearBackgroundFetchIntervals = this.clearBackgroundFetchIntervals.bind(this);
		this.refreshMenuCounters = this.refreshMenuCounters.bind(this);


		// this.sessionUser = Storage.session_get(SESSION_USER, null, true);
		this.sessionUser = Storage.get(SESSION_USER, null, true);
		// console.log('Routing constructor', this.sessionUser);

		if (this.sessionUser) {
			// this.sessionUser.role='user';
			// console.log('auth from sess');
			this.props.actions.userAuth(USER_LOGIN, this.sessionUser);

		}



	};



	async componentDidMount() {
		document.title = APP_TITLE + ' - ' + MENU_TITLE.tasks.many;
		// console.log('Routing componentDidMount');

		await this.backgroundFetch();

	}

	// static getDerivedStateFromProps(props, state) {
	// 	console.log(props);
	// }
	UNSAFE_componentWillReceiveProps(nextProps) {
		// console.log('componentWillReceiveProps');

		// console.log('prop suser', this.props.user == null);
		// console.log('nextProps user', nextProps.user == null);

		if (this.props.user === null && nextProps.user !== null && this.props.GUI.socket === null)
			this.connectSocket(nextProps.user);

		if (this.props.user === null && nextProps.user !== null)
			this.backgroundFetch(nextProps.user).then();
		else if(this.props.user == null && nextProps.user === null)
			this.clearBackgroundFetchIntervals();

	}
	connectSocket(user) {

		const {actions} = this.props;


		const RECONNECTION_ATTEMPTS = 2;
		let attempts = 0;

		if (SOCKET_URL) {

			const socket = io(SOCKET_URL, {
				reconnectionDelay: 30000,
				reconnectionDelayMax: 50000,
				reconnectionAttempts: RECONNECTION_ATTEMPTS,
				query: {
					userId: user ? user.id : 0,
					url: window.location.pathname,
					browserWidth: window.outerWidth,
					browserHeight: window.outerHeight,
				}
			});

			socket.on('connect', () => {
				actions.setSocket(socket);
				console.log('Подключено к удаленному серверу', SOCKET_URL);
				// toastr.success('Подключено к удаленному серверу ' + SOCKET_URL);
			});
			socket.on("connect_error", () => {
				console.error('Невозможно подключиться к удаленному серверу', SOCKET_URL);
				attempts++;

				if (user && user.role == 'admin' && attempts >= RECONNECTION_ATTEMPTS) {
					toastr.error(`Невозможно подключиться к удаленному серверу ${SOCKET_URL}. Система работает без обновлений "на лету"`, '', {timeOut: 0});

					socket.disconnect();
				}
			});

			socket.on('serverTrigger', res => {
				console.log('%cserverTrigger', 'background: #000; color: #fff');
				console.dir(res);
				if (res.action === 'showMessage' && res.msg && res.msg !== '') {
					switch (res.type) {
						case "error":
							toastr.error(res.msg);
							break;
						default:
							toastr.info(res.msg);
							break;
					}
				}
			});

		}
		else
			console.log('connectSocket inside routing DISABLED');
	}
	backgroundFetchIntervals = {
		taskManager: null
	};
	componentWillUnmount() {
		for (let interval in this.backgroundFetchIntervals) {
			clearInterval(this.backgroundFetchIntervals[interval]);
		}
	}

	async backgroundFetch(_user) {

		let {user} = this.props;
		if ((user == null && _user != null) )
			user = _user;

		if (user != null) {
			// console.log('backgroundFetch start ---------->');
			// console.log(window.location.pathname);
			//если на странице таскменеджер, не запускаем
			if (MENU_TITLE.taskManager.roles && in_array(user.role, MENU_TITLE.taskManager.roles)) {
				if (window.location.pathname !== '/task_manager')
					this.refreshMenuCounters(user).then(null);
				this.backgroundFetchIntervals.taskManager = setInterval(async () => {
					await this.refreshMenuCounters(user);
				}, TASK_MANAGER_MENU_COUNTER_REFRESH_TIME);
			}
		}


	}


	render() {
		const {user} = this.props;


		for (const i in MENU) {
			if (window.location.pathname.indexOf(MENU[i].url) != -1)
				document.title = APP_TITLE + ' - ' + MENU[i].name;
		}

		let content = null;



		if (!user)
			content = (
				<Router>
					<Route component={Routers.UserRouter} />
				</Router>
			);
		else {





			content = (
				<Router>
					<Containers.ContentContainer/>
				</Router>
			);
		}

		return (
			<Fragment>{content}</Fragment>
		);
	}


	async refreshMenuCounters(_user = null) {
		// console.log('refreshMenuCounters', Date.now());

		// console.log(_user);
		if (_user == null) {
			this.clearBackgroundFetchIntervals();
			return;
		}

		// this.props.actions.setMenuCounters('task_manager', (Date.now() + '').substring(11, 13));
		apiAction(await Api.post('taskmanager.php', {
			action: 'getAll',
			user: _user,
			getCounters: true
		}), (response) => {
			this.props.actions.setMenuCounters('task_manager', response.total);
		});
	};

	clearBackgroundFetchIntervals() {
		for (let interval in this.backgroundFetchIntervals) {
			clearInterval(this.backgroundFetchIntervals[interval]);
		}
	}
}
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		userAuth, sideBarToggle, setSocket, setMenuCounters
	}, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Routing);
