import React, {createRef, useEffect, useRef, useState} from 'react';
import {ContentHeader, Win, Button, FormItem} from "components/_common";
import cn from 'classnames';
import * as uniqid from "uniqid";
import {toastr} from "react-redux-toastr";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {MESSAGES} from "utils/config";
import DocsUploadBox from "components/docs/DocsUploadBox";
import {downloadFile} from "utils/func";
import DocsListFiles from "components/docs/DocsListFiles";

const Ustav = props => {

	const {
		user,
		GUI,
		edit, settings,
		doSaveSettings,
		savedSettings, setSavedSettings,
		setDeleteSettings,
		files4upload4settings, setFiles4upload4settings,
		files,
		deleteFile
	} = props;
	// console.dir(files);

	const {ustavTabs = []} = settings;

	const [tabs, setTabs] = useState(ustavTabs);

	const activeTabIdRef = useRef(tabs.length ? tabs[0].id : null);


	const [popupWin, setPopupWin] = useState(null);
	const [editorText, setEditorText] = useState(null);
	const [tabTextList, setTabTextList] = useState({});

	//для сменв в аплодере при смене табов
	const [files4uploadLocal, setFiles4uploadLocal] = useState([]);

	let ufsn = `ustav-${activeTabIdRef.current}-files`;
	// console.dir(files);
	// превью файлов в активной вкладке
	const [filesCurrent, setFilesCurrent] = useState([]);
	// const [filesCurrent, setFilesCurrent] = useState(files[ufsn]
	// 	? files[ufsn]
	// 	: []);


	useEffect(() => {
		// console.dir(files);
		let texts = {};
		// let _file4upload4tabs = {};
		// Object.keys(settings).map(key => {
		// 	if (/ustav-/i.test(key)) {
		//
		// 		const [, id, files] = key.split('-');
		// 		console.log(files);
		// 		texts[id] = settings[key];
		// 		_file4upload4tabs[id] = [];
		// 	}
		// });

		ustavTabs.map((tab, i) => {
			let setName = `ustav-${tab.id}`;

			if (settings[setName])
				texts[tab.id] = settings[setName];

		});

		setTabTextList(texts);
		// setFiles4upload4tabs(_file4upload4tabs);
		// let fk = Object.keys(texts)[0];
		// setEditorText(texts[fk]);
		setEditorText(texts[activeTabIdRef.current]);

		let ufsn = `ustav-${activeTabIdRef.current}-files`;

		setFiles4uploadLocal(files4upload4settings[ufsn]
			? files4upload4settings[ufsn]
			: []);

		// console.dir(files);
		setFilesCurrent(files[ufsn]
			? files[ufsn]
			: []);

		return () => {
			setPopupWin(null);
			setTabTextList({});
		}
	}, [files, settings]);

	const editTabNameRef = createRef();
	const editTabNumRef = createRef();

	const saveTab = (tab) => {
		const isNewTab = tab === null;
		const num = parseInt(editTabNumRef.current.value.trim());
		const editTabId = !isNewTab ? tab.id : uniqid();
		const name = editTabNameRef.current.value.trim();
		if (name === '') {
			toastr.error('Ошибка', 'Впишите наименование вкладки');
			return;
		}
		const newTabs = [];
		tabs.map((t, k) => {
			let curTab = {...t, num: k};
			if (curTab.id === editTabId) {
				curTab.name = name;
				curTab.num = num;
			}
			newTabs.push(curTab);
		});
		if (isNewTab)
			newTabs.push({
				id: editTabId,
				name: name,
				num: num
			});
		//сортируем по номеру
		let resTabs = newTabs.sort((a, b) => {
			return a.num >= b.num ? 1 : -1;

		});
		//убираем  сортировочный признак
		resTabs.map(t => delete t.num);
		console.dir(resTabs);

		setTabs(resTabs);
		setPopupWin(null);
		setSavedSettings({
			...savedSettings,
			ustavTabs: resTabs
		});
	};

	const tabEditor = (header, num, tab) => {
		return <div className="popup-win">
			<Win
				header={header}
				onClose={e => setPopupWin(null)}
				footer={
					(
						<div className={"win-ftr-btns _tac"}>
							<Button type="apply" onClick={e => saveTab(tab)}/>
						</div>
					)
				}
				winClass={"half"}
			>
				<FormItem
					name={"txt"}
					label={"Наименование"}
					fieldType={"text"}
					defVal={tab ? tab.name : ''}
					reff={editTabNameRef}
				/>

				<FormItem
					name={"txt"}
					label={"Порядковый номер"}
					tip={'Целое число'}
					fieldType={"text"}
					defVal={num}
					reff={editTabNumRef}
				/>
			</Win>
		</div>;
	};

	const addTab = () => {
		setPopupWin(
			tabEditor(
				"Добавление вкладки",
				tabs.length,
				null
			)
		);
	};

	const onTabEdit = (e, tab, num) => {
		e.preventDefault();
		setPopupWin(
			tabEditor(
				"Редактирование вкладки",
				num,
				tab
			)
		);
	};
	const onTabDelete = (e, tab) => {
		e.preventDefault();
		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: () => {
					let resTabs = [];
					tabs.map(t => {
						if (t.id !== tab.id)
							resTabs.push(t);
					});
					setTabs(resTabs);
					setSavedSettings({
						...savedSettings,
						ustavTabs: resTabs
					});
					const tt = {...tabTextList};
					delete tt[tab.id];
					setTabTextList(tt);

					setDeleteSettings(['ustav-' + tab.id]);
				}
			}
		);

	};


	const tabContent = (tab, num) => {

		const actionsMenu = (
			<div className="actions -left">
				<i className="fa fa-cogs"></i>
				<div>
					<a href={"#"} onClick={e => onTabEdit(e, tab, num)}>
						<i className="fa fa-edit"></i> Редактировать
					</a>
					<a href={"#"} onClick={e => onTabDelete(e, tab)}>
						<i className="fa fa-close"></i> Удалить
					</a>
				</div>
			</div>
		);

		return (
			<div
				key={"tabc-" + tab.id}
				className={cn("tab", {active: activeTabIdRef.current === tab.id})}>
				{edit ? actionsMenu : null}
				<div
					className="-n"
					onClick={e => {
						// console.clear();
						// console.log(tab.id, tabTextList[tab.id].substring(0, 100));
						setEditorText(
							tabTextList[tab.id] ? tabTextList[tab.id] : ''
						);
						activeTabIdRef.current = tab.id;

						let usnf = `ustav-${tab.id}-files`;

						setFiles4uploadLocal(files4upload4settings[usnf] ? files4upload4settings[usnf] : []);

						setFilesCurrent(files[usnf] ? files[usnf] : []);

					}}
				>
					{tab.name}
					{/*- {tab.id}*/}
				</div>
			</div>
		);
	};

	const onEditorChange = (text) => {
		let t = {...tabTextList};
		t[activeTabIdRef.current] = text;
		setTabTextList(t);
	};
	const saveAction = () => {
		let saved = {...savedSettings};

		Object.keys(tabTextList).map(id => {
			if (settings['ustav-' + id] != tabTextList[id])
				saved['ustav-' + id] = tabTextList[id];
		});

		// console.dir(saved);
		doSaveSettings(saved);
	};


	let content = [];

	// content.push(
	// 	<div key={"12312313434234234sdfs"}>
	// 		{activeTabIdRef.current}
	// 	</div>
	// );

	if (!edit) {
		content.push(<div key="txt" dangerouslySetInnerHTML={{__html: editorText}}/>);
	} else if (edit && editorText !== null) {

		content.push(
			<CKEditor
				key={"txt-ditor"}
				editor={ClassicEditor}
				config={{
					toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload',
						'|', 'undo', 'redo']
				}}
				data={editorText}
				onChange={(event, editor) => {
					onEditorChange(editor.getData());
				}}
			/>);

		content.push(
			<div
				key={"ustav-files-upload"}
				className="files-upload-wrp mt20"
			>

				<DocsUploadBox
					key={"ustav-files-upload"}
					showOnlyUploadBox={true}
					settings={settings}
					filesList={files4uploadLocal}
					setFilesList={(_files) => {
						setFiles4upload4settings((prev) => ({
							...prev,
							[`ustav-${activeTabIdRef.current}-files`]: _files,
						}));
						setFiles4uploadLocal(_files);

					}}
					GUI={GUI}
					formRef={null}
				/>
			</div>
		);
	}

	content.push(
		<div
			key={"ustav-files"}
			className="mt20">
			<DocsListFiles
				user={user}
				files={filesCurrent}
				onDelete={!edit ? null : (fileId) => {
					console.log('try del ' + fileId);
					deleteFile(fileId, `ustav-${activeTabIdRef.current}-files`)
				}}
				onDownload={downloadFile}
				onlyThumb={false}
			/>
		</div>
	);


	const panel = <div className="panel-top mt20 no-print _tar">
		<Button type="apply" onClick={e => {
			saveAction();
		}}/>
	</div>;

	return (
		<>
			<ContentHeader
				title={"Устав компании"}
			/>
			{edit ? panel : null}
			<div className="row mt20">
				<div className="col-3 ustav-tabs">
					{tabs.map((tab, k) => {
						return tabContent(tab, k);
					})}
					{edit ? <div className="ustav-tab-add">
						<span onClick={e => addTab()}>
							добавить
						</span>
					</div> : null}

				</div>
				<div className="col-2-3 ustav-content">
					{content}
				</div>

			</div>

			{popupWin}
		</>
	)

};

export default Ustav;
