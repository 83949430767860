import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {Button, ContentHeader} from "components/_common";
import {MENU_TITLE, rolesModerator, MESSAGES} from "utils/config";
import {setMenuCounters} from "actions/GUIActions";
import {in_array} from "utils/func";
import TaskManagerList from "components/task_manager/TaskManagerList";
import $ from "jquery";
import {toastr} from "react-redux-toastr";


const TASK_MANAGER_CONFIG = {
	todo: {
		title: 'Задания',
		// ico: 'exclamation-triangle'
		ico: 'sticky-note-o'
	},
	installs: {
		title: 'Монтажи',
		ico: 'puzzle-piece'
	},
	tasks: {
		title: MENU_TITLE.tasks.many,
		ico: 'tasks'
	},
	orders: {
		title: MENU_TITLE.orders.many,
		ico: 'shopping-basket'
	},
	forms: {
		title: MENU_TITLE.forms.many,
		ico: 'comments-o'
	}
}

const API_URL = 'taskmanager.php';

const TaskManager = props => {

	const {user, actions} = props;

	let tmDataInit = {};
	for (let i of Object.keys(TASK_MANAGER_CONFIG)) {
		tmDataInit[i] = [];
	}
	const [tmData, setTmData] = useState(tmDataInit);


	let apiActionProcess = false;
	const getData = async (isSubscribe = true) => {
		console.clear();
		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			apiAction(await Api.post(API_URL, {
				action: 'getAll',
				userId: user.id,
			}), (response) => {

				// console.dir(response.forms);
				let _tmData = {};
				for (let tmType in TASK_MANAGER_CONFIG) {
					if (response[tmType])
						_tmData[tmType] = response[tmType];
				}
				setTmData(_tmData);
				actions.setMenuCounters('task_manager', response.total);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});


		}
	};
	console.log($(window).height(), $('.top-bar').height(), $('.content-header').outerHeight(), $('.panel-top-taskmanager').outerHeight());
	$('.task-manager-list').height(
		$(window).height()
		- 80 /*padding для .content*/
		- $('.top-bar').height()
		- $('.content-header').outerHeight() - 0
		- $('.panel-top-taskmanager').outerHeight()
	);
	// $('.task-manager-list').mCustomScrollbar({
	// 	axis: "y",
	// 	theme: "minimal",
	// 	scrollInertia: 20,
	// });

	const setStatus = async (id, type,status) => {
		console.clear();
		// console.dir(tmData);
		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: async () => {

					if (!apiActionProcess) {
						actions.showLoading();
						apiActionProcess = true;

						apiAction(await Api.post(API_URL, {
							action: 'setStatus',
							userId: user.id,
							id: id,
							type: type,
							status:  status
						}), (resp) => {

							if (resp.changed) {
								let _tmData = {};
								let total = 0;
								for (let tmType in tmData) {

									if (!_tmData.hasOwnProperty(tmType))
										_tmData[tmType] = [];

									if (tmType == type) {
										tmData[tmType].map(item => {
											if (item.id != id)
												_tmData[tmType].push(item);
										});
									}
									else {
										_tmData[tmType] = tmData[tmType];

									}
									total += _tmData[tmType].length;
								}
								// console.log(total);
								// console.dir(_tmData);
								setTmData(_tmData);
								actions.setMenuCounters('task_manager', total);
							}

						}, () => {
							apiActionProcess = false;
							actions.hideLoading();
						});


					}
				},

			}
		);
	};

	useEffect(() => {
			let isSubscribe = true;
			getData(isSubscribe).then(null);


			return () => {
				isSubscribe = false;
			}
		},
		[]
	);

	const panel = (
		<div className="panel-top panel-top-taskmanager mt20m no-print flx -sb -alc">
			<div style={{color: '#ccc'}}>
				SHIFT+Scroll - горизонтальное скролирование
			</div>
			<Button
				href={`${MENU_TITLE.taskManager.url}/edit/0`}
				type={"save"}
				title={"Создать задание"}
				ico={"plus-circle"}
			/>
		</div>
	);

	return (
		<>
			<ContentHeader
				title={MENU_TITLE.taskManager.many}
				showDates={false}
			/>
			{
				in_array(user.role, ['manager', ...rolesModerator])
					? panel
					: null
			}

			<div className="task-manager-list mt20">
				{
					Object.keys(tmData).map(tmType => {
						return <TaskManagerList
							key={`tm-list-${tmType}`}
							type={tmType}
							config={TASK_MANAGER_CONFIG[tmType]}
							data={tmData[tmType]}
							user={user}
							setStatus={setStatus}
						/>;
					})
				}
			</div>

		</>
	)

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading,
		setMenuCounters
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TaskManager);

