import React, {useState, useEffect} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Tabs} from "components/_common";
import {setCalendarSelectDateAction, setSearchAction} from "actions/GUIActions";
import {MENU_TITLE} from "utils/config";
import {in_array, isModerator, isUser} from "utils/func";
// import Bills from "components/sheets/Bills";
import {useParams} from "react-router-dom";
import Page404 from "components/Page404";
import SheetsTable from "components/sheets/SheetsTable";
import {SheetsBgr, SheetsSelectedCoordInit} from "components/sheets/utils";


const API_URL = 'sheets.php';



const SheetsContainer = props => {

    const {sheetName = ''} = useParams();
    // console.log(sheetName);

    const {user, GUI, actions} = props;
    const [data, setData] = useState({});
    const [selectedCellCoord, setSelectedCellCoord] = useState(SheetsSelectedCoordInit);

    let apiActionProcess = false;
    const getData = async (isSubscribe = true, calDates = null, search = '') => {
        if (!isSubscribe)
            return;

        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;

            const res = await Api.post(API_URL, {
                action: 'get',
                userId: user.id,
                sheetName: sheetName,
                dates: calDates ? calDates : GUI.calendarDates,
                search: search
            });

            apiAction(res, (response) => {

                // console.dir(response.data);
                setData(response);

            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            });
            apiActionProcess = false;
        }
    };
    const onCalendarSelectDate = (dates) => {
        getData(true, dates).then(null);
    };
    const doSearch = str => {
        getData(true, [], str).then(null);
    };
    useEffect(() => {
            let isSubscribe = true;
            // console.clear();
            // console.log('FormContainer useEffect', code);

            getData(isSubscribe, null).then(null);
            actions.setCalendarSelectDateAction(onCalendarSelectDate);
            actions.setSearchAction(doSearch);

            if (GUI.socket) {
                GUI.socket.on('sheetsRefresh', res => {
                    console.log('sheetsRefresh');
                    // console.dir(res);
                    if (res.sheetName == sheetName && res.userId != user.id) {
                        // console.log('DO refresh');
                        getData(isSubscribe, null).then(null);
                    }
                });
            }
            else
                console.log('no soket');
            return () => {
                isSubscribe = false;
                //отключаем функцию обработчик на календаре
                actions.setCalendarSelectDateAction(null);
                actions.setSearchAction(null);

            }
        },
        []
    );

    const save = async (saveData) => {
        // console.clear();
        // console.log('save');
        // console.dir(saveData.data);

        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;

            const res = await Api.post(API_URL, {
                action: 'save',
                userId: user.id,
                sheetName: sheetName,
                data: saveData.data
            });

            apiAction(res, (resp) => {
                console.log(resp);
                setData(saveData);
            }, () => {
                apiActionProcess = false;
                actions.hideLoading();

                if (GUI.socket) {
                    GUI.socket.emit('sheetsChanged', {
                        userId: user.id,
                        sheetName: sheetName,
                    });
                }
            });
            apiActionProcess = false;
        }
    };

    const setUndefinedGrid = (tabId, rowNumber, colNumber) => {
        let newData = {...data};
        // console.log(newData.data);
        if (newData.data == null)
            newData.data = {};
        if (newData.data[tabId] == undefined)
            newData.data[tabId] = {};
        if (newData.data[tabId][rowNumber] == undefined)
            newData.data[tabId][rowNumber] = {};
        if (newData.data[tabId][rowNumber][colNumber] == undefined)
            newData.data[tabId][rowNumber][colNumber] = {
                value: '',
                bgr: '',
                edit: true
            };

        return newData;
    }

    // в принципе можно перенести  текущий tabId  в  state
    const onCellChange = async (tabId, rowNumber, colNumber, value=null) => {
        // console.clear();
        console.log(`onCellChange tabId=${tabId} row=${rowNumber} col=${colNumber}:`, value);

        let newData = setUndefinedGrid(tabId, rowNumber, colNumber);

        newData.data[tabId] = {
            ...newData.data[tabId],
            [rowNumber]: {
                ...newData.data[tabId][rowNumber],
                [colNumber]: {
                    ...newData.data[tabId][rowNumber][colNumber],
                    value: value.trim(),
                }
            }
        };


        await save(newData);
    }

    const setBackground = async (clr) => {
        console.log(clr, selectedCellCoord);
        // let newData = setUndefinedGrid(tabId, rowNumber, colNumber);
        //одна ячейка
        if (selectedCellCoord[3] === false) {
            //
        }
        //целый ряд
        else {
            let tabId = selectedCellCoord[0];
            let rowNum = selectedCellCoord[1];
            let colNumMax = data.columns.length;//selectedCellCoord[2];
            let newData = setUndefinedGrid(tabId, rowNum, 0);
            for (let i = 0; i < colNumMax; i++) {

                if (newData.data[tabId][rowNum][i] == undefined)
                    newData.data[tabId][rowNum][i] = {
                        value: '',
                        bgr: '',
                        edit: true
                    };

                newData.data[tabId][rowNum][i] = {
                    ...newData.data[tabId][rowNum][i],
                    bgr: clr === SheetsBgr.white ? '' : clr
                };
            }
            setSelectedCellCoord(SheetsSelectedCoordInit);
            await save(newData);
        }
    };


    const userIsModerator = isModerator(user);
    let isModerOrCassir = userIsModerator || isUser(user);


    let tabsData = [];
    if (data.tabs && data.tabs.length) {

        data.tabs.map(tab => {
            if (isModerOrCassir || in_array(user.divId, tab.divisions))
                tabsData.push({
                    title: tab.name,
                    // content: sheetContent(tab.id, data)
                    content: <SheetsTable
                        sheetName={sheetName}
                        user={user}
                        tabId={tab.id}
                        columns={data.columns}
                        editable={true}

                        data={data}
                        onCellChange={onCellChange}

                        selectedCellCoord={selectedCellCoord}
                        onSelectedCellCoord={setSelectedCellCoord}
                    />
                });
        });

    }
    return (
        <>
            <ContentHeader
                title={MENU_TITLE.sheets.many + (sheetName ? ' / ' + MENU_TITLE.sheets[sheetName].many : '')}
                showDates={true}
                datesAction={null}
            />
            {
                isModerOrCassir
                    ? <div className={"sheets-colors _pz"}>
                        <p>Выделить цветом фон</p>
                        {
                            Object.values(SheetsBgr).map(clr => {
                                return (<div
                                    style={{backgroundColor: `#${clr}`}}
                                    key={`sc-${clr}`}
                                    onClick={() => setBackground(clr)}
                                ></div>);
                            })
                        }
                    </div>
                    : null
            }

            <Tabs
                data={tabsData}
                // onChange={tabIndex => {
                //     console.log('change tab', tabIndex);
                // }}
            />
        </>
    )

};
const mapStateToProps = store => ({
    user: store.user,
    GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        showLoading, hideLoading, setCalendarSelectDateAction, setSearchAction
        //setSearchAction
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(SheetsContainer);
