import React from 'react';
import {NavLink} from "react-router-dom";
import date from "php-date";
import * as strtotime from "strtotime";
import {format_phone, get_noun} from "utils/func";
import {MENU_TITLE, TASK_MANAGER_STATUS, TASK_MANAGER_STATUS_TYPE} from "utils/config";

const TaskManagerListItem = (props) => {
	// console.dir(props);
	// console.log(props.type, TASK_MANAGER_STATUS_TYPE[props.type]);
	return (
		<div className={"tml-item" + (props.ownerIsIam ? ' -own' : '')}>
			<div className="top">
				<NavLink
					to={props.url}
				>
					{props.anchor}
				</NavLink>

				<div className="dt">
					{props.date}
				</div>
				<div className="">
					{props.topInfo}
				</div>
			</div>
			<div className="txt">
				{props.txt}
			</div>
			{
				props.managerName != ''
					? <div className="man">
						<span>Менеджер:</span> {props.managerName}
					</div>
					: null
			}
			{
				(props.todoUsers.length || props.filesInfo)
					? <div className="todo-users flx -sb -alc">
						<div className={"-files"}>
							{props.filesInfo}
						</div>
						<div className={"-users"}>
							{props.todoUsers}
						</div>
					</div>
					: null
			}

			{/*props.isManager*/}
			{
				(props.type !== 'todo' && TASK_MANAGER_STATUS_TYPE[props.type])
					? <div className="_tac mt20">
						<div
							className="tm-set-status"
							onClick={() => props.setStatus(props.id, TASK_MANAGER_STATUS_TYPE[props.type], TASK_MANAGER_STATUS.done.id)}
						>
							<i className={"fa fa-check-circle"} /> понятно

						</div>
					</div>
					: null
			}

		</div>
	);
}

export default TaskManagerListItem;

export const SetTaskManagerDataItem = (type, data, user, isAdmin) => {
	let result = {
		id: data.id,
		url: '',
		anchor: data.id,
		topInfo: '',
		date: '',
		txt: [],
		managerName: '',
		todoUsers: [],
		filesInfo: '',
		ownerIsIam: user.id == data.ownerId,
		type: type
	};


	switch (type) {
		case "installs":
			result.url = `/tasks/edit/${data.id}`;
			result.anchor = `№${data.id}`;
			result.date = date('d.m.Y', new Date(strtotime(data.ddateReal) * 1000));
			result.topInfo = data.todoInfo.name;

			if (data.userName && isAdmin)
				result.managerName = data.userName;

			result.txt.push(
				<p key={"tmli-company"}><b>Компания:</b> {data.company}</p>
			);
			if (data.installers.length) {
				result.txt.push(
					<p key={"tmli-installers-hdr"}><b>Монтажники:</b></p>
				);
				data.installers.map(installer => {
					result.txt.push(
						<p key={"tmli-installer-" + installer.id}>- {installer.name}</p>
					);
				});
			}
			break;
		case "orders":
			// if (data.id == 14725)
			// console.dir(data);
			result.url = `/orders/show/${data.id}`;
			result.anchor = `№${data.onum}`;
			result.date = date('d.m.Y H:i', new Date(data.odate * 1000));
			result.topInfo = data.site;

			if (data.userName)
				result.managerName = data.userName;

			result.txt.push(
				<p key={"tmli-o-name"}><b>Контактное лицо:</b> {data.cName}</p>
			);
			result.txt.push(
				<p key={"tmli-o-tel"}><b>Телефон:</b> {format_phone(data.cPhone)}</p>
			);
			break;
		case "forms":

			result.url = `/forms/show/${data.id}`;
			result.anchor = `№${data.fid}`;
			result.date = date('d.m.Y H:i', new Date(data.fdate * 1000));
			result.topInfo = data.site;
			if (data.userName)
				result.managerName = data.userName;

			if (data.name)
				result.txt.push(
					<p key={"tmli-f-name"}><b>Контактное лицо:</b> {data.name}</p>
				);
			if (data.phone)
				result.txt.push(
					<p key={"tmli-f-tel"}><b>Телефон:</b> {format_phone(data.phone)}</p>
				);
			result.txt.push(
				<p key={"tmli-f-type"} className={"_tac"}> {data.type}</p>
			);
			break;
		case "todo":
			if (user.id == data.ownerId)
				result.url = `${MENU_TITLE.taskManager.url}/edit/${data.id}`;
			else
				result.url = `${MENU_TITLE.taskManager.url}/show/${data.id}`;
			result.anchor = `№${data.id}`;

			result.date = date('d.m.Y', new Date(strtotime(data.created) * 1000));


			result.topInfo = data.status == 0 ? <div className="tm-todo-status-0">
				не выполнено
			</div> : <div className="tm-todo-status-1">
				на проверке
			</div>;


			if (data.files.length)
				result.filesInfo = data.files.length
					+ ' '
					+ get_noun(data.files.length, 'файл', 'файла', 'файлов');

			result.txt.push(
				<p key={"tmli-td-txt"}>{data.txt.length > 50
					? data.txt.substring(0, 150) + '...'
					: data.txt}</p>
			);
			if (isAdmin || data.ownerId != user.id) {
				result.todoUsers.push(<span key={"tmli-td-man-ot"}>от</span>);
				result.todoUsers.push(` ${data.ownerName} `);
			}
			if (data.execId != user.id) {
				result.todoUsers.push(<span key={"tmli-td-man-dlya"}>для</span>);
				result.todoUsers.push(` ${data.execName}`);
			}
			break;
		case "tasks":
			// console.dir(data);
			result.url = `/tasks/edit/${data.id}`;
			result.anchor = `№${data.id}`;
			result.date = date('d.m.Y H:i', new Date(strtotime(data.ddateReal) * 1000));
			result.topInfo = data.todoName;

			if (data.userName)
				result.managerName = data.userName;

			if (data.company)
				result.txt.push(
					<p key={"tmli-tsk-comp"}><b>Компания:</b> {data.company}</p>
				);
			if (data.phoneMobile)
				result.txt.push(
					<p key={"tmli-tsk-tel"}><b>Телефон:</b> {format_phone(data.phoneMobile)}</p>
				);
			if (data.address)
				result.txt.push(
					<p key={"tmli-tsk-address"}><b>Адрес:</b> {data.address}</p>
				);

			if (data.valueWas && data.valueNew)
				result.txt.push(
					<p key={"tmli-tsk-values"}>Перенесено с <b>{date('d.m.Y', new Date(strtotime(data.valueWas) * 1000))}</b> на <b>{date('d.m.Y', new Date(strtotime(data.valueNew) * 1000))}</b></p>
				);
			if (data.txt)
				result.txt.push(
					<p key={"tmli-tsk-txt"}><b>Причина переноса:</b> {data.txt}</p>
				);
			break;
		default:
			result.url = '#';
			result.anchor = 'нет боработчика для типа  ' + type;

			break;
	}

	return result;
}

