import React, {useEffect} from "react";
import {toastr} from "react-redux-toastr";
import roundTo from "round-to";
import Api, {apiAction} from "utils/Api";
import {saveSync} from "save-file";
import {SITE_URL, SOCKET_URL, rolesAdmin, rolesModerator} from "utils/config";
import date from "php-date";
import * as sha256 from "sha256";

export const in_array = (val, arr) => {
	return arr.indexOf(val) !== -1;
};
export const array_unset = (arr, val) => {
	let i = (typeof val === 'number') ? val : arr.indexOf(val);

	if (i > -1)
		arr.splice(i, 1);

	return arr;
};
export const array_unset_value = (arr, val) => {
	let i = arr.indexOf(val);
	// console.log(arr, val, i);
	if (i > -1)
		arr.splice(i, 1);

	return arr;
}
export const is_empty = arr => {
	if (Array.isArray(arr))
		return !arr.length;
	if (typeof arr === 'object')
		return !Object.keys(arr).length;

	return true;
};
export const unixTime = () => {
	return Math.round((new Date()).getTime() / 1000);
};
export const formatDate = (dateSrc, toUnixtime = false, withHours = false, forCalendar = false) => {
	const date = new Date(dateSrc);

	const getFullDayMoth = (val) => {
		return val > 9 ? val : '0' + val;
	};

	if (toUnixtime)
		return Math.round(date.getTime() / 1000);

	let formatted;
	if (forCalendar)
		formatted = date.getFullYear() + '-' + getFullDayMoth((date.getMonth() + 1)) + '-' + getFullDayMoth(date.getDate());
	else
		formatted = getFullDayMoth(date.getDate()) + '.' + getFullDayMoth((date.getMonth() + 1)) + '.' + date.getFullYear();

	if (withHours)
		formatted += ' ' + date.getHours() + ':' + date.getMinutes();

	return formatted;
};
export const dateFormat = (dt, format = 'd.m.Y') => {

	if (typeof dt === 'number')
		dt = dt * 1000;

	return date(format, new Date(dt));
};
export const getFullDayMoth = (val) => {
	return val > 9 ? val : '0' + val;
};
export const get_noun = (_num, form_for_1, form_for_2, form_for_5) => {
	const num = Math.abs(_num) % 100; // берем число по модулю и сбрасываем сотни (делим на 100, а остаток присваиваем переменной num)
	const num_x = num % 10; // сбрасываем десятки и записываем в новую переменную
	if (num > 10 && num < 20) // если число принадлежит отрезку [11;19]
		return form_for_5;
	if (num_x > 1 && num_x < 5) // иначе если число оканчивается на 2,3,4
		return form_for_2;
	if (num_x == 1) // иначе если оканчивается на 1
		return form_for_1;

	return form_for_5;
};

export const string2num = (value, roundDigits = 0) => {

	if (value === NaN)
		return 0;

	let newValue = value.replace(/,/g, '.');
	newValue.trim();

	newValue = !roundDigits ? parseInt(newValue) : parseFloat(newValue);
	newValue = newValue || 0;

	return roundTo(newValue, roundDigits);
};
export const getFileSize = (fsizeBytes, withPostfix = true) => {
	let unit = '', size = 0;
	if (fsizeBytes > 1024 * 1024) {
		size = Math.round(fsizeBytes / 1024 / 1024);
		unit = 'Мб';
	}
	else if (fsizeBytes > 1024) {
		size = Math.round(fsizeBytes / 1024);
		unit = 'Кб';
	}
	else {
		size = fsizeBytes;
		unit = 'байт';
	}

	return size + (withPostfix ? ' ' + unit : '');
};

export const getFileIcon = (extension) => {
	let fileIco = null;
	switch (extension) {
		case "pdf":
			fileIco = <i className={"fico fa fa-file-pdf-o red"}></i>;
			break;
		case "doc":
		case "docx":
			// fileIco = <img src={SITE_URL + "/img/ico_doc.png"}/>;
			fileIco = <i className={"fico fa fa-file-word-o blue"}></i>;
			break;
		case "xls":
		case "xlsx":
			fileIco = <i className={"fico fa fa-file-excel-o green"}></i>;
			// fileIco = <img src={SITE_URL + "/img/ico_xls.png"}/>;
			// fileIco = <i className={"fico fa fa-file-excel-o"}></i>;
			break;
		case "txt":
			fileIco = <i className={"fico fa fa-file-text-o"}></i>;
			break;
		case "jpg":
		case "jpeg":
		case "png":
		case "gif":
		case "bmp":
		case "tiff":
			fileIco = <i className={"fico fa fa-file-image-o"}></i>;
			break;
		case "zip":
		case "rar":
		case "7z":
		case "tar":
		case "gz":
			fileIco = <i className={"fico fa fa-file-archive-o"}></i>;
			break;
		default:
			fileIco = <i className={"fico fa fa-file-o"}></i>;
			break;
	}
	return fileIco;
};

export const number_format = (number, decimals = 2, dec_point = ".", thousands_sep = " ") => {	// Format a number with grouped thousands
	let i, kw, kd;

	i = parseInt(number = (+number || 0).toFixed(decimals)) + "";
	kw = i.split(/(?=(?:\d{3})+$)/).join(thousands_sep);
	kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

	return kw + kd;
};

export const getFileExtension = fileName => {
	return fileName.split('.').pop();
};

export const downloadFile = async (e, user, id, callback = null, options = {}) => {
	e.preventDefault();
	e.stopPropagation();
	const res = await Api.post('docs.php', {
		action: 'downloadFile',
		user: user,
		id: id,
		options: options
	});

	apiAction(res, (data) => {
		// console.dir(data);
		if (options['noSave'] == undefined) {
			if (data.fileData && data.fileName) {
				saveSync(
					data.fileData,
					data.fileName
				);
			}
			else
				toastr.error('Ошибка получения файла');

		}
		if (typeof callback == 'function')
			callback(data);
	}, () => {

	});
};

export const useScript = (url, id = null) => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = url;
		script.async = true;
		script.id = id;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, [url]);
};
// export default useScript;
export const urlGetParams = (loc) => {
	return loc.search
		.replace('?', '')
		.split('&')
		.reduce((r, e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});
};
export const bool_val = v => {

	if (v === true || v === false)
		return v;

	if (typeof v === 'string') {
		if ('true' === v)
			return true;
		if ('false' === v)
			return false;
		if ('1' === v)
			return true;
		if ('0' === v || v === '')
			return false;
		if ('' !== v)
			return true;
	}
	else if (typeof v === 'number' && v > 0)
		return true;
	else if (typeof v === 'object' && Object.keys(v).length)
		return true;

	return false;
}
export const scrollTo = (top = 0, smooth = false) => {
	window.scrollTo({
		behavior: smooth ? "smooth" : "auto",
		top: top
	});
}

export const format_phone = str => {

	let onlyDigits = str.replace(new RegExp('\\D', 'i'), '');
	if (onlyDigits.length == 10)
		onlyDigits = '7' + onlyDigits;
	let ar = onlyDigits.split('');

	if (ar.length == 11)
		return `${ar[0]} (${ar[1]}${ar[2]}${ar[3]}) ${ar[4]}${ar[5]}${ar[6]}-${ar[7]}${ar[8]}-${ar[9]}${ar[10]}`;
	else
		return str;
}
export const rand = (x, y) => {
	return parseInt(Math.random() * (y + 1 - x) + x, 10);
}
export const diffDays = (d1, d2) => {
	return Math.round(Math.abs((d1 - d2) / (24 * 60 * 60 * 1000)));
}
export const name2short = name => {
	let nameArr = name.trim().split(' ');
	let c = '';

	if (nameArr[0] != undefined)
		c += nameArr[0][0];
	if (nameArr[1] != undefined)
		c += ' ' + nameArr[1][0];

	if (c == '')
		c = name;

	return c;
}
export const changeLog = async (user, part, field, valueWas, valueNew) => {

	// const res = ;

	apiAction(await Api.post('users.php', {
		action: 'changeLog',
		user: user,
		part: part,
		field: field,
		valueWas: valueWas,
		valueNew: valueNew
	}), (data) => {

	}, () => {

	});
}
// export const deleteFile = async (user, id) => {
// 	apiAction(await Api.post('users.php', {
// 		action: 'changeLog',
// 		user: user,
// 		part: part,
// 		field: field,
// 		valueWas: valueWas,
// 		valueNew: valueNew
// 	}), (data) => {
//
// 	}, () => {
//
// 	});
// }

export const FilesLisView = ({user, files, edit, onDeleteFile, isGallery = false}) => {

	if (files.length == 0)
		return null;

	return (
		<div className="form-item tm-todo-files">
			<label>
				{/*<i className={"fa fa-files-o"}></i> */}
				Файлы
			</label>
			<ul className="form-files-list">
				{
					files.map(file => {
						// console.dir(file);
						return (
							<li key={"files-list-" + file.id}>
								<a
									href={"#"}
									onClick={e => downloadFile(e, user, file.id)}
								>
									{getFileIcon(file.ext)}
									{file.fname}
								</a>
								{
									edit ? <i
										className="fa fa-close"
										title={"Удалить файл"}
										onClick={e => onDeleteFile(file.id)}
									></i> : null
								}
							</li>
						);
					})
				}
			</ul>
		</div>
	)
}
// export const connectSocket = (user, actionSetSocket, url) => {
//
// 	if (SOCKET_URL) {
// 		console.log('connectSocket');
// 		// console.log(user);
// 		const socket = io(SOCKET_URL, {
// 			reconnectionDelay: 10000,
// 			reconnectionDelayMax: 60000,
// 			reconnectionAttempts: 10,
// 			query: {
// 				userId: user ? user.id : 0,
// 				url: url,
// 				browserWidth: window.outerWidth,
// 				browserHeight: window.outerHeight,
// 			}
// 		});
// 		// console.log(socket.connected);
// 		socket.on('connect', () => {
// 			actionSetSocket(socket);
// 			console.log('Подключено к удаленному серверу', SOCKET_URL);
//
// 			// toastr.success('Подключено к удаленному серверу ' + SOCKET_URL);
// 		});
// 		socket.on("connect_error", () => {
// 			console.error('Невозможно подключиться к удаленному серверу', SOCKET_URL);
// 			// if (user && user.role == 'admin')
// 			// 	toastr.error(`Невозможно подключиться к удаленному серверу ${SOCKET_URL}. Система работает без обновлений "на лету"`, '', {timeOut: 0});
//
// 			// socket.disconnect();
// 		});
//
//
// 	}
// }

/**
 * Раскидываем данные по офисам, по их доменам
 * @param user
 * @param divisions
 * @param data
 */
export const spreadByDivisions = (divisions, data) => {

	let result = {
		0: {
			items: [],
			totalSum: 0
		}
	};

	let allDomains = [];
	divisions.map(division => {
		allDomains = allDomains.concat(division.domains);

		return null;
	});
	// console.log(allDomains);
	// console.dir(data);

	let alreadyAddedItemIdList = [];

	divisions.map(division => {
		// console.log(' division.domains',  division.name, division.domains);
		data.map(item => {

			let domain = item.site ?? "";
			let divId = null;
			if (in_array(domain, division.domains)) {
				divId = division.id;
			}
			else if (!in_array(domain, allDomains))
				divId = 0;

			// console.log(`itemId=${item.id} domain=${domain}; divId=${divId}`);
			if (divId != null && !in_array(item.id, alreadyAddedItemIdList)) {
				if (!result.hasOwnProperty(divId))
					result[divId] = {
						items: [],
						totalSum: 0
					};
				result[divId].items.push(item);
				result[divId].totalSum += item.summ ? item.summ : 0;

				alreadyAddedItemIdList.push(item.id);
			}

			return null;
		});

		return null;
	});

	return result;
}

/**
 * Раскидываем данные по доменам
 * @param array domains  - массив доменов
 * @param array data  - массив данных
 * @param bool summarizeStatuses - суммировать по статуссам
 * @returns {{}|null}
 */
export const spreadByDomains = (domains, data, summarizeStatuses = false) => {
	if (domains.length === 0)
		return null;

	let result = {};

	let alreadyAddedItemIdList = [];

	for (let item of data) {
		if (in_array(item.id, alreadyAddedItemIdList))
			continue;

		let domain = item.site ?? "";

		if (!result.hasOwnProperty(domain)) {
			result[domain] = {
				items: [],
				totalSum: 0
			};
			if (summarizeStatuses)
				result[domain]['statusSum'] = {};
		}

		result[domain].items.push(item);
		result[domain].totalSum += item.summ ? item.summ : 0;

		if (summarizeStatuses && item.status !== undefined) {
			if (!result[domain]['statusSum'].hasOwnProperty(item.status))
				result[domain]['statusSum'][item.status] = 0;
			result[domain]['statusSum'][item.status]++;
		}

		alreadyAddedItemIdList.push(item.id);
	}

	return result;

	//
	//
	// let alreadyAddedItemIdList = [];
	//
	// divisions.map(division => {
	// 	// console.log(' division.domains',  division.name, division.domains);
	// 	data.map(item => {
	//
	// 		let domain = item.site ?? "";
	// 		let divId = null;
	// 		if (in_array(domain, division.domains)) {
	// 			divId = division.id;
	// 		} else if (!in_array(domain, allDomains))
	// 			divId = 0;
	//
	// 		// console.log(`itemId=${item.id} domain=${domain}; divId=${divId}`);
	// 		if (divId != null && !in_array(item.id, alreadyAddedItemIdList)) {
	// 			if (!result.hasOwnProperty(divId))
	// 				result[divId] = {
	// 					items: [],
	// 					totalSum: 0
	// 				};
	// 			result[divId].items.push(item);
	// 			result[divId].totalSum += item.summ ? item.summ : 0;
	//
	// 			alreadyAddedItemIdList.push(item.id);
	// 		}
	//
	// 		return null;
	// 	});
	//
	// 	return null;
	// });
	//
	// return result;
}

export const user4api = user => {
	return {
		id: user.id
	}
}
export const telHref = tel => {
	return tel.replace(new RegExp('\\D+', 'ig'), '');
}
export const copyClipboard = (str, showToast = true) => {
	//https://stackoverflow.com/a/65996386
	if (navigator.clipboard && window.isSecureContext) {
		navigator.clipboard.writeText(str).then(null);
	}
	else {
		// text area method
		let textArea = document.createElement("textarea");
		textArea.value = str;
		// make the textarea out of viewport
		textArea.style.position = "fixed";
		textArea.style.left = "-999999px";
		textArea.style.top = "-999999px";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		document.execCommand('copy');
		textArea.remove();

	}

	if (showToast)
		toastr.info('Сообщение', 'Скопировано в буфер обмена: ' + str);
}
export const trim = (str, charlist) => {
	if (typeof str !== "string" || str == '')
		return str;

	charlist = !charlist ? ' \s\xA0' : charlist.replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^\:])/g, '\$1');

	let re = new RegExp('^[' + charlist + ']+|[' + charlist + ']+$', 'g');
	return str.replace(re, '');
}
export const isRole = (user, roles) => {
	if (!user || !user.role)
		return false;
	return in_array(user.role, roles);
}
export const isAdmin = user => {
	return isRole(user, rolesAdmin);
}
export const isModerator = user => {
	return isRole(user, rolesModerator);
}
export const isManager = user => {
	return isRole(user, ['manager']);
}
export const isManagerModerator = user => {
	return isRole(user, [...rolesModerator, 'manager']);
}

export const isUser = user => {
	return isRole(user, ['user']);
}

export const sortArrayOfObjects = (arr, field, asc = true) => {
	if (arr.length == 0)
		return [];
	let res = [...arr];

	res.sort((a, b) => (a[field] > b[field])
		?
		(asc ? 1 : -1)
		: (
			(b[field] > a[field])
				?
				(asc ? -1 : 1)
				: 0
		))

	return res;
}
export const hashObject = obj => {
	return sha256(JSON.stringify(obj));
}
export const bool2YesNo = (param, returnValue = false, trueValue = 'Да', falseValue = 'Нет') => {
	// console.log('bool2YesNo', typeof param, param);
	let val = false,
		txtVal = '';
	if (typeof param == 'boolean')
		val = param;
	else if (typeof param == 'string')
		val = param != '';

	txtVal = val ? trueValue : falseValue;

	if (returnValue)
		return [val, txtVal];

	return txtVal;
}