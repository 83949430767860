import React, {useState} from 'react';
import cn from "classnames";
import { NavLink } from "react-router-dom";
import {toastr} from "react-redux-toastr";
// import {MESSAGES} from "utils/config";
// import Api, {apiAction} from "utils/Api";


export const BUTTON_TYPE = {
	apply: 'apply',
	applyIco: 'applyIco',
	save: 'save',
	saveIco: 'saveIco',
	close: 'close',
	delete: 'delete',
	closeIco: 'closeIco'
};

const BUTTONS = {
	apply: {
		cls: '',
		title: 'Сохранить'
	},
	applyIco: {
		cls: '',
		title: 'Сохранить',
		ico: 'floppy-o'
	},
	save: {
		cls: ' -green',
		title: 'Сохранить и вернуться'
	},
	saveIco: {
		cls: ' -green',
		title: 'Сохранить и вернуться',
		ico: 'floppy-o'
	},
	close: {
		cls: ' -red',
		title: 'Закрыть'
	},
	closeIco: {
		cls: ' -red',
		title: 'Закрыть',
		ico: 'times'
	},
	delete: {
		cls: ' -grey',
		title: 'Удалить',
		ico: 'trash-o'
	}
};

const Button = (props) => {

	const {type = 'apply', cls = '', className='',
		onClick = () => {},
		title = null,
		tip = null,
		tipTimeout = 500, ico = null,
		disabled = false,
		href = null, hrefBlank = false, buttonType = 'div',
		style = null,
		confirmText = ''// вопрос на подтверждение клика, для удаления например
	} = props;

	const [tipShowed, setTipShowed] = useState(false);

	let _ico = null;
	if (BUTTONS[type] && BUTTONS[type].ico) {
		_ico = BUTTONS[type].ico;
	}
	if (ico !== null)
		_ico = ico;


	const inner = (
		<>
			{
				tip ? <div className={cn("tip", {active: tipShowed})}>
					{tip}
				</div> : null
			}
			<div className={cn("-in", {emptyTitle: (typeof title === "string" && title === '')})}>
				{_ico && <i className={cn("fa", "fa-"+_ico)}></i> }
				{title !== null ? title : (BUTTONS[type] ? BUTTONS[type].title: '')}
			</div>
		</>
	);
	const classNames = cn("btn", BUTTONS[type] ? BUTTONS[type].cls : '', cls, {disabled: disabled, [className]: className != ''});

	const handleClick = e => {
		if (!disabled) {

			if (confirmText !== '') {
				toastr.confirm(
					confirmText,
					{
						okText: 'Да',
						cancelText: 'Нет',
						onOk: async () => {
							onClick(e);
						},

					}
				);
			}
			else
				onClick(e);


			if (tip && tipTimeout)
				setTipShowed(true);

		}

	};
	const handleMouseEnter = () => {
		if (tip && tipTimeout) {
			setTimeout(() => setTipShowed(true), tipTimeout)
		}
	};
	const handleMouseLeave =() => {
		setTipShowed(false);
		setTimeout(() => setTipShowed(false), tipTimeout + 100)
	};

	if (href || buttonType === 'a')
		return (
			<NavLink
				to={href}
				className={classNames}
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				target={hrefBlank ? '_blank' : null}
				style={style}
			>
				{inner}
			</NavLink>
			// <Link
			// 	to={href}
			// 	onClick={handleClick}
			// 	onMouseEnter={handleMouseEnter}
			// 	onMouseLeave={handleMouseLeave}
			// 	target={hrefBlank ? '_blank' : null}
			// 	style={style}
			// >
			// 	{inner}
			// </Link>
		);
	else if (buttonType === 'button')
		return (
			<button
				className={classNames}
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={style}
			>
				{inner}
			</button>
		);
	else
		return (
			<div
				className={classNames}
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={style}
			>
				{inner}
			</div>
		);
};

export default Button;
