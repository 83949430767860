import React from 'react';
import date from "php-date";
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css'
import {in_array, getFileIcon, downloadFile} from "utils/func";
import {IMG_EXTENSIONS, rolesModerator} from "utils/config";
import cn from "classnames";

const DocsListFiles = props => {

	const {
		files, user, onDelete, onDownload,
		onlyThumb = false
	} = props;


	if (!user)
		return null;


	return (
		<div className={"docs-list flx"}>
			{
				files.map((file, i) => {

					let ico = null;
					if (in_array(file.ext.toLowerCase(), IMG_EXTENSIONS)) {
						ico = <ReactFancyBox
							thumbnail={file.url}
							image={file.url} />;
					}
					else
						ico = <a
							href={file.downloadUrl ?? '#'}
							onClick={e => typeof onDownload == 'function' ? onDownload(e, user, file.id) : null}
						>
							{getFileIcon(file.ext)}
						</a>

					return (
						<div
							key={file.id}
							className={cn("docs-list-item",
								{
									//file.opened - это объект, где ключ- ИД юзера , а значение - время открытия
									opened: (file.opened && file.opened[user.id] !== undefined),
									printed: (file.printed && file.printed[user.id] !== undefined)
								})}
						>
							{
								(onDelete && (user.id === file.userId || in_array(user.role, rolesModerator))) ?
									<i
										className="fa fa-close del"
										title={"Удалить файл"}
										onClick={e => onDelete(file.id)}
									></i> : null
							}
							<div className="ico">
								{ico}
							</div>

							{
								!onlyThumb
								? (
									<>
										<div className="name">
											<a
												href={file.downloadUrl}
												// href={"#"}
												onClick={e => {
													e.preventDefault();
													if (typeof onDownload == 'function')
														onDownload(e, user, file.id)
												}}
											>
												{file.name}
												{
													file.naklNum ? <div className="">
														Накладная № {file.naklNum }
													</div> : null
												}
												{/*/ {file.opened} / {file.opened ? 1: 1}*/}
											</a>
										</div>
										<div className="dates">
											{date('d.m.Y', new Date(parseInt(file.fDateSeconds * 1000)))}
											{" "}
											<span title={"Дата создания"}>(
												{date('d.m.Y H:i', new Date(parseInt(file.createdSeconds * 1000)))}
												)
											</span>
										</div>
									</>
									) : null
							}

							{/*<div className="print">*/}
							{/*{*/}
							{/*	file.printUrl ?*/}
							{/*		<a*/}
							{/*			// href={"#"}*/}
							{/*			onClick={e => {*/}
							{/*				onPrint(e, user, file);*/}
							{/*				window.open(file.printUrl, '_blank');*/}
							{/*			}}*/}
							{/*			href={file.printUrl}*/}
							{/*			target={"_blank"}*/}
							{/*		>*/}
							{/*			распечатать*/}
							{/*		</a>*/}
							{/*	 : ' '*/}
							{/*}*/}
							{/*</div>*/}
						</div>
					)
				})
			}
		</div>
	)

};

export default DocsListFiles;
