import React from 'react';
import { NavLink } from "react-router-dom";
import {MENU_TITLE, MENU} from "utils/config";

const ReportsIndex = props => {

	let menu = [];
	Object.values(MENU).map(item => {
		if (item.name == MENU_TITLE.reports.many)
			menu = item.menu;

		return null;
	});

	return (
		<ul className={"reports-index _z"}>
			{
				menu.map((item, i) => {

					return (
						<li
							key={"reports-index-" + i}
						>
							<NavLink
								to={item.url}
							>
								<div className="i">
									{
										(item.img && item.img != '') ?
											<img src={item.img} />
											: <i className={"fa " + item.ico}></i>
									}

								</div>
								<div className="n">
									{item.name}
								</div>
							</NavLink>
						</li>
					);
				})
			}
		</ul>
	)

};

export default ReportsIndex;
