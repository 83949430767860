import * as strtotime from "strtotime";
// import date from "php-date";
import {diffDays} from "utils/func";

export const SheetsSelectedCoordInit = [
    0, //tab id
    -1, // row num
    -1, //col num
    false, //все ячейки в ряду
];

export const SheetsBgr = {
    white: 'ffffff',
    yellow: 'ffff00',
    orange: 'ffa500',
    red: 'ff0000',
};


export const billsShowCallText = (data) => {
    data = {...data};
    // console.log(data);
    // console.log(data[3]);
    if (data[6] != undefined && data[6].value !== ''
        &&
        (data[3] == undefined || data[3].value === '')
    ) {
        let rowDate = new Date(strtotime(data[6].value + ' 23:59:59') * 1000);
        // console.log(data[6].value, rowDate, diffDays(new Date(), rowDate));

        if (diffDays(new Date(), rowDate))
            return <div title={"от " + data[6].value}>Обзвонить</div>;
    }

    return null;
}