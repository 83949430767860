import React, {useState} from 'react';
import cn from "classnames";
import {isModerator, isUser} from "utils/func";
import ManagerSelect from "./ManagerSelect";
import {SheetsBgr, SheetsSelectedCoordInit, billsShowCallText} from "./utils";

const SheetsTableItem = ({
                             sheetName,
                             columns,
                             //columnsComponents,
                             rowNumber,
                             data,
                             editable = true,
                             onCellChange,
                             user,
                             tabId,

                             selectedCellCoord, onSelectedCellCoord
}) => {

    const value = (key, def = '') => {
        return (data[key]) ? data[key] : def;
    };

    const onChange = (e, rowNumber, columnNumber, val = null) => {
        onCellChange(tabId, rowNumber, columnNumber, val ? val : e.currentTarget.innerText);
    };

    let {data: grid} = data;


    // return null;

    let selected = tabId == selectedCellCoord[0] && selectedCellCoord[1] == rowNumber;

    return (
        <tr className={cn({
            selected: selected
        })}>
            <td
                className={"td col-num"}
                key={`sheets-tbl-td-${tabId}-num`}
                onClick={() => {

                    if (isModerator(user) || isUser(user)) {
                        if (!selected)
                            onSelectedCellCoord([tabId, rowNumber, columns.length - 1, true]);
                        else
                            onSelectedCellCoord(SheetsSelectedCoordInit);

                        // setSelected(!selected);
                    }
                }}
            >
                {(rowNumber + 1)}
                {/*{tabId}*/}
            </td>
            {
                columns.map((col, colNum) => {
                    let canEdit = editable;
                    let cont = null;
                    let defVal = null;
                    let style = {};
                    let bgr = '';
                    let rowData = null;

                    if (grid[tabId] && grid[tabId][rowNumber] && grid[tabId][rowNumber][colNum]) {

                        rowData = grid[tabId][rowNumber];
                        // console.log(rowNumber, colNum, grid[tabId][rowNumber][colNum])
                        if (grid[tabId][rowNumber][colNum]) {
                            cont = grid[tabId][rowNumber][colNum].value;
                            defVal = cont;
                            if (grid[tabId][rowNumber][colNum].bgr != '') {
                                style = {backgroundColor: `#${grid[tabId][rowNumber][colNum].bgr}`};
                                bgr = grid[tabId][rowNumber][colNum].bgr;
                            }

                            canEdit = grid[tabId][rowNumber][colNum].edit;

                        }
                    }


                    // console.dir(data.managers);

                    if (sheetName == 'bills' ) {
                        if (col.id == 'manager') {
                            if (editable) {
                                cont = <ManagerSelect
                                    rowNumber={rowNumber}
                                    colNumber={colNum}
                                    managers={data.managers ? data.managers : []}
                                    value={defVal ? defVal : 0}
                                    onChange={val => {

                                        onCellChange(tabId, rowNumber, colNum, val);
                                    }}
                                />
                                canEdit = false;
                            }
                            else if (data.managers) {
                                data.managers.map(man => {
                                   if (man.id == defVal)
                                       cont = man.name;
                                });

                            }

                        }
                        if (col.id == 'call') {
                            cont = billsShowCallText(rowData);
                            canEdit = false;
                        }
                    }

                    return (
                        <td
                            key={`sheets-tbl-td-${tabId}-${colNum}`}
                            contentEditable={editable && canEdit}
                            suppressContentEditableWarning={true}
                            onBlur={e => {
                                if (canEdit)
                                    onChange(e, rowNumber, colNum);
                            }}
                            style={style}
                            className={cn({
                                whiteText: bgr == SheetsBgr.red
                            })}
                        >
                            {/*{rowNumber}/{colNum} / {editable ? 'ok': ''}*/}
                            {cont}
                        </td>
                    );
                })
            }
        </tr>
    );



    // return (
    //     <div
    //         className={cn("tr", {selected: selected})}
    //     >
    //         <div className={"td col-num"}
    //              onClick={e => {
    //                  if (isModerator(user) || isUser(user))
    //                  setSelected(!selected)
    //              }}
    //         >
    //             {i}
    //         </div>
    //         <div className={"td col-n"}
    //              contentEditable={editable}
    //              suppressContentEditableWarning={true}
    //              onBlur={e => onChange(e, 'bill_number')}
    //         >
    //             {value('bill_number')}
    //         </div>
    //         <div className={"td col-man"}>
    //             <select
    //                 name={"manager_id"}
    //                 defaultValue={value('manager_id', 0)}
    //                 onChange={e => {
    //                     onChange(e, 'manager_id')
    //                 }}>
    //
    //             </select>
    //         </div>
    //         <div className={"td col-comp"}>
    //             {value('comp')}
    //         </div>
    //         <div className={"td col-sum"}>
    //
    //         </div>
    //         <div className={"td col-prim"}
    //              contentEditable={editable}
    //              suppressContentEditableWarning={true}
    //              onBlur={e => onChange(e, 'prim')}
    //         >
    //
    //         </div>
    //         <div className={"td col-nakl"}
    //              contentEditable={editable}
    //              suppressContentEditableWarning={true}
    //              onBlur={e => onChange(e, 'prim')}
    //         >
    //
    //         </div>
    //         <div className={"td col-call"}>
    //             &nbsp;
    //         </div>
    //     </div>
    // )

};

export default SheetsTableItem;