import React, {createRef, useState} from 'react';
import cn from "classnames";
import {toastr} from "react-redux-toastr";
import Switch from "react-switch";
import {Button, SignatureWindow, FormItem, FilesList} from "components/_common";
import {BUTTON_TYPE} from "components/_common/Button";
import Api, {apiAction} from "utils/Api";
import DocsUploadBox from "components/docs/DocsUploadBox";
import date from "php-date";
import * as strtotime from "strtotime";
import {LOGO_TITLE, MESSAGES} from "utils/config";
import {downloadFile} from "utils/func";
import DocsListFiles from "components/docs/DocsListFiles";

const API_URL = 'tasks.php';

/**
 * Монтаж
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InstallEdit = props => {

	const {
		GUI, user,
		task,
		install,
		actions,
		history
	} = props;


	const [showSignatureWin, setShowSignatureWin] = useState(false);
	const [visibleBlock, setVisibleBlock] = useState(0);

	const [data, setData] = useState({
		taskId: task ? task.id : 0,
		rating: 0,
		// claims: false,

		claims: (!install || (install.claimFilesId.length > 0 || install.claimText != '')),
		claimFilesId: install ? install.claimFilesId : [],
		claimFiles: install ? install.claimFiles : [],
		claimFiles4Upload: [],
		claimText: install ? install.claimText : '',

		detailsFilesId: install ? install.detailsFilesId : [],
		detailsFiles: install ? install.detailsFiles : [],
		detailsFiles4Upload: [],
		detailsText: install ? install.detailsText : '',

		clientName: (install && install.clientName != '') ? install.clientName : task.name,
		clientText: install ? install.clientText : '',
		signature: '',
		signatureImg: install ? install.signatureImg : '',

		photosFiles: install ? install.photosFiles : [],
		photosFiles4Upload: []
	});
	// console.clear();
	// console.dir(install);
	console.dir('InstallEdit data init', data);


	let visibleBlockIterator = 0;
	const increaseVbIter = (add = 1) => {
		visibleBlockIterator += add;
	}

	const printArrows = (current, validate, onClickAction) => {

		const preValidate = () => {

			if (typeof validate == 'function')
				return validate();

			return true;
		}

		const maxSteps = !data.claims ? 2 : 3;

		return (
			<div className="ti-arrows flx -alc -sb">
				{
					current > 0
						? <div
							className={"prev"}
							onClick={() => {
								if (preValidate()) {
									setVisibleBlock(current - 1);

									if (typeof onClickAction == 'function')
										onClickAction();
								}
							}}>
							<i className="fa fa-arrow-left"/>
						</div>
						: null
				}
				{
					current < maxSteps
						? <div
							className={"next"}
							onClick={() => {

								if (preValidate()) {
									setVisibleBlock(current + 1);

									if (typeof onClickAction == 'function')
										onClickAction();
								}
							}}>
							<i className="fa fa-arrow-right"/>
						</div>
						: null
				}

			</div>
		);
	};

	const onSave = async (isComplete) => {
		await uploadFiles('photosFilesId', data.photosFiles4Upload, 'photosFiles');

		if (data.signatureImg == '') {
			toastr.error('Ошибка', 'Нет подписи клиента');

			return false;
		}

		await setFields({
			completeDate: isComplete ? Date.now() / 1000 : 0
		});

		history.push(closeUrl);

	}


	let apiActionProcess = false;

	const uploadFiles = async (field, files, fieldFilesList) => {
		if (files.length === 0)
			return;
		const formData = new FormData();

		formData.append('id', task.id);
		formData.append('user', JSON.stringify(user));
		formData.append('action', 'installSetFiles');
		formData.append('field', field);
		files.map((file, i) => {
			formData.append('file_' + i, file);
		})

		if (!apiActionProcess) {
			actions.showLoading();
			apiAction(await Api.post(API_URL, formData), (resp) => {
				console.dir('uploadFiles', resp);
				// console.log(Object.keys(resp.uploaded));
				setData((prev) => ({
					...prev,
					[fieldFilesList]: resp.files
				}));
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	}
	const setFields = async (data) => {
		// console.log('setFields', data);
		if (!apiActionProcess) {
			actions.showLoading();
			apiAction(await Api.post(API_URL, {
				action: 'setInstallFields',
				user: user,
				id: task.id,
				data: data
			}), (resp) => {
				// console.log('setFields ', data, 'response', resp);

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	}
	const onDeleteFile = (fileId, fieldToDelete, fieldToSet) => {
		console.log('delete file', data.taskId, fileId, fieldToDelete, fieldToSet);
		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: async () => {
					// console.log('delete file', data.id, fileId);
					if (!apiActionProcess) {
						actions.showLoading();
						apiAction(await Api.post(API_URL, {
							action: 'deleteInstallFiles',
							user: user,
							id: data.taskId,
							fileId: fileId,
							field: fieldToDelete
						}), (resp) => {


							setData(prev => ({
								...prev,
								[fieldToSet]: resp.files
							}));

						}, () => {
							apiActionProcess = false;
							actions.hideLoading();
						});
					}
				}
			}
		);
	};

	const closeUrl = '/tasks/date/' + date('Y-n-j', new Date(strtotime(task.ddateReal) * 1000));

	return (

		<div>


			<div className={cn("tasks-install-block", {show: visibleBlock === visibleBlockIterator})}>
				<h1 className={"flx -alc -c"}>
					Есть рекламации?
					<span>&nbsp;&nbsp;&nbsp;</span>
					<Switch
						onChange={() => {
							setData((prev) => ({
								...prev,
								claims: !data.claims
							}));
						}}
						checked={data.claims}
					/>
					<span>&nbsp;&nbsp;&nbsp;</span>
					{
						data.claims ? <span>Да&nbsp;</span> : <span>Нет</span>
					}
				</h1>


				<div style={{display: data.claims ? 'block' : 'none'}}>
					<div className={"tasks-install-files-wrp"}>


						<DocsUploadBox
							showOnlyUploadBox={true}
							settings={user.settings}
							filesList={data.claimFiles4Upload}
							setFilesList={(_files) => {
								// console.dir(_files);
								setData((prev) => ({
									...prev,
									claimFiles4Upload: _files,
									// claimFiles4Upload: []
								}));

							}}

							GUI={GUI}
							formRef={null}
						/>
						<div className="mt20">
							<FilesList
								files={data.claimFiles}
								onDelete={(fileId) => onDeleteFile(fileId, 'claimFilesId', 'claimFiles')}
							/>
							{/*<DocsListFiles*/}
							{/*	user={user}*/}
							{/*	files={data.claimFiles}*/}
							{/*	onDelete={(fileId) => onDeleteFile(fileId, 'claimFilesId', 'claimFiles')}*/}
							{/*	onDownload={downloadFile}*/}
							{/*	onlyThumb={true}*/}
							{/*/>*/}
							{/*<FilesLisView*/}
							{/*	user={user}*/}
							{/*	edit={true}*/}
							{/*	files={data.claimFiles}*/}
							{/*	onDeleteFile={(fileId) => onDeleteFile(fileId, 'claimFilesId', 'claimFiles')}*/}
							{/*/>*/}
						</div>
					</div>
					<div className="mt20">
						<FormItem
							name={"claimText"}
							label={"Текстовое описание"}
							fieldType={"textarea"}
							isRequire={false}
							cls={"ti-tarea"}
							defVal={data.claimText}
							blurAction={(e, val) => {
								setData((prev) => ({
									...prev,
									claimText: val
								}));
							}}
						/>
					</div>
				</div>

				{printArrows(visibleBlockIterator, () => {
					if (data.claims) {

						if (data.claimFiles.length === 0 && data.claimText === '' && data.claimFiles4Upload.length === 0) {
							toastr.error('Заполните рекламации', 'Загрузите файлы и/или впишите текст');

							return false;
						}
					}

					return true;
				}, async () => {
					if (data.claims) {

						await uploadFiles('claimFilesId', data.claimFiles4Upload, 'claimFiles');

						await setFields({
							claimText: data.claimText
						});

						setData((prev) => ({
							...prev,
							claimFiles4Upload: []
						}));
					} else {
						await setFields({
							claimText: ''
						});
					}
				})}
			</div>

			{
				data.claims ? (
					<>
					{increaseVbIter()}
					<div className={cn("tasks-install-block", {show: visibleBlock === visibleBlockIterator})}>
						<h1>Необходимые детали<i></i> для замены </h1>

						<div>
							<div>
								<DocsUploadBox
									showOnlyUploadBox={true}
									settings={user.settings}
									filesList={data.detailsFiles4Upload}
									setFilesList={(_files) => {
										setData((prev) => ({
											...prev,
											detailsFiles4Upload: _files
										}));
									}}
									GUI={GUI}
									formRef={null}
								/>
								<div className="mt20">
									<FilesList
										files={data.detailsFiles}
										onDelete={(fileId) => onDeleteFile(fileId, 'detailsFilesId', 'detailsFiles')}
									/>
									{/*<DocsListFiles*/}
									{/*	user={user}*/}
									{/*	files={data.detailsFiles}*/}
									{/*	onDelete={(fileId) => onDeleteFile(fileId, 'detailsFilesId', 'detailsFiles')}*/}
									{/*	onDownload={downloadFile}*/}
									{/*	onlyThumb={true}*/}
									{/*/>*/}
									{/*<FilesLisView*/}
									{/*	user={user}*/}
									{/*	edit={true}*/}
									{/*	files={data.detailsFiles}*/}
									{/*	onDeleteFile={(fileId) => onDeleteFile(fileId, 'detailsFilesId', 'detailsFiles')}*/}
									{/*/>*/}
								</div>
							</div>
							<div className="mt20">
								<FormItem
									name={"detailsText"}
									label={"Текстовое описание"}
									fieldType={"textarea"}
									isRequire={false}
									cls={"ti-tarea"}
									defVal={data.detailsText}
									blurAction={(e, val) => {
										setData((prev) => ({
											...prev,
											detailsText: val
										}));
									}}
								/>
							</div>
						</div>

						{printArrows(visibleBlockIterator, null, async () => {

							await uploadFiles('detailsFilesId', data.detailsFiles4Upload, 'detailsFiles');

							await setFields({
								detailsText: data.detailsText
							});

							setData((prev) => ({
								...prev,
								detailsFiles4Upload: []
							}));
						})}
					</div>
					</>
				) : null
			}




			{increaseVbIter()}
			<div className={cn("tasks-install-block", {show: visibleBlock === visibleBlockIterator})}>
				<h1>Клиент</h1>
				<div>
					<FormItem
						edit={true}
						name={"clientName"}
						label={"ФИО принимающего"}
						fieldType={"text"}
						isRequire={true}
						defVal={data.clientName}
						blurAction={(e, val) => {
							setData((prev) => ({
								...prev,
								clientName: val
							}));
						}}
					/>
				</div>
				<div>
					<div>
						<FormItem
							name={"clientText"}
							label={"Комментарии клиента"}
							fieldType={"textarea"}
							isRequire={false}
							cls={"ti-tarea"}
							defVal={data.clientText}
							blurAction={(e, val) => {
								setData((prev) => ({
									...prev,
									clientText: val
								}));
							}}
						/>
					</div>
				</div>
				<div className={"_tac flx -alc -sa mt20"}>
					<Button
						type={BUTTON_TYPE.save}
						title="Добавить подпись"
						onClick={() => setShowSignatureWin(true)}
					/>
					{
						data.signatureImg != '' ?
							<img src={data.signatureImg} width={50}/>
							: null
					}
				</div>

				{printArrows(visibleBlockIterator, () => {

					if (data.clientName === '') {
						toastr.error('Ошибка', 'Заполните поле "ФИО принимающего"');
						return false;
					}
					if (data.signature === '' && data.signatureImg == '') {
						toastr.error('Ошибка', 'Нет подписи клиента');
						return false;
					}

					if (data.signature != '' && data.signature.length < 500) {
						toastr.error('Ошибка', 'Вы пытаетесь загрузить пустую подпись');
						return false;
					}

					return true;
				}, async () => {
					await setFields({
						clientName: data.clientName,
						clientText: data.clientText,
						signature: data.signature
					});
				}
				)}
			</div>


			{increaseVbIter()}
			<div className={cn("tasks-install-block", {show: visibleBlock === visibleBlockIterator})}>
				<h1>Фото объекта </h1>

				<div>
					<DocsUploadBox
						showOnlyUploadBox={true}
						settings={user.settings}
						filesList={data.photosFiles4Upload}
						setFilesList={(_files) => {
							// console.dir(_files);
							setData((prev) => ({
								...prev,
								photosFiles4Upload: _files
							}));
						}}
						GUI={GUI}
						formRef={null}
					/>
					<div className="mt20">
						<FilesList
							files={data.photosFiles}
							onDelete={(fileId) => onDeleteFile(fileId, 'photosFilesId', 'photosFiles')}
						/>
						{/*<DocsListFiles*/}
						{/*	user={user}*/}
						{/*	files={data.photosFiles}*/}
						{/*	onDelete={(fileId) => onDeleteFile(fileId, 'photosFilesId', 'photosFiles')}*/}
						{/*	onDownload={downloadFile}*/}
						{/*	onlyThumb={true}*/}
						{/*/>*/}
					</div>
				</div>
				{printArrows(visibleBlockIterator, null, async () => {
					await uploadFiles('photosFilesId', data.photosFiles4Upload, 'photosFiles');
					setData((prev) => ({
						...prev,
						photosFiles4Upload: []
					}));
				})}

				<div className="flx -sb -alc mt20">
					<Button
						title={
							// (install !== null && install.completeDate !== 0)
							// 	? "Не сдан" :
							"Сохранить без сдачи"
						}
						type={BUTTON_TYPE.apply}
						onClick={async () => {
							await onSave(false);
						}}
					/>
					<Button
						title={"СДАТЬ"}
						ico={"check-circle-o"}
						type={BUTTON_TYPE.save}
						onClick={async () => {
							await onSave(true);
						}}
					/>
				</div>

			</div>

			{/*{increaseVbIter()}*/}
			{/*<div className={cn("tasks-install-block", {show: visibleBlock === visibleBlockIterator})}>*/}
			{/*	<h1>Выберите оценку<i/> качества монтажа</h1>*/}
			{/*	<div className="ti-rates flx -alc -sa">*/}
			{/*		{[...Array(5)].map((v, k) => {*/}
			{/*			let rt = k + 1;*/}
			{/*			return <div*/}
			{/*				key={"ti-rate-" + k}*/}
			{/*				className={(data.rating >= rt ? 'act' : '')}*/}
			{/*				onClick={async () => {*/}
			{/*					setData((prev) => ({*/}
			{/*						...prev,*/}
			{/*						rating: rt*/}
			{/*					}));*/}
			{/*				}}*/}
			{/*			>*/}
			{/*				<span>{rt}</span>*/}
			{/*				<i className={"fa fa-star" + (data.rating < rt ? '-o' : '')}></i>*/}
			{/*			</div>;*/}
			{/*		})}*/}
			{/*	</div>*/}
			{/*	<div className="_tac">*/}

			{/*		<Button*/}
			{/*			title={"Оценить работу (для клиента)"}*/}
			{/*			ico={"star-half-o"}*/}
			{/*			type={BUTTON_TYPE.save}*/}
			{/*			onClick={async () => {*/}
			{/*				if (data.rating == 0) {*/}
			{/*					toastr.error('Ошибка', 'Выберите оценку');*/}
			{/*					return false;*/}
			{/*				}*/}
			{/*				else {*/}
			{/*					await setFields({*/}
			{/*						rating: data.rating*/}
			{/*					});*/}
			{/*					history.push('/tasks/date/' + date('Y-n-j', new Date(strtotime(task.ddateReal) * 1000)));*/}
			{/*				}*/}
			{/*			}}*/}
			{/*		/>*/}
			{/*	</div>*/}
			{/*	/!*{printArrows(visibleBlockIterator, ()=>{*!/*/}
			{/*	/!*	if (data.rating == 0) {*!/*/}
			{/*	/!*		toastr.error('Ошибка', 'Выберите оценку');*!/*/}
			{/*	/!*		return false;*!/*/}
			{/*	/!*	}*!/*/}

			{/*	/!*	return true;*!/*/}
			{/*	/!*}, async ()=>{*!/*/}
			{/*	/!*	await setFields({*!/*/}
			{/*	/!*		rating: data.rating*!/*/}
			{/*	/!*	});*!/*/}
			{/*	/!*})}*!/*/}
			{/*	/!*{data.rate !== 0 ? printArrows(visibleBlockIterator) : <div />}*!/*/}


			{/*</div>*/}


			{
				showSignatureWin
					? <SignatureWindow
						title={"Подпись клиента"}
						onSave={(imgData, imgBase64) => {
							console.log('InstallEdit SignatureWindow onSave');
							// console.log(imgBase64);
							// setSignatureImg(imgData);
							setData((prev) => ({
								...prev,
								signature: imgBase64,
								signatureImg: imgData
							}));
							setShowSignatureWin(false);
						}}
						onClose={() => setShowSignatureWin(false)}
					/>
					: null
			}

		</div>
	)

};

export default InstallEdit;

