import React, {Fragment, useState, useEffect} from 'react';
import {Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import {connect} from "react-redux";
import {LoginContainer, ForgetPassword,
	UserEditContainer, UserListContainer,
	DivisionsListContainer, DivisionsEditContainer,
	ClientsContainer, ClientsEditContainer
} from "containers/user";

const UserRouter = props => {

	// let content = null;
	const [content, setContent] = useState(null);

	useEffect(() => {

			let _content = null;
			if (!props.user)
				_content = (
					<Switch>
						{/*регистрации нет, пользователи создаются администратором*/}
						<Route path="/forgetpassword" exact>
							<ForgetPassword {...props} />
						</Route>

						<Route>
							<LoginContainer {...props} />
						</Route>

						{/*<Route component={Page404} />*/}
					</Switch>
				);
			else
			 _content = (
				<Switch>
					<Route path="/user" exact key={"users-all"}>
						<UserListContainer {...props} />
					</Route>
					<Route path="/user/role/:roleName" key={"users-by-role"}>
						<UserListContainer {...props} />
					</Route>
					<Route path="/user/edit/:id/:roleName?" exact={false}>
						<UserEditContainer {...props} />
					</Route>
					<Route path="/user/profile_edit" exact>
						<UserEditContainer {...props} editSelf={true} />
					</Route>
					<Route path="/user/profile/:id" exact>
						<UserEditContainer {...props} edit={false} />
					</Route>
					<Route path="/user/divisions" exact>
						<DivisionsListContainer {...props} />
					</Route>
					<Route path="/user/divisions/edit/:id" exact>
						<DivisionsEditContainer {...props}/>
					</Route>
					<Route path="/user/clients" exact={true}>
						<ClientsContainer {...props} />
					</Route>
					<Route path="/user/clients/:id" exact>
						<ClientsEditContainer {...props}/>
					</Route>
					<Route component={Page404} />
				</Switch>
			);

			setContent(_content);
		},
		[]
	);


	// if (!props.user)
	// 	content = (
	// 		<Switch>
	// 			{/*регистрации нет, пользователи создаются администратором*/}
	// 			<Route path="/forgetpassword" exact>
	// 				<ForgetPassword {...props} />
	// 			</Route>
	//
	// 			<Route>
	// 				<LoginContainer {...props} />
	// 			</Route>
	//
	// 			{/*<Route component={Page404} />*/}
	// 		</Switch>
	// 	);
	// else
	// 	content = (
	// 		<Switch>
	// 			<Route path="/user" exact key={"users-all"}>
	// 				<UserListContainer {...props} />
	// 			</Route>
	// 			<Route path="/user/role/:roleName" key={"users-by-role"}>
	// 				<UserListContainer {...props} />
	// 			</Route>
	// 			<Route path="/user/edit/:id/:roleName?" exact={false}>
	// 				<UserEditContainer {...props} />
	// 			</Route>
	// 			<Route path="/user/profile_edit" exact>
	// 				<UserEditContainer {...props} editSelf={true} />
	// 			</Route>
	// 			<Route path="/user/profile/:id" exact>
	// 				<UserEditContainer {...props} edit={false} />
	// 			</Route>
	// 			<Route path="/user/divisions" exact>
	// 				<DivisionsListContainer {...props} />
	// 			</Route>
	// 			<Route path="/user/divisions/edit/:id" exact>
	// 				<DivisionsEditContainer {...props}/>
	// 			</Route>
	// 			<Route path="/user/clients" exact={true}>
	// 				<ClientsContainer {...props} />
	// 			</Route>
	// 			<Route path="/user/clients/:id" exact>
	// 				<ClientsEditContainer {...props}/>
	// 			</Route>
	// 			<Route component={Page404} />
	// 		</Switch>
	// 	);

	return (
		<Fragment>
			{content}
		</Fragment>
	)

};

const mapStateToProps = store => ({
	user: store.user
});

export default connect(mapStateToProps, null)(UserRouter);
// export default UserRouter;
