import React, {createRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";
import {in_array, string2num} from "utils/func";
import InputMask from 'react-input-mask';
import {DEBUG} from "utils/config";

export const FORM_ITEM_CONST = {
	labelNbsp: '\u00A0'
};

const FormItem = props => {

	const {
		edit = true,
		label, name,
		labelLeft = false,
		isRequire = false,
		defVal = "",
		cls="",

		fieldType = 'text', hidden = false,
		ico = null, descr = '',
		numericRound = null,//int, округлять числовые значения
		reff = null,
		options = [], optionsComponent = null,
		optionsMap = new Map(),
		changeAction = (e, val) => {},
		blurAction = (e, val) => {},
		hideFields = [],
		//предустановленные значения поля, можно выбрать
		predefined = [],
		predefinedComponent = null,
		//дописываются в конце
		predefinedAdd = false,
		predefinedAddSeparator = '. ',
		//маска, для даты например
		mask = null,
		placeholder = null,
		isChecked = false,
		radioInNewLine = true,
		icoRight = null,//это объект {ico: string, onClick: func}
		noBgr=false,//без фона, прозрачный фон , иначе он белый
	} = props;

	const _ref = reff ? reff : createRef();


	// if (name === 'txt') {
	// 	console.log(name, 'defVal=', defVal);
	// 	console.dir(options);
	// }

	const [fieldValue, setFieldValue] = useState(defVal);


	useEffect(() => {

		setFieldValue(defVal);
	}, [defVal]);

	if (hidden || fieldValue === null || in_array(name, hideFields)) {
		if (DEBUG) {
			return <div className="">
				поле {name} скрыто
			</div>;
		}
		else
			return null;
	}




	const changeAct = (e, val) => {
		let result = changeAction(e, val);

		if (result !== undefined)
			setFieldValue(result);
	};

	let _descr = descr,
	_label = label;
	if (predefined.length && descr === '')
		_descr = (
			<div className="predefined">
				{
					predefined.map((v,i) => {
						return (
							<span
								key={"fi-prdef-" + i}
								onClick={e => {
									// console.log('click');
									let fieldValueNew = fieldValue;
									if (predefinedAdd) {
										fieldValueNew += (fieldValueNew !== '' ? predefinedAddSeparator : '') + v;
									}
									else
										fieldValueNew = v;

									setFieldValue(fieldValueNew);
									changeAct({
										target: {
											name: name,
											value: fieldValueNew
										}
									});
								}}
							>
								{v}
							</span>
						)
					})
				}
			</div>
		);

	if (predefinedComponent !== null) {
		_descr = <div className="predefined">{predefinedComponent}</div>
	}

	if (!edit)
		_descr = null;


	let field = (
		<p className={"input"}>
			undefined fieldType "{fieldType}"
		</p>
	);

	const onBlur = e => {
		let thisVal = e.target.value;
		if (numericRound !== null) {
			thisVal = string2num(thisVal, numericRound);
			setFieldValue(thisVal);
		}

		blurAction(e, thisVal);
	};

	if (in_array(fieldType, ['text', 'email', 'color', 'tel', 'number', 'password', 'file', 'files', 'date', 'datetime-local', 'time'])) {



		field = <input
				type={fieldType === 'files' ? 'file' : fieldType}
				name={name}
				// defaultValue={defVal}
				value={fieldValue}
				// value={defVal}
				onChange={e => {
					setFieldValue(e.target.value);
					changeAct(e, e.target.value);
				}}
				onBlur={e => onBlur(e)}
				ref={_ref}
				placeholder={placeholder}
				disabled={!edit}
				multiple={fieldType === 'files'}
				autoComplete={fieldType === 'password' ? 'current-password' : 'off'}
			/>;
		if (mask) {
			field = <InputMask
				mask={mask}
				placeholder={placeholder}
				type={fieldType}
				name={name}
				value={fieldValue}
				onChange={e => {
					setFieldValue(e.target.value);
					changeAct(e, e.target.value);
				}}
				onBlur={e => onBlur(e)}
				ref={_ref}
				disabled={!edit}
			/>;
		}
	}
	else if (in_array(fieldType, ['checkbox', 'bool'])) {
		_label = null;
		field = (
			<label className={"flx -alc"}>
				<input
					type={"checkbox"}
					name={name}
					defaultValue={defVal}
					// value={fieldValue}
					// value={defVal}
					onChange={e => {
						// console.dir(e.target);
						// setFieldValue(e.target.value);
						changeAct(e, e.target.value);
					}}
					ref={_ref}
					disabled={!edit}
					// checked={isChecked}
					defaultChecked={isChecked}
					onBlur={e => onBlur(e)}
				/>
				<b>{label}</b>
			</label>
		);
	}
	else if (in_array(fieldType, ['textarea', 'area', 'txt']))
		field = (
			<textarea name={name}
					  // defaultValue={fieldValue}
					  value={fieldValue}
					  ref={_ref}
					  onChange={e => {
						  setFieldValue(e.target.value);
						  changeAct(e, e.target.value);
					  }}
					  onBlur={e => onBlur(e)}
					  disabled={!edit}
					  className={cls}
		></textarea>
		);
	else if (fieldType === 'select') {

		let optionsHtml = [];
		if (optionsComponent)
			optionsHtml = optionsComponent;
		else if (optionsMap.size) {
			optionsMap.forEach((val, key) => {
				optionsHtml.push(
					<option
						key={name + "-opt-" + key}
						value={key}
						style={val.selectStyle ? val.selectStyle : null}
						disabled={val.disabled !== undefined ? val.disabled : false }
					>
						{val.value}
					</option>
				);
			});
		}
		else {

			Object.keys(options).map(key => {
				optionsHtml.push(
					<option
						key={name + "-opt-" + key}
						value={key}
						style={options[key].selectStyle ? options[key].selectStyle : null}
						disabled={options[key].disabled !== undefined ? options[key].disabled : false }
					>
						{options[key].value}
					</option>
				);
			});
		}

		field = (
			<select
				name={name}
				// defaultValue={fieldValue}
				value={fieldValue}
				onChange={e => {
					setFieldValue(e.target.value);
					changeAct(e, e.target.value);
				}}
				ref={_ref}
				disabled={!edit}
			>
				{optionsHtml}
			</select>
		);
	}
	else if (fieldType === 'radio') {
		let radioValues = [];

		if (options.length || Object.keys(options).length) {
			Object.keys(options).map(key => {
				radioValues.push(
					<div className={cn("fi-field-radio", {inline: !radioInNewLine})} key={name + key}>
						<label>
							{/*{options[key].ico ? <div className="radio-ico">*/}
							{/*	<i className={"fa fa-" + options[key].ico}></i>*/}
							{/*</div> : null}*/}
							<input
								type={"radio"}
								name={name}
								value={key}
								onChange={e => {
									setFieldValue(e.target.value);
									changeAct(e, e.target.value);
								}}
								ref={_ref}
								placeholder={placeholder}
								disabled={!edit}
								defaultChecked={key === defVal}
							/>
							{options[key].ico ? <div className="radio-ico">
								<i className={"fa fa-" + options[key].ico}></i>
							</div> : null}
							{options[key].value}
						</label>
					</div>
				);

				return null;
			});

			field = radioValues;
		}
		else
			field = <div className="clr-red">
			!!! Для типа поля RADIO не задан массив опций options
			</div>

	}
	else// if (typeof fieldType === 'object')
		field = fieldType;

	// console.log(name + '/' + typeof fieldType );

	const fieldWrap = (
		<div className={cn("fi-field", {
			withIco: ico,
			withIcoRight: icoRight !== null,
			'no-bgr': noBgr
		})}>
			{ico ? (
				<div className="-ico">
					<i className={"fa fa-" + ico}></i>
				</div>
			): ''}
			{field}
			{icoRight ? <div
				className="-icor"
				onClick={e => {
					if (icoRight.onClick)
						icoRight.onClick(e, _ref.current);
				}}
				title={icoRight.tip && icoRight.tip}
			>
				<i className={"fa fa-" + icoRight.ico}></i>
			</div> : null}
		</div>
	)

	return (
		<div className={cn("form-item", cls, {disabled: (!edit), labelLeft: labelLeft})}>
			{
				_label ? (
					<label>
						{_label}
						{isRequire && '*'}
					</label>
				) : null
			}

			{fieldWrap}

			{
				_descr !== '' ? (
					<div className="descr">
						{_descr}
					</div>
				) : ''
			}
		</div>
	)
};
FormItem.propTypes = {
	// edit: PropTypes.bool.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	name: PropTypes.string.isRequired,
	isRequire: PropTypes.bool,
	defVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fieldType: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};
export default FormItem;
