import React, {useState} from 'react';
import {FormItem, Tabs} from "components/_common";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";



const Notifications = props => {
	const {settings,
		setSavedSettings,
		divisions
		// userRoles
	} = props;

	// console.dir(settings);
	// const [texts, setTexts] = useState({
	// 	email_manager_get_order: ''
	// });

	const patterns = {
		name: 'Имя покупателя',
		from_domain: 'Домен сайта, откуда пришли данные (например westcom.ru), без http(s)://',
		manager_name: 'Имя менеджера',
		manager_phone: 'Телефон менеджера',
		manager_email: 'Email менеджера'
	};
	const patternsContent = [];
	for (let i in patterns) {
		patternsContent.push(
			<p key={i}>
				<b>[{i}]</b> - {patterns[i]}
			</p>
		);
	}
	// ClassicEditor.builtinPlugins.map( plugin => console.log(plugin.pluginName) );

	// const editorToolbar = [
	// 	'heading',
	// 	'|',
	// 	'bold',
	// 	'italic',
	// 	'blockQuote',
	// 	'link',
	// 	'numberedList',
	// 	'bulletedList',
	// 	'imageUpload',
	// 	'|',
	// 	// 'code', '|',
	// 	'undo',
	// 	'redo'
	// ];

	// console.dir(divisions);

	let domains = [];
	for (let divId in divisions) {
		let division = divisions[divId];
		// console.log(divId, division);
		if (division.domains.length) {
			division.domains.map(domain => {
				// console.log(domains);
				if (domains.indexOf(domain) == -1)
					domains.push({
						title: domain,
						postfix: domain
					});
			});
		}
	}
	const tabsData = [];

	// if (domains.length== 0)
		domains.push({
			title: 'По ум. для всех',
			postfix: ''
		});
	domains.map(item => {
		let emailManOrderVar = "email_manager_get_order" + item.postfix;
		let emailManFormVar = "email_manager_get_form" + item.postfix;
		tabsData.push({
			title: item.title,
			content: (
				<>
					<FormItem
						label={"Письмо покупателю о том что Заказ принял менеджер"}
						name={emailManOrderVar}
						fieldType={'textarea'}
						cls={"textarea-big"}
						defVal={settings[emailManOrderVar] ? settings[emailManOrderVar] : ''}
						changeAction={(e, val) => {

							setSavedSettings(prev => ({
								...prev,
								[emailManOrderVar]: val
							}));
						}}
					/>


					<FormItem
						label={"Письмо покупателю о том что Форму принял менеджер"}
						name={emailManFormVar}
						fieldType={'textarea'}
						cls={"textarea-big"}
						defVal={settings[emailManFormVar] ? settings[emailManFormVar] : ''}
						changeAction={(e, val) => {

							setSavedSettings(prev => ({
								...prev,
								[emailManFormVar]: val
							}));
						}}
					/>
				</>
			)
		});
	});




	return (
		<>
			<Tabs
				data={tabsData}
			/>
			{/*<FormItem*/}
			{/*	label={"Письмо покупателю о том что Заказ принял менеджер"}*/}
			{/*	name={"email_manager_get_order"}*/}
			{/*	// fieldType={<CKEditor*/}
			{/*	// 	editor={ ClassicEditor }*/}
			{/*	// 	config={{*/}
			{/*	// 		toolbar: editorToolbar*/}
			{/*	// 	}}*/}
			{/*	// 	data={settings.email_manager_get_order ? settings.email_manager_get_order : ''}*/}
			{/*	// 	onChange={ ( event, editor ) => {*/}
			{/*	// 		setSavedSettings(prev => ({*/}
			{/*	// 			...prev,*/}
			{/*	// 			email_manager_get_order: editor.getData()*/}
			{/*	// 		}));*/}
			{/*	// 	} }*/}
			{/*	// 	isEnable={false}*/}
			{/*	// />}*/}
			{/*	fieldType={'textarea'}*/}
			{/*	cls={"textarea-big"}*/}
			{/*	defVal={settings.email_manager_get_order ? settings.email_manager_get_order : ''}*/}
			{/*	changeAction={(e, val) => {*/}

			{/*		setSavedSettings(prev => ({*/}
			{/*			...prev,*/}
			{/*			email_manager_get_order: val*/}
			{/*		}));*/}
			{/*	}}*/}
			{/*/>*/}


			{/*<FormItem*/}
			{/*	label={"Письмо покупателю о том что Форму принял менеджер"}*/}
			{/*	name={"email_manager_get_form"}*/}
			{/*	fieldType={'textarea'}*/}
			{/*	cls={"textarea-big"}*/}
			{/*	defVal={settings.email_manager_get_form ? settings.email_manager_get_form : ''}*/}
			{/*	changeAction={(e, val) => {*/}

			{/*		setSavedSettings(prev => ({*/}
			{/*			...prev,*/}
			{/*			email_manager_get_form: val*/}
			{/*		}));*/}
			{/*	}}*/}
			{/*/>*/}
			<div className="mt20">
				<h3>Шаблоны подстановки:</h3>
				{patternsContent}
			</div>
		</>
	)

};

export default Notifications;
