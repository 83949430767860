import React from 'react';
import cn from "classnames";

const TableSortArrow = ({sort, field}) => {

    return (
        <div className={cn("tbl-sort", {active: sort.field === field})}>
            <i className={cn("fa", {
                "fa-caret-down": sort.asc,
                "fa-caret-up": !sort.asc
            })}></i>
        </div>
    )

};

export default TableSortArrow;