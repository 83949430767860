import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";
import date from "php-date";
import {bindActionCreators} from "redux";
import {setCalendarDates, setCalendarSelectDateAction} from "actions/GUIActions";
import * as strtotime from "strtotime";
import {Button} from "components/_common";

export const ContentHeaderDateType = {
	date: 'date',
	month: 'month'
};

const ContentHeader = (props) => {

	const {title = '', parent = [], titleEnds = '',
		GUI, actions,
		// даты
		showDates = false, datesAction, dateType = ContentHeaderDateType.date
	} = props;
	// console.dir(GUI.calendarDates);

	const dateStart = date('Y-m-d', GUI.calendarDates.start);
	const dateEnd = date('Y-m-d', GUI.calendarDates.end);

	const [dateStartInput, setDateStartInput] = useState(dateStart);
	const [dateEndInput, setDateEndInput] = useState(dateEnd);
	// const [stateDateStart, setDateStart] = useState(strtotime(dateStart));
	const [stateDateEnd, setDateEnd] = useState(strtotime(dateEnd));

	const [stateDateStart, setDateStart] = useState(GUI.calendarDates.start);
	// console.log(GUI.calendarDates.start);
	// console.log(stateDateStart);
	// console.log(dateEnd, stateDateEnd);

	const dateDiff = () => {
		let ds = stateDateStart;
		if (typeof ds == 'object') {
			let minusSeconds = ds.getHours() * 3600 + ds.getMinutes() * 60 + ds.getSeconds();
			// console.log(minusSeconds);
			ds = (stateDateStart.getTime() - minusSeconds * 1000);
		}
		// console.log(stateDateStart, ds, stateDateStart.getMilliseconds());
		// console.log(new Date(ds));
		let de = stateDateEnd;
		if (typeof de == 'object') {
			// let minusSeconds = de.getHours() * 3600 + de.getMinutes() * 60 + de.getSeconds();
			// console.log(minusSeconds);
			de = de.getTime();
		}
		if(de.toString().length == 10)
			de = de * 1000;

		// console.log(ds, de, ds > de);
		// console.log(new Date(ds), new Date(de));
		if (ds > de) {
			alert('Выбран неверный промежуток времени');
			return false;
		}

		return true;
	};

	//даты для печати
	let titlePrintDates = '';
	if (dateStart !== dateEnd )
		titlePrintDates = 'с ' + date('d.m.Y', GUI.calendarDates.start) + ' по ' + date('d.m.Y', GUI.calendarDates.end);
	else
		titlePrintDates = `за ` + date('d.m.Y', GUI.calendarDates.start);


	let datesSelect = null;
	if (showDates)
		datesSelect = (
			<div className="panel-top flx -e -alc no-print">
				<div className="flx -alc -dates">
					<span className={"mobile-hide"}>Вывести&nbsp;</span>
					c &nbsp;
					<div className="panel-top-dates-item">
						<input
							type={dateType}
							name="start"
							value={dateStartInput}
							// value={date('Y-m-d', stateDateStart)}
							// defaultValue={dateStartInput}
							onChange={(e) => {
								// console.log(e.target.value);
								setDateStartInput(e.target.value);
								// dateDiff(e.target.name);

							}}
							onBlur={e => {

								const v = strtotime(e.target.value + ' 00:00:00');

								setDateStart(v);
								// console.log('CH date start value', e.target.value, v);
								actions.setCalendarDates({
									...GUI.calendarDates,
									start: new Date( v * 1000)
								});
							}}
						/>
					</div>
					&nbsp; по  &nbsp;
					<div className="panel-top-dates-item">
						<input
							type={dateType}
							name="end"
							value={dateEndInput}
							// defaultValue={dateEndInput}
							onChange={(e) => {
								setDateEndInput(e.target.value);

							}}
							onBlur={e => {
								const v = strtotime(e.target.value + ' 23:59:59');
								setDateEnd(v);
								actions.setCalendarDates({
									...GUI.calendarDates,
									end: new Date(v * 1000)
								});
							}}
						/>
					</div>
				</div>
				<Button
					type={"apply"}
					title={"Показать"}
					ico={""}
					onClick={() => {
						if (dateDiff() && typeof datesAction == 'function') {
							datesAction(GUI.calendarDates);
						}
						//чет ХЗ нигжде не применяется кажись
						// else
						// 	actions.setCalendarSelectDateAction(GUI.calendarDates);
					}}
				/>
				{/*<div*/}
				{/*	className={""}*/}
				{/*	// className="mobile-mt20"*/}
				{/*>*/}
				{/*	*/}
				{/*	<div className="btn" onClick={() => {*/}
				{/*		if (dateDiff() && typeof datesAction == 'function') {*/}
				{/*			datesAction();*/}
				{/*		}*/}
				{/*	}}>*/}
				{/*		Показать*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		);

	useEffect(() => {

			// console.log('update in effect', showDates);

			setDateStartInput(date('Y-m-d', GUI.calendarDates.start));
			setDateEndInput(date('Y-m-d', GUI.calendarDates.end));

			return () => {
				// datesSelect = null;
			}
		},
		[
			GUI.calendarDates.start
		]
	);


	return (
		<div className="content-header  flx -sb -alc">
			<div className="content-title">
				{parent.map((item, i) => {
					return <NavLink to={item.url} key={i}><span>{item.name}</span></NavLink>
				})}
				<h1>{title} <span className={"print-only"}>{titlePrintDates}</span>{titleEnds}</h1>
			</div>

			{datesSelect}
		</div>
	);
};

ContentHeader.propTypes = {
	title: PropTypes.oneOfType([PropTypes.element,PropTypes.string]).isRequired,
	parent: PropTypes.arrayOf(PropTypes.shape({
		url: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})),
	showDates: PropTypes.bool
	// children: PropTypes.node.isRequired,
	// footer: PropTypes.oneOfType([PropTypes.element,PropTypes.string]),
	// winClass: PropTypes.string,
	// onClose: PropTypes.func,
};

const mapStateToProps = store => ({
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		setCalendarDates, setCalendarSelectDateAction
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader);
// export default ContentHeader;
