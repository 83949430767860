import axios from "axios";
import {API_URL} from "utils/config";
import {toastr} from "react-redux-toastr";


export default axios.create({
    baseURL: API_URL,
    responseType: "json",
    timeout: 30000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
        // "Content-Type": "multipart/form-data"
        // "Access-Control-Allow-Origin": "*"
    }
});

export const apiAction = (res, callback, final = null, showToastMessage = true) => {

    const showToastr = (data, type, timeout = 10000) => {
        let title;

        switch(type) {
            case "info":
                title = 'Сообщение';
                break;
            case "warning":
                title = 'Предупреждение';
                break;
            case "error":
                title = 'Ошибка';
                break;
            default:
                toastr.error('apiAction error', 'Нет обработчика для вывода сообщений типа ' + type, {timeOut: 0});
                return true;
                break;
        }

        if (typeof data == 'object') {
            if (data.length) {
                data.map(t => {
                    if (t != '')
                        toastr[type](title + ' (сервер)', t, {timeOut: timeout});
                });

                return true;
            }
        }
        else if (data !== '') {
            toastr[type](title + ' (сервер)', data, {timeOut: timeout});
            return true;
        }

        return false;
    };



    if (res && res.data) {
        console.log('server response', res.data.time);
        if (!showToastr(res.data.error, 'error', 0)) {
            if (typeof callback == 'function')
                callback(res.data.response);
        }

        if (showToastMessage) {
            showToastr(res.data.info, 'info');
            showToastr(res.data.warning, 'warning');
        }

    }
    else
        toastr.error('Ошибка сервера (js)', 'Данные не получены', {timeOut: 0});

    if (typeof final == 'function')
        final(res);
};
