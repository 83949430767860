import React from 'react';

const Page404 = props => {
	const {text = ''} = props;
	return(
		<div className="page-404">
			{text !== '' ? text : 'Страница не найдена'}
		</div>
	)
}
export default Page404;