import {Storage} from "utils/Storage";

export const NOW_DATE = 'NOW_DATE';
export const GUI_MENU_TOGGLE = 'GUI_MENU_TOGGLE';
export const GUI_SIDE_MENU_OPEN_STORAGE_KEY = 'sideBarOpen';

export const GUI_SET_DATES = 'GUI_SET_DATES';
export const GUI_SET_DATES_ACTION = 'GUI_SET_DATES_ACTION';
export const GUI_SET_IS_PRINT = 'GUI_SET_IS_PRINT';
export const GUI_SET_SETTINGS = 'GUI_SET_SETTINGS';

export const GUI_SET_CALENDAR_SELECT_DATE_ACTION = 'GUI_SET_CALENDAR_SELECT_DATE_ACTION';
export const GUI_SET_SEARCH_ACTION = 'GUI_SET_SEARCH_ACTION';
export const GUI_SET_SOCKET = 'GUI_SET_SOCKET';
export const GUI_SET_TOPBAR_ACTIONS = 'GUI_SET_TOPBAR_ACTIONS';
export const GUI_SET_MENU_COUNTERS = 'GUI_SET_MENU_COUNTERS';

export const sideBarToggle = (isOpen) => {

	Storage.set(GUI_SIDE_MENU_OPEN_STORAGE_KEY, isOpen);
	return {
		type: GUI_MENU_TOGGLE,
		payload: {
			sideBarOpen: isOpen
		}
	}
};

export const setCalendarDates = (dates) => {
	// console.log('-----setCalendarDates-----');
	// console.log(new Date(dates.start).getTime());

	// console.log(dates.start, dates.end);

	let nd = dates.start;
	if (typeof dates.start == 'object')
		nd = new Date(dates.start).getTime();
	// console.log('-----ACTION GUI setCalendarDates-----', nd);
	// Storage.session_set(NOW_DATE, nd);
	// console.dir(dates);
	return {
		type: GUI_SET_DATES,
		payload: dates
	}
};
export const setIsPrint = (isPrint) => {
	return {
		type: GUI_SET_IS_PRINT,
		payload: isPrint
	}
};
export const setSettings = (key, val) => {
	return {
		type: GUI_SET_SETTINGS,
		payload: {
			key: key,
			value: val
		}
	}
};
export const setCalendarSelectDateAction = (func) => {
	return {
		type: GUI_SET_CALENDAR_SELECT_DATE_ACTION,
		payload: func
	}
};
export const setSearchAction = (func) => {
	return {
		type: GUI_SET_SEARCH_ACTION,
		payload: func
	}
};
export const setSocket = (val) => {
	return {
		type: GUI_SET_SOCKET,
		payload: val
	}
};
export const setTopbarActions = (val) => {
	return {
		type: GUI_SET_TOPBAR_ACTIONS,
		payload: val
	}
};
export const setMenuCounters = (key, val) => {
	return {
		type: GUI_SET_MENU_COUNTERS,
		payload: {[key]: val}
	}
};
// export const doSearch = str => {
// 	return {
// 		type: GUI_SET_SEARCH_ACTION,
// 		payload: func
// 	}
// };
