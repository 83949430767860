import React, {useEffect, useState} from 'react';
import cn from "classnames";
import ManagerSelect from "./ManagerSelect";
import {TablesBgr, PanelColorSelect} from "./utils";
import {DEBUG} from "utils/config";
import {dateFormat, isModerator, isUser, diffDays, in_array, array_unset_value} from "utils/func";
import BillsFiles from "./BillsFiles";
import {Button} from "../_common";
// import BillsMonths from "./BillsMonths";

const selectedCellInit = {
    rowId: 0,
    columnId: ''
};

const BillsTable = ({
                        user, tab, columns,
                        managers, managersFilter,
                        data, onSave,
                        doTablesApiAction,
                        // getData,
                        sort, sortBy,
                        // GUI,
                        dates,
                        billsMonths, MyBillsCheckbox,
                        rowsBackground, setRowsBackground,
                        cellsBackground, setCellsBackground
                    }) => {
    // console.clear();
    // console.log('BillsTable init', tab.id, dates);



    const tabId = tab.id;
    let isModer = isModerator(user);
    let isCassir = isUser(user);
    let isModerOrCassir = isModer || isCassir;


    const [selectedIdList, setSelectedIdList] = useState([]);
    const [selectedCell, setSelectedCell] = useState(selectedCellInit);

    const [filesWin, setFilesWin] = useState(null);

    //фоны
    /*
    let rowsBackgroundInit = {};
    let cellsBackgroundInit = {};
    data.map(row => {
        if (row.bgr) {
            rowsBackgroundInit[row.id] = row.bgr;
            // console.log(row.id, row.bgr);
        }
        //TODO цвета столбцов
        //  console.log(row.id, row.bgrCells, typeof row.bgrCells);
        if (row.bgrCells) {
            cellsBackgroundInit[row.id] = row.bgrCells;

            console.log(row.id, row.bgrCells);
            // for (let cid in row.bgrCells) {
            //
            // }
        }
    });

    const [rowsBackground, setRowsBackground] = useState(rowsBackgroundInit);
    const [cellsBackground, setCellsBackground] = useState(cellsBackgroundInit);*/

    useEffect(() => {
            // console.log(`BillsTable useEffect`);
            setSelectedIdList([]);
            // return () => {
            //
            // }
        },
        [data]
    );

    //пока косяк с обновлением фонов
    // let rowsBackgroundInitHash = sha256(JSON.stringify(rowsBackgroundInit));
    // let cellsBackgroundInitHash = sha256(JSON.stringify(cellsBackgroundInit));
    // useEffect(() => {
    //
    //         // let hashInit = sha256(JSON.stringify(rowsBackgroundInit));
    //         // let hash = sha256(JSON.stringify(rowsBackground));
    //         // console.log('useEffect hashes', hashInit == hash);
    //         // if (hashInit !== hash)
    //         setRowsBackground(rowsBackgroundInit);
    //         setCellsBackground(cellsBackgroundInit);
    //
    //     },
    //     [rowsBackgroundInitHash, cellsBackgroundInitHash]
    // );

    // console.log(selectedColor);

    /*
    * не используется
    */
    /*
    const file2email = async (fileIdList, managerId) => {
        console.clear();
        console.log(fileIdList, managerId);

        await doTablesApiAction({
            action: 'sendFilesToEmail',
            fileIdList: fileIdList,
            sendUserId: managerId
        }, () => {

        });
    };
    */

    const send2email = async (row) => {
        // console.clear();
        // console.dir(row);
        let canSend = false;

        let comment = row.comment || '';
        if (parseInt(row.send2email)) {
            comment = prompt('Комментарий', comment);
            canSend = true;
            // if (!comment) {
            //   alert('Отправка отменена, впишите комментарий')
            // }
        }
        else {
            canSend = window.confirm('Подтвердите действие');
        }

        if (canSend) {
            await doTablesApiAction({
                    action: 'sendToEmail',
                    userId: user.id,
                    tableName: 'bills',
                    data: {
                        ...row,
                        sendComment: comment
                    }
                },
                null,
                null,
                true
            );
        }


    }

    console.log('cellsBackground has 3222', cellsBackground[3222]);

    const billsCell = (columnId, row) => {

        let result = row[columnId] ? row[columnId] : null;
        let style = {};
        let tdClass = [];
        let canEdit = true;
        let canSetBgr = true;

        if (rowsBackground.hasOwnProperty(row.id) && rowsBackground[row.id] == TablesBgr.red)
            style = {...style, color: `#fff`};


        // let cellHasBackground = false;



        if (cellsBackground.hasOwnProperty(row.id) && cellsBackground[row.id].hasOwnProperty(columnId)) {
            style = {...style, backgroundColor: `#${cellsBackground[row.id][columnId]}`};

            if (cellsBackground[row.id][columnId] == TablesBgr.red)
                style['color'] = '#fff';

            // cellHasBackground = true;
        }
        else if (row.bgr) {
            style = {...style, backgroundColor: `#${row.bgr}`};
            if (row.bgr == TablesBgr.red)
                style['color'] = '#fff';
        }

        switch (columnId) {
            case "manager_id":
                canEdit = false;
                result = <ManagerSelect
                    managers={managers}
                    divisions={tab.divisions ?? []}
                    value={result ? result : 0}
                    onChange={val => {
                        onSave(row.id, tabId, columnId, val);
                        console.log(row.id, tabId, columnId, val);
                    }}
                />
                break;
            case "created":
                canEdit = false;
                result = [];
                result.push(<div
                    key={`td-${tabId}-${row.id}-${columnId}-created-date`}
                    className={"-dt"}>
                    {dateFormat(row.created)}
                </div>);
                if (row.summa == '' && diffDays(new Date(row.created), Date.now()) >= 4)
                    result.push(<div
                        className={"bills-call"}
                        key={`td-${tabId}-${row.id}-${columnId}-created`}
                    >Обзвонить</div>);
                break;
            case "bill_number":
                if (!isModerOrCassir)
                    canEdit = false;
                break;
            case "files":
                canEdit = false;


                let sendBillBtn = null;
                if (row.manager_id !== 0 && isModerator(user)) {
                    let send2emailDateInt = parseInt(row.send2email);
                    sendBillBtn = (
                        <div style={{marginTop: 10}}>
                            <Button
                                // title={`Отправить счет на ${emlIco}`}
                                title={
                                    // <div className={"flx -c -alc"}>
                                    //
                                    // </div>
                                    <>
                                        <i className={"fa fa-envelope"}/> Отправить
                                    </>
                                }
                                cls={cn("tables-bills-btn-sen2email", {"-lgrey": (send2emailDateInt > 0)})}
                                // onClick={() => file2email(row.billsFiles, row.manager_id)}
                                onClick={() => send2email(row)}
                            />
                            {
                                send2emailDateInt > 1
                                    ? <div className={"tables-bills-btn-sen2email-date"}>
                                        {dateFormat(send2emailDateInt)}
                                    </div>
                                    : null
                            }
                        </div>
                    );
                }


                result = <div className={"bills-files-wrp"}>
                    <i
                        className={"fa fa-files-o"}
                        title={"Скачать/загрузить файлы"}
                        onClick={() => setFilesWin(
                            <BillsFiles
                                // user={user}
                                onClose={() => setFilesWin(null)}
                                data={row}
                            />
                        )}
                    />

                    {sendBillBtn}
                    {
                        (row.comment)
                            ? <div className={"comment"}>
                                {row.comment}
                            </div>
                            : null
                    }
                </div>

                if (
                    (!row.rekvFiles || row.rekvFiles.length == 0)
                    &&
                    (!row.billsFiles || row.billsFiles.length == 0)
                )
                    tdClass.push('no-files');

                if (!isCassir) {
                    canSetBgr = false;
                }
                // if (cellHasBackground === false)
                // {
                //     style = {backgroundColor: '#fff'};
                // }

                break;
        }


        return <td
            key={`td-${tabId}-${row.id}-${columnId}`}
            contentEditable={canEdit}
            suppressContentEditableWarning={true}
            onBlur={e => {
                if (canEdit) {
                    // console.clear();
                    // console.log(row.id, tabId, columnId, e.currentTarget.innerText);
                    // console.dir(data);
                    let canSave = false;
                    for (let i in data) {
                        let _row = data[i];
                        if (_row.id == row.id && row[columnId] != e.currentTarget.innerText)
                            canSave = true;
                    }

                    if (canSave)
                        onSave(row.id, tabId, columnId, e.currentTarget.innerText);
                }

            }}
            style={style}

            className={cn(`col-${columnId}`, tdClass, {
                // whiteText: row.bgr == TablesBgr.red
            })}
            onClick={() => {
                console.log('onfocus', columnId);
                if (canSetBgr)
                    setSelectedCell({rowId: row.id, columnId: columnId});
            }}
        >
            {result}
        </td>;
    }

    const setBgr = async (clr) => {
        console.log('setBgr', clr);
        console.log(selectedIdList);
        console.log(selectedCell);


        if (selectedIdList.length == 0 && selectedCell.rowId == 0) {
            console.log('>> NOT setBgr');
            return;
        }


        let cellsBackgroundNew = {...cellsBackground};

        // return;

        await doTablesApiAction({
            action: 'setTableBackgrounds',
            color: clr,
            idList: selectedIdList,
            selectedCell: selectedCell
        }, (res, setData, getData) => {

            console.dir(res);

            return;
            //выделение строк
            if (selectedIdList.length != 0) {
                let rowsBackgroundNew = {...rowsBackground};

                selectedIdList.map(rid => rowsBackgroundNew[rid] = clr);

                //затираем ячейки
                for (let rowId in rowsBackgroundNew) {
                    // console.log(rowId);
                    if (cellsBackgroundNew.hasOwnProperty(rowId))
                        delete cellsBackgroundNew[rowId];
                }

                setRowsBackground(rowsBackgroundNew);
                setCellsBackground(cellsBackgroundNew);

                setSelectedIdList([]);

            }
            //выделение ячеек
            else if (selectedCell.rowId != 0) {

                if (clr == TablesBgr.white) {
                    if (cellsBackgroundNew.hasOwnProperty(selectedCell.rowId))
                        delete cellsBackgroundNew[selectedCell.rowId][selectedCell.columnId];
                } else {
                    if (!cellsBackgroundNew.hasOwnProperty(selectedCell.rowId))
                        cellsBackgroundNew[selectedCell.rowId] = {};

                    if (clr)
                        cellsBackgroundNew[selectedCell.rowId][selectedCell.columnId] = clr;
                }
                setCellsBackground(cellsBackgroundNew);
                setSelectedCell(selectedCellInit);
            }


        });
    }


    return (
        <>
            <div className={"tables-filter"}>
                {billsMonths}
                <div className={"flx -sb -alc"}>
                    <div
                        className={"tables-add-row -top tables-filter-item"}
                        onClick={() => onSave(0, tabId, '', '')}
                    >
                        + добавить счет
                    </div>

                    <div className={"flx -sb -alc"}>
                        {MyBillsCheckbox}
                        {managersFilter}
                    </div>
                </div>


            </div>
            <table className={"tables-table tbl dark hdr-cntr -bordered"} id={"bills-table"}>
                <thead>
                <tr>
                    <td className={"col-num"} width={"3%"}>
                        &nbsp;
                    </td>
                    {
                        columns.map((col, i) => {
                            return (
                                <td
                                    key={`tr-${tabId}-${i}`}
                                    width={`${col.width}%`}
                                    // className={cn(`col-${col.id}`, {sortable: col.sort})}
                                    className={`col-${col.id}`}
                                    onClick={() => {
                                        if (col.sort && sortBy)
                                            sortBy(col.id)
                                    }}
                                >
                                    {col.name}
                                    {
                                        (sort && col.sort)
                                            ? (
                                                <div
                                                    className={cn("tbl-sort", {active: sort.field === col.id})}
                                                >
                                                    <i className={cn("fa", {
                                                        "fa-caret-down": sort.asc,
                                                        "fa-caret-up": !sort.asc
                                                    })}></i>
                                                </div>
                                            ) : null
                                    }
                                </td>
                            );
                        })
                    }

                </tr>
                </thead>
                <tbody className={"tbody"}>

                {
                    data.map((row, i) => {

                        let isNew = false;
                        if (new Date(row.created) >= (Date.now() - 15 * 60000))
                            isNew = true;

                        let style = null;
                        if (rowsBackground.hasOwnProperty(row.id))
                            style = {backgroundColor: `#${rowsBackground[row.id]}`};

                        // if (row.id == 127) {
                        //     console.log(rowsBackgroundInit[127]);
                        //     console.log(rowsBackground[127]);
                        //
                        //     console.log(style);
                        // }

                        return (
                            <tr
                                key={`tr-${tabId}-${i}`}
                                className={cn({
                                    selected: in_array(row.id, selectedIdList),
                                    isNew: isNew
                                })}
                                style={style}
                            >
                                <td
                                    key={`td-${tabId}-${row.id}-num`}
                                    className={"col-num"} width={"3%"}
                                    onClick={() => {
                                        if (isModerOrCassir) {
                                            let selectedIdListNew = [...selectedIdList];

                                            if (!in_array(row.id, selectedIdList))
                                                selectedIdListNew.push(row.id);
                                            else
                                                selectedIdListNew = array_unset_value(selectedIdListNew, row.id);

                                            setSelectedIdList(selectedIdListNew);
                                        }
                                    }}

                                >
                                    {(i + 1)}
                                    {
                                        DEBUG ? <>
                                            <br/>
                                            <span style={{fontSize: '9px'}}>
                                                id={row.id}
                                                <br/>
                                                taId={row.tabId}
                                                <br/>bgr={row.bgr}

                                            </span>
                                        </> : null
                                    }

                                </td>
                                {
                                    columns.map((col, j) => {
                                        return billsCell(col.id, row);
                                    })
                                }
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>

            {filesWin}

            {
                (isModerOrCassir &&
                    (selectedIdList.length !== 0 || selectedCell.rowId !== 0)
                )
                    ? <PanelColorSelect onClick={setBgr} right={isCassir}/>
                    : null
            }
        </>
    )

};

export default BillsTable;

