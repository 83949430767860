import React from 'react';
import {Win} from "components/_common";
import DocsListFiles from "./DocsListFiles";

const DocsList = props => {

	const {docsByUser, onDelete, onDownload} = props;

	let usersFiles = [];

	for (const userName in docsByUser) {
		usersFiles.push(
			<Win
				header={userName}
				key={userName}
			>
				<DocsListFiles
					files={docsByUser[userName]}
					onDelete={onDelete}
					onDownload={onDownload}
					{...props}
				/>
			</Win>
		)
	}

	return (
		<>
			{usersFiles}
		</>
	)

};

export default DocsList;