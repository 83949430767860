import React from 'react';
import {Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import MailboxContainer from "containers/mailbox/MailboxContainer";

const MailboxRouter = props => {

	return(
		<Switch>
			<Route path="/mailbox/:id?"
				   key={"mailbox-list"}>
				<MailboxContainer {...props} />
			</Route>
			<Route component={Page404} />
		</Switch>
	)

};

export default MailboxRouter;
