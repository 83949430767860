import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar'

// Reducers
// import catalogReducer from './catalog-reducer';
import UserReducer from './UserReducer';
import GUIReducer from './GUIReducer';
import {reducer as toastrReducer} from 'react-redux-toastr';


// Combine Reducers
let reducers = combineReducers({
	loadingBar: loadingBarReducer,
	toastr: toastrReducer,

	user: UserReducer,
	GUI: GUIReducer,//для управления интерфейсом, показать/скрыть меню и прочее
});

export default reducers;