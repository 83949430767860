import React from 'react';
import date from "php-date";
import {FormItem, FilesList} from "components/_common";
// import DocsListFiles from "components/docs/DocsListFiles";
import {SITE_URL} from "utils/config";

const InstallView = props => {
	const {
		user,
		// task,
		install
	} = props;
	// console.clear();
	// console.dir(install);
	if (install === null)
		return <h1 className="flx -c -alc" style={{height: '30vh'}}>
			<i className={"fa fa-ban"}
			   style={{marginRight: '10px', color: 'red'}}/>
			Нет информации о монтаже
		</h1>;


	let content = [];
	content.push(
		<h1 className="tasks-install-view-hdr _tac" key={"ti-hdr"}>
			Монтаж {install.completeDate == 0 ? 'не ' : ''}сдан {
			install.completeDate != 0
				? date('d.m.Y H:i', new Date(install.completeDate * 1000))
				: null
		}
		</h1>
	);

	// content.push(
	// 	<FormItem
	// 		edit={false}
	// 		name={"rating"}
	// 		label={"Оценка качества монтажа"}
	// 		defVal={install.rating}
	// 		key={"ti-rating"}
	// 	/>
	// );

	// console.dir(install.claimFiles);
	if (install.claimFiles && install.claimFiles.length !== 0)
		content.push(
			<FormItem
				edit={false}
				name={"claimFiles"}
				label={"Рекламации, файлы"}
				key={"ti-claimFiles"}
				fieldType={
					<FilesList
						files={install.claimFiles}
						onDelete={null}
					/>
					// <DocsListFiles
					// 	files={install.claimFiles}
					// 	onDelete={null}
					// 	onDownload={null}
					// 	user={user}
					// 	onlyThumb={true}
					// />
				}
			/>
		);
	if (install.claimText != '')
		content.push(
			<FormItem
				edit={false}
				name={"claimText"}
				label={"Рекламации, описание"}
				defVal={install.claimText}
				key={"ti-claimText"}
				fieldType={"txt"}
			/>
		);
	if (install.detailsFiles && install.detailsFiles.length)
		content.push(
			<FormItem
				edit={false}
				name={"detailsFiles"}
				label={"Детали для замены, файлы"}
				key={"ti-detailsFiles"}
				fieldType={
					<FilesList
						files={install.detailsFiles}
						onDelete={null}
					/>
					// <DocsListFiles
					// 	files={install.detailsFiles}
					// 	onDelete={null}
					// 	onDownload={null}
					// 	user={user}
					// 	onlyThumb={true}
					// />
				}
			/>
		);
	if (install.detailsText != '')
		content.push(
			<FormItem
				edit={false}
				name={"detailsText"}
				label={"Детали для замены, описание"}
				defVal={install.detailsText}
				key={"ti-detailsText"}
				fieldType={"txt"}
			/>
		);


	if (install.photosFiles && install.photosFiles.length)
		content.push(
			<FormItem
				edit={false}
				name={"photosFiles"}
				label={"Фото объекта"}
				key={"ti-photosFiles"}
				fieldType={
					<FilesList
						files={install.photosFiles}
						onDelete={null}
					/>
					// <DocsListFiles
					// 	files={install.photosFiles}
					// 	user={user}
					// 	onDelete={null}
					// 	onDownload={null}
					// />
				}
			/>
		);

	content.push(
		<FormItem
			edit={false}
			name={"clientName"}
			label={"ФИО принимающего"}
			defVal={install.clientName}
			key={"ti-clientName"}
		/>
	);
	if (install.clientText)
		content.push(
			<FormItem
				edit={false}
				name={"clientText"}
				label={"Комментарии клиента"}
				defVal={install.clientText}
				key={"ti-clientText"}
			/>
		);
	if (install.signature)
		content.push(
			<FormItem
				edit={false}
				name={"signature"}
				label={"Подпись клиента"}
				defVal={""}
				key={"ti-signature"}
				fieldType={
					<div>
						<img src={SITE_URL + install.signature} />
					</div>
				}
			/>
	);
	return (
		<>
			{content}
		</>
	)
}
export default InstallView;
