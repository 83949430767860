import React from 'react';
import {Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import DocsContainer from "containers/docs/DocsContainer";

const DocsRouter = props => {

	// return <DocsContainer {...props} />
	return (
		<Switch>
			<Route
				path="/docs"
				exact={true}
				key={"docs"}
			>
				<DocsContainer {...props} />
			</Route>
			<Route
				path="/docs/user/:userId"
				// exact={true}
				key={"docs4user"}
			>
				<DocsContainer {...props} />
			</Route>

			<Route component={Page404} />
		</Switch>
	)

};

export default DocsRouter;
