import React, {useState, useEffect} from 'react';
import {useParams, useLocation, useRouteMatch} from "react-router-dom";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {MENU_TITLE, MESSAGES, rolesModerator} from "utils/config";
import {array_unset, in_array} from "utils/func";
// import {toastr} from "react-redux-toastr";
import {ContentHeader, Button, Win} from "components/_common";
import * as Settings from "components/settings";
import {userSetSettings} from "actions/UserActions";
import {toastr} from "react-redux-toastr";
// import Page404 from "components/Page404";

const API_URL = 'settings.php';
const SettingsContainer = props => {

	const {
		user,
		GUI,
		actions
	} = props;


	const {settingsType} = useParams();
	let settingsTypeResult = '';

	const location = useLocation();
	switch (location.pathname) {
		case "/ustav":
			settingsTypeResult = 'ustav';
			break;
		case "/vocations":
			settingsTypeResult = 'vocations';
			break;
		default:
			settingsTypeResult = settingsType;
			break;
	}


	const [settings, setSettings] = useState(null);
	const [savedSettings, setSavedSettings] = useState({});
	const [divisions, setDivisions] = useState(null);
	const [deleteSettings, setDeleteSettings] = useState([]);
	const [todoList, setTodoList] = useState(null);
	const [files4settings, setFiles4settings] = useState({});
	const [files4upload4settings, setFiles4upload4settings] = useState({});

	let apiActionProcess = false;
	const getSettings = async (isSubscribe = true, stype = '') => {

		if (!isSubscribe)
			return;


		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'getSettings',
				user: user,
				stype: stype
			});

			apiAction(res, (data) => {

				// console.dir(data.files);
				setSettings(data.settings);
				setDivisions(data.divisions);
				setTodoList(data.todoList);
				setFiles4settings(data.files);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();

			});
		}
	};
	useEffect(() => {
			console.log('sets cont useEffect');
			let isSubscribe = true;
			getSettings(isSubscribe, settingsTypeResult).then(null);

			return () => {
				isSubscribe = false;
				setSettings(null);
			}
		},
		[]
	);

	/**
	 * Сохранение настроек
	 * @param data - напрямую переданные настройки для сохранения
	 * @param forFiles - если загружаем файлы, то дальше не сохранять
	 * @returns {Promise<void>}
	 */
	const saveSettings = async (data = null, forFiles = false) => {
		console.clear();
		console.log('saveSettings');

		let settings2save = data ? data : savedSettings;

		if (Object.values(files4upload4settings).length > 0) {
			let _savedFilesSettings = await uploadFiles();
			for (let sn in _savedFilesSettings) {

				settings2save[ sn ] = _savedFilesSettings[sn];
			}
			// settings2save = {...settings2save, _savedFilesSettings};
			// console.dir(settings2save);
			// return;
		}
		//
		// console.dir('savedSettings \\/\\/\\/', savedSettings);
		// console.dir(settings2save);
		// return;
		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('settings.php', {
				action: 'setSettings',
				user: user,
				data: settings2save,
				deleteSettings: deleteSettings
			});

			apiAction(res, (data) => {
				for (const key in savedSettings) {
					actions.userSetSettings(key, savedSettings[key]);
				}
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();

			});
		}
	};

	const uploadFiles = async () => {
		console.log('settings uploadFiles');

		// setFiles4upload4settings({});
		// setSavedSettings((prev) => ({
		// 	...prev,
		// 	someSettingsName: 123456
		// }))

		const formData = new FormData();

		formData.append('user', JSON.stringify(user));
		formData.append('action', 'uploadFiles');

		Object.entries(files4upload4settings).forEach(([setName, files]) => {
			// console.log( setName, files.length);

			files.map((file, i) => {
				formData.append(`${setName}_${i}`, file);
			})

		});

		let _savedFilesSettings = {};

		if (!apiActionProcess) {
			actions.showLoading();
			apiAction(await Api.post(API_URL, formData), async (response) => {
				if (response.settingsFilesId) {

					Object.entries(response.settingsFilesId).forEach(([setName, _ids]) => {
						let newIdList = _ids;
						if (settings[setName])
							newIdList = newIdList.concat(settings[setName]);

						_savedFilesSettings[ setName ] = newIdList;
					});
				}
				console.dir(response.settingsFiles);

				if (response.settingsFiles)
					setFiles4settings(response.settingsFiles);

				setFiles4upload4settings({});

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}

		return _savedFilesSettings;
	}

	const deleteFile = (fileId, settingName) => {
		console.clear();


		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: async () => {

					console.log('delete file', fileId, settingName);
					if (files4settings[settingName]) {

						let newFiles = {};
						Object.entries(files4settings).forEach(([setName, _files]) => {
							if (!newFiles.hasOwnProperty(setName))
								newFiles[setName] = [];

							_files.map(file => {
								if (file.id !== fileId)
									newFiles[setName].push(file);
							});
						});

						/*
						 * удаляем только в стейте,
						 * потому что в других случаях берется с сервера
						 */


						if (!apiActionProcess) {
							actions.showLoading();
							apiAction(await Api.post(API_URL, {
								action: 'deleteFiles',
								user: user,
								fileId: fileId,
								settingName: settingName
							}), () => {

								setFiles4settings(newFiles);

							}, () => {
								apiActionProcess = false;
								actions.hideLoading();
							});
						}
					}


				}
			}
		);
	};

	if (!settings || typeof settings !== 'object')
		return null;

	let contentHeaderTitle = '',
		contentHeaderParent = [{url: '/settings', name: 'Настройки'}],
		content = '',
		fullContent = null;

	let winFooter = (
		<div className={"win-ftr-btns"}>
			<Button
				type="apply"
				onClick={() => saveSettings()}
				disabled={!Object.keys(savedSettings).length}
				// disabled={true}
			/>
		</div>
	);


	switch (settingsTypeResult) {
		case "calendar":
			contentHeaderTitle = 'Календарь';
			content = <Settings.Calendar
				{...props}
			/>;
			break;
		case "vocations":
			contentHeaderTitle = MENU_TITLE.vocations.one;
			content = <Settings.Calendar
				{...props}
				type={settingsTypeResult}
			/>;
			break;
		case "ustav":
			// contentHeaderTitle = 'Устав компании';
			// if (!in_array(user.role, rolesModerator))
			// 	winFooter = null;
			fullContent = <Settings.Ustav
				edit={in_array(user.role, rolesModerator)}
				user={user}
				GUI={GUI}
				settings={settings}
				doSaveSettings={saveSettings}
				savedSettings={savedSettings}
				setSavedSettings={setSavedSettings}

				setDeleteSettings={setDeleteSettings}

				files={files4settings}
				files4upload4settings={files4upload4settings}
				setFiles4upload4settings={setFiles4upload4settings}
				deleteFile={deleteFile}
			/>;
			break;
		case "limits":
			contentHeaderTitle = 'Ограничения';
			content = <Settings.Limits
				settings={settings}
				savedSettings={savedSettings}
				setSavedSettings={setSavedSettings}
			/>;
			break;
		case "general":
			contentHeaderTitle = 'Основные настройки';

			let initTodoSelectColors = {};
			for (const divId in divisions) {
				let bgr = '#fffff1', color = '#999999';
				if (settings['g_todo-select-bgr-' + divId])
					bgr = settings['g_todo-select-bgr-' + divId];
				if (settings['g_todo-select-color-' + divId])
					color = settings['g_todo-select-color-' + divId];
				initTodoSelectColors[divId] = {
					bgr: bgr,
					color: color
				};
			}
			let initTodoListBgr = {};
			if (todoList)
				for (let todo in todoList) {
					let clr;

					if (todoList[todo].hideInTodo === undefined || !todoList[todo].hideInTodo) {
						if (settings.task_list_bgr && settings.task_list_bgr[todo])
							clr = settings.task_list_bgr[todo];
						else
							clr = '#ffffff';
						// clr = '#FD7E14';

						initTodoListBgr[todo] = clr;
					}
				}
			// console.log(initTodoSelectColors);

			if (settings && divisions && todoList)
				content = <Settings.General
					// setSettings={setSettings}
					settings={settings}
					divisions={divisions}
					initTodoSelectColors={initTodoSelectColors}
					savedSettings={savedSettings}
					setSavedSettings={setSavedSettings}
					userRoles={user.userRoles && user.userRoles}
					todoList={todoList}
					initTodoListBgr={initTodoListBgr}
				/>;
			break;
		case "notifications":
			contentHeaderTitle = 'Уведомления';
			content = <Settings.Notifications
				settings={settings}
				setSavedSettings={setSavedSettings}
				divisions={divisions}
			/>
			break;
		case "tasks":
			contentHeaderTitle = MENU_TITLE.tasks.many;
			content = <Settings.Tasks
				settings={settings}
				setSavedSettings={setSavedSettings}
			/>
			break;
		default:
			content = <Settings.Personal
				user={user}
				settings={settings}
				savedSettings={savedSettings}
				setSavedSettings={setSavedSettings}
			/>
			break;
	}
	if (fullContent)
		return fullContent;

	return (
		<>
			<ContentHeader
				title={contentHeaderTitle}
				parent={contentHeaderParent}
				showDates={false}
			/>
			<Win
				header={contentHeaderTitle}
				footer={winFooter}
			>
				{content}
			</Win>
		</>
	);

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, userSetSettings
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
