import React from 'react';
import {in_array, sortArrayOfObjects} from "utils/func";

const ManagerSelect = ({managers, id, value = 0, onChange, divisions = [], title='Выберите менеджера'}) => {

    managers = sortArrayOfObjects(Object.values(managers), 'name');

    let usersByRoles = {
        manager: [],
        admin: [],
        moderator: [],
    };
    let usersByRolesName = {
        manager: 'Менеджеры',
        admin: 'Администраторы',
        moderator: 'Модераторы',
    };

    managers.map(man => {
        usersByRoles[ man.role ].push(man);

        return null;
    })

    const options = [];

    for (let role in usersByRoles) {
        if (usersByRoles[role].length) {

            let opts = [];
            usersByRoles[role].map(man => {
                if (divisions.length == 0 || in_array(man.divId, divisions))
                    opts.push(
                        <option
                            key={`man-sel-${id}-${man.id}`}
                            value={man.id}

                        >
                            {man.name}
                        </option>
                    );

                return null;
            });

            options.push(<optgroup
                key={`man-sel-group-${role}`}
                label={usersByRolesName.hasOwnProperty(role) ? usersByRolesName[ role ] : role}
            >
                {opts}
            </optgroup>);

        }
    }


    return (
        <select
            defaultValue={value}
            onChange={e => {
                console.log(e.target.value);
                onChange(e.target.value);
            }}
        >
            <option value={0}>{title}</option>

            {options}
        </select>
    )

};

export default ManagerSelect;