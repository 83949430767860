import React, {createRef, useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {Win, Tabs, FilesList} from "components/_common";
import Api, {apiAction} from "utils/Api";
import DocsUploadBox from "components/docs/DocsUploadBox";
import {toastr} from "react-redux-toastr";
import {MESSAGES} from "../../utils/config";
// import DocsListFiles from "../docs/DocsListFiles";


const API_URL = 'tables.php';

const BillsFiles = props => {
    // console.dir(props);
    const {user, GUI, actions, onClose, data} = props;

    const [files, setFiles] = useState(null);
    const [activeTab, setActiveTab] = useState(0);

    // const [files4upload_rekv, setFiles4upload_rekv] = useState([]);
    // const [files4upload_bills, setFiles4upload_bills] = useState([]);

    const rekvRef = createRef();
    const billsRef = createRef();


    let apiActionProcess = false;
    const getFiles = async (isSubscribe = true) => {
        if (!isSubscribe)
            return;

        // console.clear();
        console.log('getFiles', data.id);


        if (!apiActionProcess) {
            apiActionProcess = true;

            const res = await Api.post(API_URL, {
                action: 'getFiles',
                userId: user.id,
                tableName: 'bills',
                fileTypes: ['rekvFiles', 'billsFiles'],
                id: data.id,
            });

            apiAction(res, (response) => {

                setFiles(response.files);

            }, () => {
                apiActionProcess = false;
            });
            apiActionProcess = false;
        }
    };


    useEffect(() => {
            let isSubscribe = true;

            console.log('useEffect');
            getFiles(isSubscribe).then(null);

            return () => {
                isSubscribe = false;
            }
        },
        []
    );

    const uploadFiles = async (fileType, files) => {

        const formData = new FormData();

        formData.append('id', data.id);
        formData.append('userId', user.id);
        formData.append('action', 'uploadFiles');
        formData.append('tableName', 'bills');
        formData.append('fileType', fileType);


        // console.dir(files4upload_rekv);
        // console.dir(files4upload_bills);
        // console.dir(files);
        // return;

        files.map((file, i) => {
            formData.append('file_' + i, file);
        })

        if (!apiActionProcess) {
            actions.showLoading();
            apiAction(await Api.post(API_URL, formData), (response) => {
                console.log('after upload');
                setFiles(response.files);

            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            });
        }
    }

    const deleteFile = async (fileId) => {
        // console.log('deleteFile', fileId);
        toastr.confirm(
            MESSAGES.confirmAction,
            {
                okText: 'Да',
                cancelText: 'Нет',
                onOk: async () => {
                    if (!apiActionProcess) {
                        apiActionProcess = true;
                        actions.showLoading();
                        apiAction(await Api.post(API_URL, {
                            action: 'deleteFile',
                            userId: user.id,
                            tableName: 'bills',
                            fileType: '',
                            id: data.id,
                            fileId: fileId
                        }), (response) => {
                            // console.log('after delete');
                            // console.dir(response.files);
                            setFiles(response.files);

                        }, () => {
                            apiActionProcess = false;
                            actions.hideLoading();
                        });
                    }
                }
            }
        );

    }


    let content = null;
    if (files === null) {
        content = <div className={"loading"}>
            <i className={"fa fa-circle-o-notch  fa-spin"}/>
        </div>
    } else {

        let filesByTab = {
            "rekv": [],
            "bills": []
        };
        files.map(file => {
            if (file.ftype == 'table_bills_rekv')
                filesByTab['rekv'].push(file);
            else if (file.ftype == 'table_bills_bills')
                filesByTab['bills'].push(file);
        });

        // console.log(files);
        //
        // console.dir(filesByTab);

        let tabsData = [];
        tabsData.push({
            title: 'Реквизиты',
            content: (
                <>
                    <FilesList
                        files={filesByTab.rekv}
                        onDelete={deleteFile}
                        user={user}
                    />
                    {/*<DocsListFiles*/}
                    {/*    files={filesByTab.rekv}*/}
                    {/*    onDelete={deleteFile}*/}
                    {/*    onDownload={null}*/}
                    {/*    user={user}*/}
                    {/*    onlyThumb={true}*/}
                    {/*/>*/}
                    <DocsUploadBox
                        showOnlyUploadBox={true}
                        settings={user.settings}
                        filesList={[]}
                        setFilesList={(_files) => uploadFiles('rekv', _files)}
                        GUI={GUI}
                        formRef={rekvRef}
                    />
                </>
            )
        });
        tabsData.push({
            title: 'Счета',
            content: (
                <>
                    <FilesList
                        files={filesByTab.bills}
                        onDelete={deleteFile}
                        user={user}
                    />
                    <DocsUploadBox
                        showOnlyUploadBox={true}
                        settings={user.settings}
                        filesList={[]}
                        setFilesList={(_files) => uploadFiles('bills', _files)}
                        GUI={GUI}
                        formRef={billsRef}
                    />
                </>
            )
        });

        content = <Tabs
            data={tabsData}
            tabPrefix={'bills-files-tabs'}
            url={''}
            useUrl={false}
            activeTabInit={activeTab}
            onChange={i => setActiveTab(i)}
        />;
    }

    return (
        <div className="popup-win tables-files-win">
            <Win
                onClose={onClose}
                header={`Файлы, cчет №${data.bill_number}, ${data.org} `}
                winClass={"wide"}
            >
                {content}
            </Win>
        </div>
    )

};

const mapStateToProps = store => ({
    user: store.user,
    GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        showLoading, hideLoading
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(BillsFiles);


// export default BillsFiles;
