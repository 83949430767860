import React, {useEffect, useState, useRef} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Button, Win, FormItem} from "components/_common";
import {doDeleteClient} from "./ClientsContainer";
import {toastr} from "react-redux-toastr";


const API_URL = 'users.php';

const ClientsEditContainer = props => {
    const {user, actions, history} = props;

    let {id = 0} = useParams();
    id = parseInt(id);


    const [data, setData] = useState({client: {id: id}});

    const goBack = () => {
        history.push(`/user/clients`);
    }


    let apiActionProcess = false;
    const getData = async (isSubscribe = true, calDates = null, search = '') => {
        if (!isSubscribe)
            return;

        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;

            const res = await Api.post(API_URL, {
                action: 'getClients',
                user: {id: user.id},
                id: id
            });

            apiAction(res, (response) => {
                console.dir(response);
                if (id !== 0)
                    setData(response);

            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            });
            apiActionProcess = false;
        }
    };
    useEffect(() => {
            let isSubscribe = true;

            getData(isSubscribe, null).then(null);

            return () => {
                isSubscribe = false;
            }
        },
        []
    );

    const saveClient = async () => {
        console.log('saveClient');

        const {client = {}} = data;

        console.dir(client);
        // return;
        let errors = [];
        if (client.company == undefined || client.company == '')
            errors.push('Не заполнено поле "Компания"');

        if (client.name == undefined || client.name == '')
            errors.push('Не заполнено поле "Контактное лицо"');

        if (errors.length) {
            errors.map(err => toastr.error('Ошибка заполнения', err));
        } else {
            if (!apiActionProcess) {
                actions.showLoading();
                apiActionProcess = true;

                const res = await Api.post(API_URL, {
                    action: 'saveClient',
                    user: {id: user.id},
                    data: client
                });

                apiAction(res, (response) => {
                    console.dir(response);
                    goBack();

                }, () => {
                    apiActionProcess = false;
                    actions.hideLoading();
                });
                apiActionProcess = false;
            }
        }
    }

    let winTitle = id === 0 ? 'Новый клиент' : 'Редактирование клиента';

    let winFooter = null;
    // let saveButton = null;
    // let deleteButton = null;
    // const deleteClient = id => {
    //     console.log('deleteClient');
    //
    // };


    if (1) {
        winFooter = <div className={"win-ftr-btns flx -sb"}>
            <div>
                <Button
                    type="saveIco"
                    title={"Сохранить"}
                    onClick={saveClient}
                />
            </div>
            <div className="_tar">
                {
                    id !== 0
                        ? <Button
                            type={"delete"}
                            onClick={e => doDeleteClient(user, data.client, actions, apiActionProcess, (resp) => {
                                goBack();
                            })}
                        />
                        : null
                }

            </div>

        </div>;
    }

    let content = [];
    const {client = {}} = data;

    const onFieldChange = (e, val) => {

        let field = e.target.name ?? '';

        if (e.target) {
            setData((prev) => ({
                ...prev,
                client: {
                    ...client,
                    [field]: val
                },
            }));
        }
    };

    content.push(
        <FormItem
            key={'ced-company'}
            name={"company"}
            label={"Компания"}
            defVal={client.company ?? ''}
            changeAction={onFieldChange}
            isRequire={true}
        />
    );
    content.push(
        <FormItem
            key={'ced-name'}
            name={"name"}
            label={"Контактное лицо"}
            defVal={client.name ?? ''}
            changeAction={onFieldChange}
            isRequire={true}
        />
    );
    content.push(
        <FormItem
            key={'ced-phoneMobile'}
            name={"phoneMobile"}
            label={"Телефон, мобильный"}
            defVal={client.phoneMobile ?? ''}
            changeAction={onFieldChange}
        />
    );
    content.push(
        <FormItem
            key={'ced-phones'}
            name={"phones"}
            label={"Телефоны"}
            defVal={client.phones ?? ''}
            changeAction={onFieldChange}
        />
    );
    content.push(
        <FormItem
            key={'ced-adrs'}
            name={"adrs"}
            label={"Адрес"}
            defVal={client.adrs ?? ''}
            changeAction={onFieldChange}
        />
    );
    content.push(
        <FormItem
            key={'ced-cmtns'}
            fieldType={"txt"}
            name={"cmnts"}
            label={"Комментарии"}
            defVal={client.cmnts ?? ''}
            changeAction={onFieldChange}
        />
    );
    return (
        <>
            <ContentHeader
                parent={[{url: '/user', name: "Пользователи"}, {url: '/user/clients', name: 'Клиенты'}]}
                title={winTitle}
            />

            <Win
                header={winTitle}
                onClose={goBack}
                footer={winFooter}
            >

                {content}
            </Win>
        </>
    )

};

const mapStateToProps = store => ({
    user: store.user,
    // GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        showLoading, hideLoading,
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientsEditContainer);
