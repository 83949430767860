import React from 'react';
import {dateFormat, isModerator} from "utils/func";
import {NavLink} from "react-router-dom";
import {TableSortArrow} from "components/_common";


export const clearCompanyName = str => {
    let companyPure = str.replace(/ООО/i, '');


    companyPure = companyPure.replaceAll(/ИП/ig, '');
    // companyPure = companyPure.replace(/\W+/ig, '');
    companyPure = companyPure.replaceAll(/\"/ig, '');
    companyPure = companyPure.toLowerCase();
    companyPure = companyPure.trim();

    return companyPure;
}
const ClientsList = ({clients, user, onDelete, sort, onSort}) => {

    // let content = [];
    const userIsModerator = isModerator(user);

    return (
        <>
            <table className={"tbl dark mt20"}>
                <thead>
                <tr>
                    <td width="5%" className="_tac">
                        &nbsp;&nbsp;
                    </td>
                    <td width={"20%"}
                        className={"sortable"}
                        onClick={() => onSort("company")}
                    >
                        Компания
                        <TableSortArrow sort={sort} field={"company"}/>
                    </td>
                    <td width={"22%"}>Контактное лицо</td>
                    <td width={"25%"}>Телефоны</td>
                    <td width={"20%"}>Адрес</td>
                    <td width={"10%"}
                        className={"sortable"}
                        onClick={() => onSort("created")}
                    >
                        Создан
                        <TableSortArrow sort={sort} field={"created"}/>
                    </td>
                </tr>
                </thead>
                <tbody>
                {
                    clients.map((client) => {

                        let actionsMenu = null,
                            editButton = null,
                            deleteButton = null;

                        if (user.id = client.uid || userIsModerator) {
                            editButton = <NavLink to={"/user/clients/" + client.id} title={"Редактировать"}>
                                <i className="fa fa-edit"></i> Редактировать
                            </NavLink>;

                            deleteButton = <a href={"#"} onClick={e => onDelete(e, client)}>
                                <i className="fa fa-close"></i> Удалить
                            </a>;
                        }

                        if (editButton || deleteButton)
                            actionsMenu = (
                            <div className="actions -left">
                                <i className="fa fa-bars"></i>
                                <div>

                                    {editButton}
                                    {deleteButton}
                                </div>
                            </div>
                        );

                        return (
                            <tr
                                key={`clients-list-${client.id}`}
                            >
                                <td className="_tac">{actionsMenu}</td>
                                <td>{client.company}</td>
                                <td>{client.name}</td>
                                <td>
                                    {client.phoneMobile}
                                    {
                                        client.phone != ''
                                            ? (<><br/>{client.phone}</>)
                                            : null
                                    }
                                </td>
                                <td>{client.adrs}</td>
                                <td>{dateFormat(client.created)}</td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        </>
    )

};

export default ClientsList;