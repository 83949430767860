import React from 'react';
import date from "php-date";
import {FormItem, FilesList} from "components/_common";

const DeliveryView = props => {
    const {
        closeUrl, history,
        task,
        delivery,
        moversAll,
    } = props;


    if (delivery == null)
        return <h1 className="flx -c -alc" style={{height: '30vh'}}>
            <i className={"fa fa-ban"}
               style={{marginRight: '10px', color: 'red'}}/>
            Доставка не завершена
        </h1>;

    let moversList = [];
    if (delivery.movers.length !== 0) {
        delivery.movers.map(moverId => {

            if (moversAll.hasOwnProperty(moverId)) {
                moversList.push(
                    moversAll[moverId].name
                );
            } else
                moversList.push(`Не найден пользователь с ИД=${moverId}`);

            return null;
        });

    }

    return (
        <div className={"task-delivery-view"}>
            <h1 className="tasks-install-view-hdr _tac" key={"ti-hdr"}>
                Доставка {delivery.completeDate == 0 ? 'не ' : ''}завершена {
                delivery.completeDate != 0
                    ? date('d.m.Y H:i', new Date(delivery.completeDate * 1000))
                    : null
            }
            </h1>

            {
                delivery.mkadKm > 0
                    ? <FormItem
                        edit={false}
                        name={"claimText"}
                        label={"Доставка за МКАД, км"}
                        defVal={delivery.mkadKm}
                        // fieldType={"txt"}
                    /> : <div className={"form-item"}>
                        <label>Доставка в пределах МКАД</label>
                    </div>
            }
            <div className={"form-item"}>
                <label>{
                    moversList.length !== 0
                        ? 'Разгрузка наша'
                        : 'Без разгрузки'
                }</label>
                {moversList.join(', ')}
            </div>
            {
                delivery.photosFiles.length !== 0
                    ? <FormItem
                        edit={false}
                        name={"photosFiles"}
                        label={"Фото"}

                        fieldType={
                            <FilesList
                                files={delivery.photosFiles}
                                onDelete={null}
                            />
                        }
                    /> : null

            }


        </div>
    )

};

export default DeliveryView;